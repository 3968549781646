import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {loginUiTexts, generalUiTexts} from 'data/ui-texts';
import {languagesData} from 'data/languages-data';
import {getText} from 'helpers/language-helper';
import Background from 'components/background/background';
import Select from 'components/select/select';
import LoginFacilitatorController from './login-facilitator-controller';
import RequestPasswordController from './request-password-controller';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import './login.scss';


const Login = ({languageId, activeLoginBox, updateLanguage, setActiveLoginBox}) => {
	/* Get active box component for facilitator box */
	let [facilitatorBoxType, setFacilitatorBoxType] = useState('login');

	let FacilitatorBoxComponent = LoginFacilitatorController;
	if (facilitatorBoxType === 'createUser') FacilitatorBoxComponent = CreateUserController;
	if (facilitatorBoxType === 'resetPassword') FacilitatorBoxComponent = RequestPasswordController;

	return (
		<div className="Login">
			<Background />
			<div className="Login-panel">
				<div className="Login-language">
					<Select 
						isDisabled={false}
						type="login"
						languageId={languageId}
						options={languagesData}
						defaultId={appConfig.defaultLanguage}
						selectedId={languageId}
						onSelect={updateLanguage}
					/>
				</div>
				<div className="Login-wrap">
					<div className="Login-title">{getText(generalUiTexts.title, languageId)}</div>
					<div className="Login-box">
						<div className="Login-tabContainer">
							<div
								className={'Login-tabButton' + (activeLoginBox === 'group' ? ' active' : '')}
								onClick={() =>{setActiveLoginBox('group');}}
							><span>{getText(loginUiTexts.group, languageId)}</span></div>
							<div
								className={'Login-tabButton' + (activeLoginBox === 'facilitator' ? ' active' : '')}
								onClick={() =>{setActiveLoginBox('facilitator');}}
							><span>{getText(loginUiTexts.facilitator, languageId)}</span></div>
						</div>
						<div className="Login-formContainer">
							{activeLoginBox === 'facilitator' && 
								<FacilitatorBoxComponent 
									languageId={languageId} 
									setFacilitatorBoxType={setFacilitatorBoxType} 
								/>}
							{activeLoginBox === 'group' && 
								<LoginGroupController languageId={languageId} />
							}
						</div>
					</div>
				</div>
				<CookieConsentController languageId={languageId} />
			</div>
		</div>
	);
};

Login.propTypes = {
	languageId: PropTypes.string.isRequired,
	activeLoginBox: PropTypes.string.isRequired,
	updateLanguage: PropTypes.func.isRequired,
	setActiveLoginBox: PropTypes.func.isRequired
};

export default Login;