/**
 * Error feedback for login
 * @param {string} errorCode 
 */
const errorFeedbackHelper = function(errorCode, showOriginalIfNoMatch = false) {
	let status = null;

	switch (errorCode) {
	// Create user (facilitator)
	case 'empty-fields':
		status = 'emptyFields';
		break;
	case 'auth/email-already-exists': 
		status = 'emailExists';
		break;
	case 'invalid-email':
		status = 'invalidEmailDomain';
		break;
	case 'weak-password': 
	case 'auth/weak-password': 	
	case 'no-password':
	case 'auth/invalid-password': 
		status = 'invalidPassword';
		break;
	case 'no-password-match':
		status = 'noPasswordMatch';
		break;

	// Login / reset password (facilitator)
	case 'auth/user-not-found':
		status = 'emailNotFound';
		break;
	case 'no-email': 
	case 'auth/wrong-password':
		status = 'invalidEmailPasswordFormat';
		break;
	case 'auth/invalid-email': 
		status = 'invalidEmail';
		break;

	// Login (group)
	case 'no-game-code':
	case 'wrong-game-code':
		status = 'noGameCodeMatch';
		break;

	// Create game
	case 'invalid-chars':
		status = 'invalidCharacters';
		break;
	case 'name-too-long':
		status = 'groupNameTooLong';
		break;
	case 'no-name':
		status = 'noGroupName';
		break;
	case 'not-enough-groups':
		status = 'notEnoughGroups';
		break;

	// Default
	default:
		if (showOriginalIfNoMatch) {
			status = errorCode;
		} else {
			status = 'unknownError';
		}
		
	}

	return status;
};


export {errorFeedbackHelper};