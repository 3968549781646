const roundsData = [
	{
		id: 1,
		phaseId: 1,
		'expected-demand': {
			'scenario-1': [25000, 33000],
			'scenario-2': [25000, 33000],
			'scenario-3': [25000, 33000]
		},
		'actual-demand': {
			'scenario-1': 30000,
			'scenario-2': 30000,
			'scenario-3': 30000
		},
		report: {
			internal: {
				da: require('data/reports/round-1/report-1a_da.md').default,
				en: require('data/reports/round-1/report-1a_en.md').default,
			},
			expectations: {
				da: require('data/reports/round-1/report-1b_da.md').default,
				en: require('data/reports/round-1/report-1b_en.md').default,
			}
		}
	},
	{
		id: 2,
		phaseId: 1,
		'expected-demand': {
			'scenario-1': [30000, 37000],
			'scenario-2': [30000, 37000],
			'scenario-3': [30000, 37000],
		},
		'actual-demand': {
			'scenario-1': 37000,
			'scenario-2': 37000,
			'scenario-3': 37000,
		},
		report: {
			internal: {
				da: require('data/reports/round-2/report-2a_da.md').default,
				en: require('data/reports/round-2/report-2a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-2/report-2b_da.md').default,
				en: require('data/reports/round-2/report-2b_en.md').default
			}
		},
		effects: [
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'waste', value: 3},
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'waste', value: 3},
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'waste', value: 2},
		]
	},
	{
		id: 3,
		phaseId: 1,
		'expected-demand': {
			'scenario-1': [90000, 100000],
			'scenario-2': [90000, 100000],
			'scenario-3': [90000, 100000]
		},
		'actual-demand': {
			'scenario-1': 90000,
			'scenario-2': 90000,
			'scenario-3': 90000,
		},
		report: {
			internal: {
				da: require('data/reports/round-3/report-3a_da.md').default,
				en: require('data/reports/round-3/report-3a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-3/report-3b_da.md').default,
				en: require('data/reports/round-3/report-3b_en.md').default
			}
		}
	},
	{
		id: 4,
		phaseId: 1,
		'expected-demand': {
			'scenario-1': [85000, 90000],
			'scenario-2': [85000, 90000],
			'scenario-3': [85000, 90000],
		},
		'actual-demand': {
			'scenario-1': 90000,
			'scenario-2': 90000,
			'scenario-3': 90000,
		},
		report: {
			internal: {
				da: require('data/reports/round-4/report-4a_da.md').default,
				en: require('data/reports/round-4/report-4a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-4/report-4b_da.md').default,
				en: require('data/reports/round-4/report-4b_en.md').default
			}
		},
		effects: [
			{type: 'work-environment', value: -3},
		]
	},
	{
		id: 5,
		phaseId: 2,
		showIntroPopup: true,
		'expected-demand': {
			'scenario-1': [65000, 72000],
			'scenario-2': [65000, 72000],
			'scenario-3': [83000, 91000],
		},
		'actual-demand': {
			'scenario-1': 70000,
			'scenario-2': 70000,
			'scenario-3': 85000,
		},
		report: {
			internal: {
				da: require('data/reports/round-5/report-5a_da.md').default,
				en: require('data/reports/round-5/report-5a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-5/report-5b_da.md').default,
				en: require('data/reports/round-5/report-5b_en.md').default
			}
		}
	},
	{
		id: 6,
		phaseId: 2,
		'expected-demand': {
			'scenario-1': [92000, 100000],
			'scenario-2': [70000, 78000],
			'scenario-3': [62000, 72000],
		},
		'actual-demand': {
			'scenario-1': 95000,
			'scenario-2': 74000,
			'scenario-3': 72000,
		},
		report: {
			internal: {
				da: require('data/reports/round-6/report-6a_da.md').default,
				en: require('data/reports/round-6/report-6a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-6/report-6b_da.md').default,
				en: require('data/reports/round-6/report-6b_en.md').default
			}
		},
		effects: [
			{type: 'old-machine-reduced-effect', value: -0.05}
		]
	},
	{
		id: 7,
		phaseId: 2,
		'expected-demand': {
			'scenario-1': [124000, 133000],
			'scenario-2': [80000, 89000],
			'scenario-3': [53000, 60000],
		},
		'actual-demand': {
			'scenario-1': 128000,
			'scenario-2': 83000,
			'scenario-3': 59000,
		},
		report: {
			internal: {
				da: require('data/reports/round-7/report-7a_da.md').default,
				en: require('data/reports/round-7/report-7a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-7/report-7b_da.md').default,
				en: require('data/reports/round-7/report-7b_en.md').default
			}
		},
		effects: [
			{type: 'total-cost-reduction', value: -10000, condition: {parameter: 'work-environment', level: 'ok'}},
			{type: 'total-cost-reduction', value: -20000, condition: {parameter: 'work-environment', level: 'good'}},
		]
	},
	{
		id: 8,
		phaseId: 2,
		'expected-demand': {
			'scenario-1': [133000, 143000],
			'scenario-2': [87000, 97000],
			'scenario-3': [38000, 48000],
		},
		'actual-demand': {
			'scenario-1': 137000,
			'scenario-2': 88000,
			'scenario-3': 48000,
		},
		report: {
			internal: {
				da: require('data/reports/round-8/report-8a_da.md').default,
				en: require('data/reports/round-8/report-8a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-8/report-8b_da.md').default,
				en: require('data/reports/round-8/report-8b_en.md').default
			}
		},
		effects: [
			{type: 'mandatory-action', actionId: 34, condition: {type: 'no-upgrade', upgradeType: 'gmp-demands-implemented'}}
		]
	},
	{
		id: 9,
		phaseId: 2,
		hide: true,
		'expected-demand': {
			'scenario-1': [143000, 153000],
			'scenario-2': [143000, 153000],
			'scenario-3': [143000, 153000],
		},
		'actual-demand': {
			'scenario-1': 150000,
			'scenario-2': 150000,
			'scenario-3': 150000,
		},
		report: {
			internal: {
				da: require('data/reports/round-9/report-9a_da.md').default,
				en: require('data/reports/round-9/report-9a_en.md').default
			},
			expectations: {
				da: require('data/reports/round-9/report-9b_da.md').default,
				en: require('data/reports/round-9/report-9b_en.md').default
			}
		}
	}
];

export {roundsData};