import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getText} from 'helpers/language-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import CreateUser from './create-user';

class CreateUserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			userCreated: false,
			email: null,
			password: null,
			passwordRepeat: null,
			feedback: null
		};
	};

	/**
	 * Update input fields
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	}

	/**
	 * Handle create user request
 	 * @param {obj} event 
 	 */
	handleCreateUser = (event) => {
		event.preventDefault();

		this.setState({isLoading: true}, () => {
			let email = this.state.email;
			let password = this.state.password;
			let passwordRepeat = this.state.passwordRepeat;
			let error = null;
	
			/* Check for empty fields */
			if (!email || !password || !passwordRepeat) error = 'empty-fields';
	
			/* Check valid e-mail */
			if (!error) {
				if (email.indexOf('@') > -1) {
					const emailDomain = email.split('@')[email.split('@').length - 1];
					if (appConfig.validEmails.indexOf(emailDomain) < 0) {
						error = 'invalid-email';
					}
				} else {
					error = 'invalid-email';
				}
			}
	
			/* Check if passwords match */
			if (!error) {
				if (password && password.length < 8) {
					error = 'weak-password';
				} else if (password && password !== passwordRepeat) {
					error = 'no-password-match';
				}
			}
	
			if (error) {
				/* Error feedback */
				const errorMsg = getText(errorTexts[errorFeedbackHelper(error)], this.props.languageId);
				this.setState({
					isLoading: false,
					feedback: errorMsg
				});
			} else {
				/* Create user */
				this.createFirebaseUser(email, password);
			}
		});
	}

	/**
	 * Error feedback
	 * @param {string} errorCode 
	 */
	getErrorFeedback = (errorCode) => {
		const errorStatus = errorFeedbackHelper(errorCode);
		const errorMsg = getText(errorTexts[errorStatus], this.props.languageId);
		this.setState({
			feedback: errorMsg
		});
	}

	/**
	 * Create user
	 * @param {string} email 
	 * @param {string} password 
	 */
	createFirebaseUser = (email, password) => {
		apiHelper('facilitator/create-facilitator', {
			email: email,
			password: password
		}).then(
			(response)=>{
				if (response.status === 'success') {
					this.setState({userCreated: true, isLoading: false});
				} else {
					this.getErrorFeedback(response.error);
					this.setState({
						isLoading: false,
					});
				}
			},
			(error) => {
				console.error(error);
				this.setState({
					isLoading: false,
					feedback: getText(errorTexts.unknownError, this.props.languageId)
				});
			}
		);
	}

	/**
	 * Render create user component
	 */
	render() {
		return (
			<CreateUser
				isLoading={this.state.isLoading}
				userCreated={this.state.userCreated}
				languageId={this.props.languageId}
				email={this.state.email}
				password={this.state.password}
				passwordRepeat={this.state.passwordRepeat}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleCreateUser={this.handleCreateUser}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
			/>
		);		
	}
}

CreateUserController.propTypes = {
	languageId: PropTypes.string.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
};

export default CreateUserController;