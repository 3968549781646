import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import apiHelper from 'helpers/api-helper';
import {getText} from 'helpers/language-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';
import appConfig from 'config/app.config';
import LoginGroup from './login-group';

class LoginGroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			groups: null,
			feedback: null,
			isLoading: false,
			isCreatingGroup: false,
			invalidGameCode: false,
			showNewGroupPopup: false,
			groupId: null,
			groupName: null
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
			invalidGameCode: false
		});
	}

	/**
	 * Hide / show new group popup
	 */
	toggleCreateNewGroupPopup = () => {
		let showNewGroupPopup = !this.state.showNewGroupPopup;
		this.setState({showNewGroupPopup: showNewGroupPopup, groupName: null});
	}

	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event 
	 */
	handleFindGame = (event) => {
		event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({
				invalidGameCode: true,
				feedback: getText(errorTexts[errorFeedbackHelper('empty-fields')], this.props.languageId),
			});
			return;
		}
		this.setState({isLoading: true});
		const db = firebase.firestore();
		db.collection(appConfig.gamesDbName).doc(code).get().then((doc) => {
			if (doc.exists) {
				db.collection(appConfig.groupsDbName).where('gameId', '==', code).get().then((docs) => {
					let groups = [];
					docs.forEach((doc) => {
						let data = doc.data();
						data.id = doc.id;
						groups.push(data);
					});
					this.setState({
						groups: groups,
						isLoading: false
					});
				}).catch((error) => {
					console.error(error);
					// const msg = getText(errorTexts[errorFeedbackHelper('unknown-error')], this.props.languageId);
					const msg = 'Login error 2: ' + error.code;
					this.setState({
						feedback: msg,
						isLoading: false
					});		
				});
			} else {
				const msg = getText(errorTexts[errorFeedbackHelper('wrong-game-code')], this.props.languageId);
				this.setState({
					feedback: msg,
					isLoading: false,
					invalidGameCode: true
				});
			}
		}).catch((error) => {
			console.error(error);
			// const msg = getText(errorTexts[errorFeedbackHelper('unknown-error')], this.props.languageId);
			const msg = 'Login error 1: ' + error.code;
			this.setState({
				feedback: msg,
				isLoading: false
			});
		});	
	}	

	/**
	 * Create new group and get login token
	 * @param {object} event 
	 * @param {string} groupName 
	 * @returns 
	 */
	handleCreateGroup = (event, groupName) => {
		if (event) event.preventDefault();
		if (!groupName || groupName.length < appConfig.groupNameMinLength) {
			this.setState({feedback: getText(errorTexts.groupNameTooShort, this.props.languageId)});
			return;
		}
		if (groupName.length > appConfig.groupNameMaxLength) {
			this.setState({feedback: getText(errorTexts.groupNameTooLong, this.props.languageId)});
			return;
		}

		this.setState({isLoading: true}, () => {
			apiHelper('group/create-group', {gameCode: this.state.code, groupName: groupName})
				.then((response)=>{
					if (response.status === 'success' && response.token) {
						this.loginWithToken(response.token);
					} else {
						if (response.error === 'group-name-already-exists') {
							this.setState({
								isLoading: false, 
								feedback: getText(errorTexts.groupNameAlreadyExists, this.props.languageId)
							});
						} else {
							this.setState({isLoading: false, feedback: response.error});
						}
						
					}
				})
				.catch((rejection) => {
					console.error(rejection);
					this.setState({
						isLoading: false, 
						feedback: getText(errorTexts.unknownError, this.props.languageId)
					});
				});
		});
	 };


	/**
	 * Get a login token.
	 * Call firebase auth to sign in with that token.
	 * @param {number} groupId 
	 */
	handleGetLoginToken = (groupId) => {
		this.setState({
			isLoading: true,
			groupId: groupId
		});

		apiHelper('group/join-game', {gameCode: this.state.code, groupId: groupId}).then(
			(response)=>{
				if (response.status === 'success' && response.token) {
					this.loginWithToken(response.token);
				} else {
					console.error(response);
					const msg = getText(errorTexts[errorFeedbackHelper('unknown-error')], this.props.languageId);
					this.setState({
						feedback: msg,
						isLoading: false,
						groupId: null,
						groupName: null
					});
				}
			},
			(rejection) => {
				this.setState({
					isLoading: false,
					groups: null,
					groupId: null
				});
				console.error('rejection: ', rejection);
			}
		);
	}


	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
				this.setState({
					isLoading: false,
					groupId: null
				});
			// All okay, should trigger the authStateChanged in LoginController
			})
			.catch((error) => {
				console.error('sign in error', error);
				const msg = getText(errorTexts[errorFeedbackHelper('unknown-error')], this.props.languageId);
				this.setState({
					feedback: msg,
					isLoading: false,
					groupId: null,
					groupName: null
				});
			});
	}

	render = () => {
		return (
			<LoginGroup
				languageId={this.props.languageId}
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				handleCreateGroup={this.handleCreateGroup}
				handleGetLoginToken={this.handleGetLoginToken}
				toggleCreateNewGroupPopup={this.toggleCreateNewGroupPopup}
				code={this.state.code}
				groups={this.state.groups}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
				groupId={this.state.groupId}
				showNewGroupPopup={this.state.showNewGroupPopup}
				groupName={this.state.groupName}
				invalidGameCode={this.state.invalidGameCode}
			/>
		);
	}
}

LoginGroupController.propTypes = {
	languageId: PropTypes.string.isRequired,
};

export default LoginGroupController;
