import React from 'react';
import PropTypes from 'prop-types';
import {parametersData} from 'data/parameters-data';
import {actionsData} from 'data/actions-data';
import {formatNumber} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import Tooltip from 'components/tooltip/tooltip';
import './costs-and-production.scss';
import { generalUiTexts } from 'data/ui-texts';

const CostsAndProduction = ({isGameOver, languageId, hoverActionId, roundId, areaStats, areaData}) => {
	// If player hovers over an action animate affected parameters
	let affectedParameters = [];
	if (hoverActionId) {
		let actionData = actionsData.find((action) => {return action.id === hoverActionId;});
		if (actionData && actionData.affectedParameters) {
			affectedParameters = actionData.affectedParameters;
		}
	}

	const costsPanel = areaData.costsPanel;
	const productionPanel = areaData.productionPanel; 
	return (
		<div className={'CostsAndProduction ' + areaData.id}>
			{/* Costs panel */}
			<div className="CostsAndProduction-box costs">
				<div className="CostsAndProduction-title">
					{getText(costsPanel.title, languageId)} <span>({getText(costsPanel.subtitle, languageId)})</span>
				</div>
				{costsPanel.sections.map((section, sectionIndex) => {
					return (
						<div key={sectionIndex} className={'CostsAndProduction-section ' + section.id}>
							{section.rows.map((row, rowIndex) => {
								if (row.areaId && row.areaId !== areaData.id) return null;
								let parameterData = (row.parameterId ? 
									parametersData[row.parameterId] 
									: null
								);
								let title = (parameterData 
									? getText(parameterData.title, languageId) 
									: (row.title ? getText(row.title, languageId) : '?'));
								let value = null;
								if (areaStats.hasOwnProperty(row.parameterId)) {
									value = formatNumber(
										languageId,
										areaStats[row.parameterId], 
										(parameterData ? parameterData.decimals : 0),
										(row.isRange === true),
										(row.showFullNumbers === true)
									);
								}	
								let unit = (parameterData
									? (parameterData.unitSpace ? ' ' : '') + getText(parameterData.unit, languageId)
									: (row.unit ? ' ' + getText(row.unit, languageId) : null)
								);
								let animate = (affectedParameters.indexOf(row.parameterId) >= 0);

								return (
									<div 
										key={sectionIndex + '-' + rowIndex} 
										className={'CostsAndProduction-sectionRow' + 
											(row.class ? ' ' + row.class : '') + 
											(animate ? ' animate' : '') +
											(row.tooltipId ? ' tooltip' : '')
										}
									>
										<span>{title}:</span>
										<span>{value}{unit}</span>
										{row.tooltipId && <Tooltip 
											languageId={languageId}
											areaId={areaData.id} 
											roundId={roundId}
											tooltipId={(row.tooltipId ? row.tooltipId : null)} 
											areaStats={areaStats}
										/>}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>

			{/* Production / balance panel */}
			<div className={'CostsAndProduction-box ' + productionPanel.class}>
				<div className="CostsAndProduction-title">
					{getText(productionPanel.title, languageId) + ' '} 
					<span>({getText(productionPanel.subtitle, languageId)})</span>
				</div>
				{productionPanel.sections.map((section, sectionIndex) => {
					if (isGameOver && section.hideIfGameIsOver === true) return null;
					return (
						<div 
							key={sectionIndex} 
							className={'CostsAndProduction-section ' + section.id + (isGameOver ? ' gameover' : '')}
						>
							{section.rows.map((row, rowIndex) => {
								if (isGameOver && row.hideIfGameIsOver === true) return null;
								if (row.areaId && row.areaId !== areaData.id) return null;
								if (
									!row.parameterId || 
									!areaStats.hasOwnProperty(row.parameterId) || 
									!areaStats[row.parameterId]
								) return null;
								let parameterData = (row.parameterId 
									? (parametersData[row.parameterId]  ? parametersData[row.parameterId] : null)
									: null
								);
								let title = (parameterData 
									? getText(parameterData.title, languageId)
									: (row.title ? getText(row.title, languageId) : '?'));
								let value = null;
								if (areaStats.hasOwnProperty(row.parameterId)) {
									value = formatNumber(
										languageId,
										areaStats[row.parameterId], 
										(parameterData ? parameterData.decimals : 0),
										(row.isRange === true),
										(row.showFullNumbers === true)
									);
								}	
								let unit = (parameterData
									? (parameterData.unitSpace ? ' ' : '') + getText(parameterData.unit, languageId)
									: (row.unit ? ' ' + row.unit : null)
								);
								let animate = (affectedParameters.indexOf(row.parameterId) >= 0);
								let rampup = null;
								if (
									row.parameterId === 'equipment-capacity' && 
									areaStats.hasOwnProperty('equipment-rampup') &&
									areaStats['equipment-rampup'] > 0
								) {
									rampup =  areaStats['equipment-rampup'] * 100 + '% ' + 
										getText(generalUiTexts.rampup, languageId);
								}
								return (
									<div 
										key={sectionIndex + '-' + rowIndex} 
										className={'CostsAndProduction-sectionRow' + 
											(row.class ? ' ' + row.class : '') +
											(animate ? ' animate' : '') +
											(row.tooltipId ? ' tooltip' : '') +
											(rampup ? ' rampup' : '')
										}
									>
										<span>{title}:</span>
										<span>{value}{unit}{(rampup ? <span>{rampup}</span>  : null)}</span>
										{row.tooltipId && <Tooltip 
											languageId={languageId}
											areaId={areaData.id} 
											roundId={roundId}
											tooltipId={(row.tooltipId ? row.tooltipId : null)} 
											areaStats={areaStats}
										/>}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>		
		</div>
	);
};

CostsAndProduction.propTypes = {
	isGameOver: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	hoverActionId: PropTypes.number,
	roundId: PropTypes.number.isRequired,
	areaStats: PropTypes.object.isRequired,
	areaData: PropTypes.object.isRequired,
};

export default CostsAndProduction;
