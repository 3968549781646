const initialData = {
	production: {
		'scenario-1': 27857,
		'scenario-2': 27857,
		'scenario-3': 27857
	},
	cost: {
		'scenario-1': 111428,
		'scenario-2': 111428,
		'scenario-3': 111428
	},
	inventory: {
		'scenario-1': 9286,
		'scenario-2': 9286,
		'scenario-3': 9286
	},
	demand: {
		'scenario-1': 18571,
		'scenario-2': 18571,
		'scenario-3': 18571
	},
};

export {initialData};