import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {generalUiTexts} from 'data/ui-texts';
import {areasData} from 'data/areas-data';
import {actionsData} from 'data/actions-data';
import Action from 'components/action/action';
import {getText} from 'helpers/language-helper';
import './selected-actions.scss';

const SelectedActions = (props) => {
	const {
		isCalculatingEffects, 
		languageId,
		roundStats, 
		group, 
		handleToggleAction, 
		handleConfirmSelectedActions
	} = props;
	let groupSelectedActions = (group.selectedActions ? group.selectedActions : []);

	// Group selected actions by area 
	let selectedActionsByArea = [];
	if (groupSelectedActions && groupSelectedActions.length > 0) {
		areasData.forEach((area) => {
			let selectedActions = [];		
			groupSelectedActions.forEach((action) => {
				const actionData = actionsData.find((a) => {return a.id === action.id;});
				if (actionData.areaId === area.id) {
					selectedActions.push(actionData);
				}
			});
			if (selectedActions.length > 0) {
				selectedActionsByArea.push({
					id: area.id,
					title: area.title,
					actions: selectedActions
				});
			}
		});
	}

	// Open / close action
	let [openActionIds, setOpenActionIds] = useState([]);
	const toggleAction = (actionId) => {
		let newOpenActionIds = [...openActionIds];
		let actionIndex = newOpenActionIds.indexOf(actionId);
		if (actionIndex >= 0) {
			newOpenActionIds.splice(actionIndex, 1);
		} else {
			newOpenActionIds.push(actionId);
		}
		setOpenActionIds(newOpenActionIds);
	};	

	return (
		<div className="SelectedActions">
			<div className="SelectedActions-header">
				<span>
					{getText(generalUiTexts.roundActions, languageId) + ' '}
					{groupSelectedActions.length}/{appConfig.actionsPerRound}
				</span>
			</div>
			<div className="SelectedActions-body">
				{/* Selected actions */}
				{selectedActionsByArea.map((area) => {
					return (
						<div key={area.id} className={'SelectedActions-area ' + area.id}>
							<div className="SelectedActions-areaTitle">
								<span>{getText(area.title, languageId)}</span>
							</div>
							<div className="SelectedActions-areaActions">
								{area.actions.map((action) => {
									let isOpen = (openActionIds.indexOf(action.id) >= 0);
									return (
										<Action 
											key={action.id} 
											isOpen={isOpen}
											languageId={languageId}
											type='selected'
											areaId={area.id}
											hoverActionId={null}
											actionData={action}
											areaStats={roundStats[action.areaId]}
											group={group}
											toggleAction={toggleAction}
											handleToggleAction={handleToggleAction}
											handleHoverAction={() => {return null;}}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
				{/* Empty slots */}
				{Array(appConfig.actionsPerRound - groupSelectedActions.length).fill().map((_, i) => {
					return (
						<div key={i} className="SelectedActions-placeholder">
							<span>{getText(generalUiTexts.selectedAction, languageId)}</span>
						</div>
					);
				})}
				{/* Confirm selected actions btn */}
				<div 
					className={'SelectedActions-confirmBtn' + 
						(isCalculatingEffects ? ' loading' : '') +
						(groupSelectedActions.length < appConfig.actionsPerRound ? ' disabled' : '')}
					onClick={() => {handleConfirmSelectedActions();}}
				><span>{getText(generalUiTexts.execute, languageId)}</span></div>
			</div>
			
		</div>
	);
};

SelectedActions.propTypes = {
	isCalculatingEffects: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	roundStats: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleToggleAction: PropTypes.func.isRequired,
	handleConfirmSelectedActions: PropTypes.func.isRequired,
};

export default SelectedActions;
