import React from 'react';
import PropTypes from 'prop-types';
import {popupsData} from 'data/popups-game-data';
import {getText} from 'helpers/language-helper';
import './pause-overlay.scss';

const PauseOverlay = ({languageId}) => {
	return (
		<div className="PauseOverlay" >
			<div className="PauseOverlay-content">
				{getText(popupsData.pause.text, languageId)}
			</div>
		</div>
	);
};

PauseOverlay.propTypes = {
	languageId: PropTypes.string.isRequired
};

export default PauseOverlay;
