import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import ReactExport from 'react-export-excel';
import {roundsData} from 'data/rounds-data';
import {settingsUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {getDataSheet} from 'helpers/data-helper';
import {formatNumber} from 'helpers/game-helper';
import Background from 'components/background/background';
import UnitCostScorePopup from './unit-cost-score-popup';
import './game.scss';

const Game = (props) => {
	let {
		userData,
		gameData,
		groups,
		handleGoToGame,
		goToNextPhase,
		toggleGamePause
	} = props;

	// Toggle unit cost score popup
	const [showScorePopup, setShowScorePopup] = useState(false);
	const toggleScorePopup = () => {
		setShowScorePopup(!showScorePopup);
	};

	// Prepare data for download
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	let groupSheet = null;
	if (appConfig.env !== 'production') groupSheet = getDataSheet(gameData, groups);
	
	return (
		<div className="Game">
			<Background />
			<div className="Game-panel">
				<div className="Game-backBtn" onClick={() => {handleGoToGame(null);}}></div>
				<div className="Game-info">
					<div><span>{getText(settingsUiTexts.gameTitle, userData.languageId)}:</span> {gameData.title}</div>
					<div><span>{getText(settingsUiTexts.created, userData.languageId)}:</span> {gameData.playDate}</div>
					<div><span>{getText(settingsUiTexts.gameCode, userData.languageId)}:</span> {gameData.id}</div>
				</div>
				<div className="Game-buttons">
					{(gameData.phaseId < appConfig.numberOfPhases && !gameData.isPaused) && 
						<div className="Game-nextPhaseBtn" onClick={() => {goToNextPhase();}}>
							{getText(settingsUiTexts.openRound, userData.languageId) + ' '} 
							{gameData.phaseId + 1}
						</div>
					}
					{gameData.phaseId === appConfig.numberOfPhases && 
						<div className="Game-scorePopupBtn" onClick={() => {toggleScorePopup();}}>
							{getText(settingsUiTexts.unitCostScore, userData.languageId)} 
						</div>}
					<div 
						className={'Game-pauseBtn' + (gameData.isPaused ? ' paused' : '')} 
						onClick={() => {toggleGamePause();}}
					>
						{(gameData.isPaused 
							? getText(settingsUiTexts.unpauseGame, userData.languageId) 
							: getText(settingsUiTexts.pauseGame, userData.languageId)
						)}
					</div>
				</div>
				<div className={'Game-groups phase-' + gameData.phaseId}>
					<div className="Game-groupsHeader">
						<div className="Game-groupsName" />
						{Array(appConfig.numberOfPhases).fill().map((_, index) => {
							return (
								<div key={index} className="Game-groupsPhase">
									<div className="Game-groupsPhaseTitle">
										{getText(settingsUiTexts.round, userData.languageId)} {index + 1}
									</div>
									<div className="Game-groupsPhaseRounds">
										{roundsData.filter((round) => {
											return (round.phaseId === (index + 1) && !round.hide === true);
										}).map((round, roundIndex) => {
											return <span key={roundIndex}>{round.id}</span>;
										})}
										
									</div>
								</div>);
						})}
						<div className="Game-score">
							<span>{getText(settingsUiTexts.unitCostScore, userData.languageId)}</span>
						</div>
					</div>

					<div className="Game-groupContainer">
						{groups.map((group, index) => {
							let unitScoreCost = null;
							if (group.isGameOver === true) {
								let groupRound = group.rounds.find((round) => {
									return (round.id === 8 && round.results);
								});
								if (groupRound && groupRound.results && groupRound.results.gameScore) {
									unitScoreCost = formatNumber(
										userData.languageId, groupRound.results.gameScore, 2, false);
								}
							}

							return (
								<div key={index} className={'Game-group' + (group.isPlaying ? ' active' : '')}>
									<div className="Game-groupWrap">
										<div className="Game-groupTitle">{group.title}</div>
										{roundsData.map((round, index) => {
											if (round.hide === true) return null;
											let groupRound = group.rounds.find((gr) => {
												return (gr.id === round.id && gr.results && gr.results.unitCost);
											});
											let status = 'ready';
											if (
												group.isPlaying === true && 
												round.id === group.roundId && 
												round.phaseId <= gameData.phaseId
											) status = 'current';
											if (round.id < group.roundId || group.isGameOver === true) status = 'done';
											if (status === 'ready') return null;
											return (
												<div key={index} className={'Game-groupRoundStatus ' + status}>
													{groupRound && <div className="Game-groupRoundStatusTooltip"><span>
														{groupRound 
															? 'Unit Cost: ' + 
																formatNumber(
																	userData.languageId, 
																	groupRound.results.unitCost, 
																	1, 
																	false
																) 
																+ ' NB/Unit'
															: null
														}
													</span></div>}
												</div>
											);
										})}
										{unitScoreCost && <div className="Game-unitScoreCost">{unitScoreCost}</div>}

									</div>
								</div>
							);
						})}
					</div>
				</div>

				{groupSheet && 
					<ExcelFile 
						element={
							<button className="Game-downloadBtn">
								{getText(settingsUiTexts.downloadData, userData.languageId)}
							</button>
						}
					>
						<ExcelSheet data={groupSheet.data} name={groupSheet.name}>
							{groupSheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					</ExcelFile>
				}
			</div>
			{showScorePopup && 
				<UnitCostScorePopup 
					languageId={userData.languageId}
					groups={groups}
					toggleScorePopup={toggleScorePopup}
				/>
			}
		</div>
	);
};

Game.propTypes = {
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	goToNextPhase: PropTypes.func.isRequired,
	toggleGamePause: PropTypes.func.isRequired,
};

export default Game;
