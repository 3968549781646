import {initialData} from 'data/initial-data';
import {reportData} from 'data/report-data';
import {roundsData} from 'data/rounds-data';
import {areasData} from 'data/areas-data';
import {actionsData} from 'data/actions-data';
import {workEnvironmentData} from 'data/work-environment-data';
import {formatNumber, getTotalUnitCost} from 'helpers/game-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getText} from 'helpers/language-helper';
import appConfig from 'config/app.config';
import { generalUiTexts } from 'data/ui-texts';

const replaceDynamicContent = (languageId, scenarioId, text, results, conditionsData, roundStats, roundId) => {
	
	let streakCount = (conditionsData ? conditionsData.streakCount : null);
	let bottleNeckAreaIds = (conditionsData ? conditionsData.bottleNeckAreaIds : null);
	let materialsAreaIds = (conditionsData ? conditionsData.materialsAreaIds : null);
	let newMachinesAreaIds = (conditionsData ? conditionsData.newMachinesAreaIds : null);

	let updatedText = JSON.parse(JSON.stringify(text));

	const production = results.production;
	if (typeof production === 'number') {
		updatedText = updatedText.replace(/%production%/g, formatNumber(languageId, production, 0, false));
	}
	
	const demand = results.actualDemand;
	if (typeof demand === 'number') {
		updatedText = updatedText.replace(/%demand%/g, formatNumber(languageId, demand, 0, false));
	}

	const inventory = results.inventory;
	if (typeof inventory === 'number') {
		updatedText = updatedText.replace(/%inventory-primo%/g, formatNumber(languageId, inventory, 0, false));
	}

	const inventoryNow = results.inventoryUltimo;
	if (typeof inventoryNow === 'number') {
		updatedText = updatedText.replace(/%inventory%/g, formatNumber(languageId, inventoryNow, 0, false));
	}

	const unitCost = results.unitCost;
	if (typeof unitCost === 'number') {
		updatedText = updatedText.replace(/%unitcost%/g, formatNumber(languageId, unitCost, 1, false));
	}
	
	if (results.extraProduction > 0 && results.originalUnitCost) {
		updatedText = updatedText.replace(/%extra-production%/g, 
			formatNumber(languageId, results.extraProduction, 0, false));
		updatedText = updatedText.replace(/%original-unit-cost%/g, 
			formatNumber(languageId, results.originalUnitCost, 1, false));
	}

	const workEnvironment = results.workEnvironment;
	if (workEnvironment) {
		let weData = workEnvironmentData.find((we) => {
			return workEnvironment >= we.minVal && workEnvironment <= we.maxVal;
		});
		if (weData) {
			updatedText = updatedText.replace(/%workEnvironmentTitle%/g, '<i>' + 
				getText(weData.title, languageId) + '</i>');
		}
	}

	if (roundStats) {
		let initialUnitCost = (initialData.cost[scenarioId] / initialData.production[scenarioId]);
		let currentUnitCost = getTotalUnitCost(roundStats);
		let unitCostDifference = (currentUnitCost - initialUnitCost);
		let unitCostDirection = (unitCostDifference > 0 
			? getText(generalUiTexts.increased, languageId)
			: getText(generalUiTexts.decreased, languageId)
		);

		unitCostDifference = formatNumber(languageId, Math.abs(unitCostDifference), 1, false) + 
			+ ' ' + getText(generalUiTexts.costPerUnit, languageId);
		let roundsLeft = (8 - roundId + 1);
		roundsLeft = roundsLeft + ' ' + (roundsLeft > 1 
			? getText(generalUiTexts.roundsLeft, languageId) 
			: getText(generalUiTexts.roundLeft, languageId)
		);
		updatedText = updatedText.replace(/%unitCostDirection%/g, unitCostDirection);
		updatedText = updatedText.replace(/%unitCostDifference%/g, unitCostDifference);
		updatedText = updatedText.replace(/%roundsLeft%/g, roundsLeft);

		let totalMaterialCostPrimo = 0;
		let totalFixedCosts = 0;
		areasData.forEach((area) => {
			totalFixedCosts = totalFixedCosts + roundStats[area.id]['total'];
			if (roundStats[area.id].hasOwnProperty('materials')) {
				totalMaterialCostPrimo = totalMaterialCostPrimo + roundStats[area.id]['materials'];
			}
			if (roundStats[area.id].hasOwnProperty('expenses')) {
				totalFixedCosts = totalFixedCosts - roundStats[area.id]['expenses'];
			}
			if (roundStats[area.id].hasOwnProperty('machines')) {
				totalFixedCosts = totalFixedCosts - roundStats[area.id]['machines'];
			}
		});
		updatedText = updatedText.replace(/%totalMaterialCostPrimo%/g, 
			formatNumber(languageId, totalMaterialCostPrimo, 0, false));
		updatedText = updatedText.replace(/%totalFixedCosts%/g, formatNumber(languageId, totalFixedCosts, 0, false));

		let wasteInfo = 
			'<table><thead><tr>' +
			'<td>' + getText(generalUiTexts.department, languageId) + '</td>' +
			'<td>' + getText(generalUiTexts.waste, languageId) + '</td>' +
			'</tr></thead><tbody>';
		areasData.forEach((area) => {
			wasteInfo += '<tr><td>' + getText(area.title, languageId) + '</td><td>' + 
		formatNumber(languageId, roundStats[area.id]['waste'], 2, false) + '%</td></tr>';
		});
		wasteInfo += '</tbody></table>';
		updatedText = updatedText.replace(/%waste-info%/g, wasteInfo);
	}

	if (streakCount) {
		updatedText = updatedText.replace(/%numberOfRounds%/g, streakCount);
	}

	if (bottleNeckAreaIds) {
		let lowCapacityAreaData = areasData.find((area) => {return area.id === bottleNeckAreaIds.low.id;});
		let highCapacityAreaData = areasData.find((area) => {return area.id === bottleNeckAreaIds.high.id;});
		if (lowCapacityAreaData && highCapacityAreaData) {
			// Note: cannot use e.g. bottleNeckAreaIds.high.value as there might be effects between rounds 
			let lowCapacity = roundStats[lowCapacityAreaData.id]['effective-capacity'];
			updatedText = updatedText.replace(/%lowCapacityAreaTitle%/g, 
				getText(lowCapacityAreaData.title, languageId));
			updatedText = updatedText.replace(
				/%lowCapacityAreaEffectiveCapacity%/g, formatNumber(languageId, lowCapacity, 0, false)
			);			
			let highCapacity = roundStats[highCapacityAreaData.id]['effective-capacity'];
			updatedText = updatedText.replace(/%highCapacityAreaTitle%/g, 
				getText(highCapacityAreaData.title, languageId));
			updatedText = updatedText.replace(
				/%highCapacityAreaEffectiveCapacity%/g, formatNumber(languageId, highCapacity, 0, false)
			);
		}
	}

	if (materialsAreaIds) {
		let materialsAreaTitles = '';
		materialsAreaIds.forEach((areaId, index) => {
			let areaData = areasData.find((area) => {return area.id === areaId;});
			if (areaData) {
				if (index === 0) {
					materialsAreaTitles = getText(areaData.title, languageId);
				} else {
					if (index + 1 < materialsAreaIds.length) {
						materialsAreaTitles = materialsAreaTitles + ', ' + getText(areaData.title, languageId);
					} else {
						materialsAreaTitles = materialsAreaTitles + ' ' + 
							generalUiTexts.listAnd + ' ' + getText(areaData.title, languageId);
					}
				}
			}
		});
		updatedText = updatedText.replace(/%materialsAreaTitles%/g, materialsAreaTitles);
	}

	if (newMachinesAreaIds) {
		let machineAreaTitles = '';
		let machineEfficiencies = '';
		newMachinesAreaIds.forEach((machineArea, index) => {
			let areaData = areasData.find((area) => {return area.id === machineArea.areaId;});
			if (areaData) {
				if (index === 0) {
					machineAreaTitles = getText(areaData.title, languageId);
				} else {
					if (index + 1 < newMachinesAreaIds.length) {
						machineAreaTitles = machineAreaTitles + ', ' + getText(areaData.title, languageId);
					} else {
						machineAreaTitles = machineAreaTitles + ' ' + 
							generalUiTexts.listAndIn + ' ' + getText(areaData.title, languageId);
					}
				}

				let machineInfoText = JSON.parse(JSON.stringify(
					getText(generalUiTexts.effectNowAndWhenImplemented, languageId)
				));
				machineInfoText = machineInfoText.replace(/%currentCapacity%/g, 
					formatNumber(languageId, roundStats[areaData.id]['effective-capacity'], 0, false));
				machineInfoText = machineInfoText.replace(/%percent%/g, roundStats[areaData.id]['equipment-rampup']);
				machineInfoText = machineInfoText.replace(/%finalCapacity%/g, 
					formatNumber(languageId, roundStats[areaData.id]['effective-capacity'] / 
					roundStats[areaData.id]['equipment-rampup'], 0, false));

				machineEfficiencies = 
					machineEfficiencies + '<p>' + getText(areaData.title, languageId) + ': ' + machineInfoText + '</p>';
				// formatNumber(languageId, roundStats[areaData.id]['effective-capacity'], 0, false) + 
				// 	' ' + getText(generalUiTexts.units, 'da').toLowerCase() + ' (' +
				// roundStats[areaData.id]['equipment-rampup'] * 100 + 
				// 	getText(generalUiTexts.percent, 'da') + ' ' + 
				// 	getText(generalUiTexts.rampup, 'da') + '), ' + 
				// 	getText(generalUiTexts.and, 'da') + ' ' +
				// 	formatNumber(languageId, roundStats[areaData.id]['effective-capacity'] / 
				// 		roundStats[areaData.id]['equipment-rampup'], 0, false) + 
				// 		' units når den er kørt ind.</p>';
			}
		});
		updatedText = updatedText.replace(/%areaTitles%/g, machineAreaTitles);
		updatedText = updatedText.replace(/%newMachineEffectiveCapacities%/g, machineEfficiencies);
	}

	return updatedText;
};

const getReportSnippets = (currentRoundIndex, rounds, lockedReportSnippetIds) => {
	let reportSnippets = [];
	let maxGroupPriority = Math.max.apply(Math, reportData.groups.map((g) => { return g.priority;}));
	// Loop over group priorities
	for (let groupPriority = 1; groupPriority <= maxGroupPriority; groupPriority++) {
		if (reportSnippets.length >= appConfig.reportSnippets) break;
		// Loop over groups with current priority, select 1 text from each group (all for group 'extra')
		// eslint-disable-next-line no-loop-func
		reportData.groups.filter((group) => {return group.priority === groupPriority;}).forEach((group) => {
			let snippetsInGroup = [];
			reportData.snippets.forEach((snippet) => {
				if (snippet.groupId === group.id && lockedReportSnippetIds.indexOf(snippet.id) < 0) {
					const snippetData = checkIfReportConditionsAreMet(currentRoundIndex, rounds, snippet);
					if (snippetData.conditionsMet === true) {
						snippetsInGroup.push({
							id: snippet.id,
							groupId: snippet.groupId,
							snippetData: snippetData,
							groupPriority: groupPriority,
							priority: snippet.priority,
							order: snippet.order,
						});
					}
				}
			});
			if (snippetsInGroup.length > 0) {
			// Sort by priority in group
				snippetsInGroup = sortArrayByProperty(snippetsInGroup, 'priority', 'ASC');
				if (group.id === 'extra') {
					// Select all snippets in group
					snippetsInGroup.forEach((snippet) => {reportSnippets.push(snippet);});
					// let snippetIndex = Math.floor(Math.random() * snippetsInGroup.length);
					// relevantTexts.push(snippetsInGroup[snippetIndex]);
				} else {
					// Select highest priority snippet in group
					reportSnippets.push(snippetsInGroup[0]);
				}
			}
		});
	};

	reportSnippets = sortArrayByProperty(reportSnippets, 'groupPriority', 'ASC', 'order', 'ASC');
	reportSnippets.splice(appConfig.reportSnippets, reportSnippets.length);


	return reportSnippets;
};

const getTextSnippets = (languageId, scenarioId, roundId, rounds, roundStats) => {
	let textSnippets = null;
	let currentRoundIndex = roundsData.findIndex((round) => {return roundId === round.id;});
	let currentGroupRound = rounds.find((round) => {return round.id === roundId;});
	const prevRoundData = roundsData[currentRoundIndex - 1];
	let prevGroupRound = rounds.find((round) => {return round.id === prevRoundData.id;});
	if (prevGroupRound && prevGroupRound.results && currentGroupRound && currentGroupRound.reportSnippets) {
		textSnippets = [];
		currentGroupRound.reportSnippets.forEach((groupSnippet) => {
			let snippetData = reportData.snippets.find((snippet) => {return snippet.id === groupSnippet.id;});
			let snippetGroupData = groupSnippet.snippetData;
			if (snippetData && snippetGroupData) {
				let snippetText = JSON.parse(JSON.stringify(getText(snippetData.text, languageId)));
				if (
					snippetGroupData.newMachinesAreaIds && 
					snippetGroupData.newMachinesAreaIds.length > 1) {
					snippetText = getText(snippetData.text2, languageId);
				}
				const text = replaceDynamicContent(
					languageId,
					scenarioId,
					snippetText, 
					prevGroupRound.results, 
					snippetGroupData, 
					roundStats,
					roundId
				);
				textSnippets.push(text);
			}
		});
	}
	return textSnippets;
};

/**
 * Check if conditions are met
 * @param {number} currentRoundIndex 
 * @param {array} rounds 
 * @param {object} snippet 
 * @returns {object} snippetData
 */
const checkIfReportConditionsAreMet = (currentRoundIndex, rounds, snippet) => {
	let conditionsAreMet = true;
	let streakCount = null;
	let bottleNeckAreaIds = null;
	// let numberOfConditionActionsSelected = null;
	let materialsAreaIds = null;
	let newMachinesAreaIds = null;
	const currentRoundData = roundsData[currentRoundIndex];
	const prevRoundData = roundsData[currentRoundIndex - 1];
	let prevGroupRound = rounds.find((round) => {return round.id === prevRoundData.id;});
	let	currentGroupRound = rounds.find((round) => {return round.id === currentRoundData.id;});
	
	// Snippet was shown in previous round 
	if (
		prevGroupRound && 
		prevGroupRound.reportSnippets &&
		prevGroupRound.reportSnippets.some((s) => {
			return s.id === snippet.id;
		})
	) {
		return {conditionsMet: false};
	}


	// Snippet was NOT shown in previous round, check conditions
	snippet.conditions.forEach((condition) => {
		if (conditionsAreMet) {
			let conditionRecognized = false;

			// Check round id
			if (condition.type === 'not-round-id') {
				conditionRecognized = true;
				if (currentRoundData.id === condition.roundId) {
					conditionsAreMet = false;
				}
			}
			if (condition.type === 'round-id') {
				conditionRecognized = true;
				if (condition.hasOwnProperty('minRoundId') && currentRoundData.id < condition.minRoundId) {
					conditionsAreMet = false;
				}
				if (condition.hasOwnProperty('roundId') && currentRoundData.id !== condition.roundId) {
					conditionsAreMet = false;
				}
			}

			// Count actions taken (in x rounds)
			if (condition.type === 'count-actions') {
				conditionRecognized = true;
				let numberOfActions = 0;
				let roundIndexLimit = Math.max(0, currentRoundIndex - 1 - condition.maxRounds);
				for (let i = (currentRoundIndex - 1); i >= roundIndexLimit; i--) {
					if (condition.hasOwnProperty('minNumber') && numberOfActions >= condition.minNumber) break;
					if (condition.hasOwnProperty('maxNumber') && numberOfActions > condition.maxNumber) break;
					if (
						rounds[i].results && 
						rounds[i].results.actions && 
						condition.actionIds && 
						rounds[i].results.actions.some((action) => {
							return condition.actionIds.indexOf(action.id) >= 0;
						})
					) {
						numberOfActions = numberOfActions + rounds[i].results.actions.filter((action) => {
							return condition.actionIds.indexOf(action.id) >= 0;
						}).length;
					}
				}
				if (
					condition.hasOwnProperty('minNumber') && numberOfActions < condition.minNumber
				) conditionsAreMet = false;
				if (
					condition.hasOwnProperty('maxNumber') && numberOfActions > condition.maxNumber
				) conditionsAreMet = false;

				if (conditionsAreMet) {
					if (snippet.type === 'search-materials-market') {
						materialsAreaIds = [];
						condition.actionIds.forEach((actionId) => {
							if (prevGroupRound.results.actions.some((action) => {return action.id === actionId;})) {
								let actionData = actionsData.find((a) => {return a.id === actionId;});
								if (actionData) materialsAreaIds.push(actionData.areaId);
							}
						});
					}
					if (snippet.type === 'new-machines') {
						newMachinesAreaIds = [];
						condition.actionIds.forEach((actionId) => {
							if (prevGroupRound.results.actions.some((action) => {return action.id === actionId;})) {
								let actionData = actionsData.find((a) => {return a.id === actionId;});
								if (actionData) {
									newMachinesAreaIds.push({
										areaId: actionData.areaId,
										operatorTeams: currentGroupRound[actionData.areaId]['operator-teams'],
										supportTeams: currentGroupRound[actionData.areaId]['support-teams'],
									});
								};
							}
						});
					}
				}
			}

			// Check result property value
			if (condition.type === 'result-property') {
				conditionRecognized = true;
				if (
					prevGroupRound.results.hasOwnProperty(condition.property) &&
					condition.value !== prevGroupRound.results[condition.property]
				) conditionsAreMet = false;
			}

			// Check streaks
			if (condition.type === 'result-property-streak') {
				conditionRecognized = true;
				streakCount = getStreakCount(condition, currentRoundIndex, rounds);		
				if (streakCount < condition.minStreak) conditionsAreMet = false;
			}

			// Staff change in relation to bottleneck (always comes after bottleneck condition)
			if (condition.type === 'staff-change-in-area') {
				conditionRecognized = true;
				if (bottleNeckAreaIds) {
					let selectedActions = prevGroupRound.results.actions;
					let staffActions = selectedActions.filter((action) => {
						let actionData = actionsData.find((a) => {return a.id === action.id;});
						return (
							actionData && 
							actionData.areaId === bottleNeckAreaIds[condition.areaEffectiveCapacity].id
						);
					});

					let staffAdded = staffActions.some((action) => {return action.parameterAdjustment > 0;});
					let staffRemoved = staffActions.some((action) => {return action.parameterAdjustment < 0;});
					if (condition.hasOwnProperty('increase') && staffAdded !== condition.increase) {
						conditionsAreMet = false;
					}
					if (condition.hasOwnProperty('decrease') && staffRemoved !== condition.decrease) {
						conditionsAreMet = false;
					}
				} else {
					conditionsAreMet = false;
					console.error('error: no bottleneck data');
				}
			}

			// Bottleneck before actions
			if (condition.type === 'bottleneck-before-actions') {
				conditionRecognized = true;
				if (
					(condition.value === true && !prevGroupRound.results.preActionsBottleneck) ||
					(condition.value === false && prevGroupRound.results.preActionsBottleneck)
				) {
					conditionsAreMet = false;
				}
				if (prevGroupRound.results.preActionsBottleneck) {
					bottleNeckAreaIds = prevGroupRound.results.preActionsBottleneck;
				}
			}

			// Bottleneck
			if (condition.type === 'bottleneck') {
				conditionRecognized = true;
				if (
					(condition.value === true && !prevGroupRound.results.bottleneck) ||
					(condition.value === false && prevGroupRound.results.bottleneck)
				) {
					conditionsAreMet = false;
				}
				if (prevGroupRound.results.bottleneck) bottleNeckAreaIds = prevGroupRound.results.bottleneck;
			}
			
			if (!conditionRecognized) console.error('unknown condition type: ', condition.type);
		}
	});	

	return {
		conditionsMet: conditionsAreMet, 
		streakCount, 
		bottleNeckAreaIds,
		materialsAreaIds,
		newMachinesAreaIds
	};
};

/**
 * Get streak count
 * @param {object} condition 
 * @param {number} currentRoundIndex 
 * @param {array} rounds 
 * @returns {number} streakCount
 */
const getStreakCount = (condition, currentRoundIndex, rounds) => {
	let streakCount = 0;
	for (let i = currentRoundIndex - 1; i >= 0; i--) {
		// Compare with specific value
		if (condition.hasOwnProperty('maxVal')) {
			if (
				rounds[i].results && 
				rounds[i].results[condition.property] && 
				rounds[i].results[condition.property] <= condition.maxVal
			) {
				streakCount = streakCount + 1;
			} else {
				break;
			}
		}

		// Compare with other property
		if (
			condition.hasOwnProperty('compareToProperty') && 
			rounds[i].results &&
			rounds[i].results[condition.property] && 
			rounds[i].results[condition.compareToProperty] && (
				(condition.lessThan === true && 
					(rounds[i].results[condition.property] <= 
						rounds[i].results[condition.compareToProperty] * (1 - condition.fraction))
				) ||
				(condition.lessThan === false &&
					(rounds[i].results[condition.property] >
						rounds[i].results[condition.compareToProperty] * (1 + condition.fraction))
				)
			)
		) {
			streakCount = streakCount + 1;
		} else {
			break;
		}
	}
	return streakCount;
};





export {
	replaceDynamicContent,
	getReportSnippets,
	getTextSnippets,
};