import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import {popupsData} from 'data/popups-facilitator-data';
import {languagesData} from 'data/languages-data';
import {scenariosData} from 'data/scenarios-data';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';
import DatePicker from 'react-datepicker';
import Select from 'components/select/select';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './create-game-popup.scss';

const CreateGamePopup = (props) => {
	let {
		isCreatingGame,
		languageId,
		errMsg,
		toggleCreateGamePopup,
		handleCreateGame,
	} = props;

	/* Title */
	let [gameTitle, setGameTitle] = useState('');
	const updateGameTitle = (event) => {
		setGameTitle(event.target.value);
	};

	/* Date */
	let [gameDate, setGameDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
	const updateGameDate = (value) => {
		if (value !== 'Invalid Date') setGameDate(value);
	};

	/* Language */
	let [gameLanguage, setGameLanguage] = useState(languageId);

	/* Scenario */
	let [gameScenario, setGameScenario] = useState(scenariosData[0].id);


	/* Verify  */
	const inputIsValid = () => {
		return (
			gameTitle.length > 0 && 
			gameTitle.length <= appConfig.gameTitleMaxLength
		);
	};

	return (
		<div className="CreateGamePopup" onClick={() => {toggleCreateGamePopup();}}>
			<div className="CreateGamePopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="CreateGamePopup-header">
					<div className="CreateGamePopup-title">
						{getText(popupsData.createGame.title, languageId)}
					</div>
				</div>
				<div className="CreateGamePopup-body">
					<div className="CreateGamePopup-form">
						<div className="CreateGamePopup-label">
							{getText(popupsData.createGame.gameTitleLabel, languageId)}
						</div>
						<input
							disabled={isCreatingGame}
							name="gameTitle"
							type="text"
							maxLength={appConfig.gameTitleMaxLength}
							placeholder={getText(popupsData.createGame.gameTitlePlaceholder, languageId)}
							value={gameTitle ? gameTitle : ''}
							onChange={(event) => {updateGameTitle(event);}}
						/>
						<div className="CreateGamePopup-label">
							{getText(popupsData.createGame.gamePlayDateLabel, languageId)}
						</div>
						<DatePicker
							disabled={false}
							selected={gameDate ? dayjs(gameDate).toDate() : new Date()}
							onChange={(date) => {updateGameDate(dayjs(date).format('YYYY-MM-DD'));}}
							dateFormat="yyyy-MM-dd"
							placeholderText="yyyy-mm-dd"
						/>
						<div className="CreateGamePopup-label">
							{getText(popupsData.createGame.gameLanguageLabel, languageId)}
						</div>
						<Select 
							type="createGameLanguage"
							isDisabled={false}
							languageId={languageId}
							defaultId={gameLanguage}
							selectedId={gameLanguage}
							options={languagesData}
							onSelect={setGameLanguage}
						/>
						<div className="CreateGamePopup-label">
							{getText(popupsData.createGame.gameScenarioLabel, languageId)}
						</div>
						<Select 
							type="createGameScenario"
							isDisabled={false}
							languageId={languageId}
							defaultId={gameScenario}
							selectedId={gameLanguage}
							options={scenariosData}
							onSelect={setGameScenario}
						/>
						<div className="CreateGamePopup-buttons">
							<Button
								isDisabled={!inputIsValid()}
								classes={['popup']}
								isLoading={isCreatingGame}
								text={getText(popupsData.createGame.create, languageId)}
								onClick={() => {handleCreateGame(gameTitle, gameDate, gameLanguage, gameScenario);}}
							/>
							<Button
								classes={['popup', 'cancel']}
								text={getText(popupsData.createGame.cancel, languageId)}
								onClick={() => {toggleCreateGamePopup();}}
							/>
						</div>
						<div className="CreateGamePopup-errMsg">{errMsg}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

CreateGamePopup.propTypes = {
	isCreatingGame: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	errMsg: PropTypes.string,
	toggleCreateGamePopup: PropTypes.func.isRequired,
	handleCreateGame: PropTypes.func.isRequired,
};

export default CreateGamePopup;
