/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {roundsData} from 'data/rounds-data';
import './round-track.scss';

const RoundTrack = ({page, group, animateRoundId}) => {
	
	return (
		<div className={'RoundTrack ' + page}>
			{roundsData.map((round, i) => {
				if (round.hide === true) return null;
				return (
					<div key={i} className={'RoundTrack-round' +
						(round.id === group.roundId ? ' selected' : '') +
						(round.id === group.roundId && animateRoundId ? ' blink' : '')
					}>
						<span>{i + 1}</span>
					</div>
				);
			})}
		</div>
	);
};

RoundTrack.propTypes = {
	page: PropTypes.string,
	group: PropTypes.object.isRequired,
	animateRoundId: PropTypes.bool
};

export default RoundTrack;
