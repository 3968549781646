
/**
 * Get size of image that has size = cover
 * @param {number} containerWidth 
 * @param {number} containerHeight 
 * @param {number} imageRatio 
 */
function getImageSize(containerWidth, containerHeight, imageRatio) {
	let containerRatio = containerWidth / containerHeight;

	let imageWidth = containerWidth;
	let imageHeight = containerHeight;

	if (imageRatio > containerRatio) {
		/* height is 100%, width is > 100% */
		imageWidth = imageRatio * containerHeight;
	}
	if (imageRatio < containerRatio) {
		/* width is 100%, height is > 100% */
		imageHeight = containerWidth / imageRatio;
	}

	return [imageWidth, imageHeight];
}



/**
 * Get adjusted x position
 * @param {number} containerWidth 
 * @param {number} imageWidth 
 * @param {string} imageAlignment 
 * @param {array} originalXPos 
 */
function getXPosition(containerWidth, imageWidth, imageAlignment, originalXPos) {
	let xPos = originalXPos;
	if (imageAlignment === 'center') {
		xPos =  ( 
			((((originalXPos * imageWidth) / 100. ) - 
				((imageWidth - containerWidth) / 2.)) / containerWidth) * 100.
		);
	}

	if (imageAlignment === 'left') {
		xPos = (originalXPos * imageWidth) / containerWidth;
	}

	if (imageAlignment === 'right') {
		xPos =  ( 
			((((originalXPos * imageWidth) / 100. ) - 
				(imageWidth - containerWidth)) / containerWidth) * 100.
		);
	}

	return xPos;
}

/**
 * Get adjust y position
 * @param {number} containerHeight 
 * @param {number} imageHeight 
 * @param {string} imageAlignment 
 * @param {array} originalYPos 
 */
function getYPosition(containerHeight, imageHeight, imageAlignment, originalYPos) {
	let yPos = originalYPos;

	if (imageAlignment === 'top') {
		yPos = (originalYPos * imageHeight) / containerHeight;
	}

	if (imageAlignment === 'center') {
		yPos = (
			((((originalYPos * imageHeight) / 100. ) - 
			((imageHeight - containerHeight) / 2.)) / containerHeight) * 100.
		);
	}

	if (imageAlignment === 'bottom') {
		yPos = (
			((((originalYPos * imageHeight) / 100. ) - 
			(imageHeight - containerHeight)) / containerHeight) * 100.
		);
	}

	return yPos;

}

export {getImageSize, getXPosition, getYPosition};