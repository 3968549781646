import React from 'react';
import PropTypes from 'prop-types';
import './confirm-button.scss';

const ConfirmButton = (props) => {
	let {
		isConfirmed,
		isDisabled,
		actionId, 
		parameterAdjustment,
		handleToggleAction,
	} = props;

	return (
		<div 
			className={'ConfirmButton ' + (isConfirmed ? ' checked' : '') + (isDisabled ? ' disabled' : '')} 
			onClick={() => {if (!isDisabled) handleToggleAction(actionId, parameterAdjustment);}}
		/>
	);
};

ConfirmButton.propTypes = {
	isConfirmed: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	actionId: PropTypes.number.isRequired,
	parameterAdjustment: PropTypes.number,
	handleToggleAction: PropTypes.func.isRequired
};

export default ConfirmButton;
