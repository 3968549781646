// Convert seconds to dd/mm-yy
export function formatDate(seconds) {
	let dateObj = new Date(seconds * 1000);
	let month = String(dateObj.getMonth() + 1);
	let day = String(dateObj.getDate());
	const year = String(dateObj.getFullYear());

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return `${day}/${month}-${year}`;
}