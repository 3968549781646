import React from 'react';
import PropTypes from 'prop-types';
import {formatDate} from 'helpers/date-helper';
import {getText} from 'helpers/language-helper';
import {statsUiTexts} from 'data/ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Background from 'components/background/background';
import './stats.scss';

const Stats = (props) => {
	const {
		languageId,
		statsType,
		users,
		games,
		showStatsOfType,
		downloadEmails,
		sortUsersBy,
		sortGamesBy,
		goToPage
	} = props;

	return (
		<div className="Stats">
			<Background />
			<div className="Stats-panel">
				<div className="Stats-title">{getText(statsUiTexts.statistics, languageId)}</div>
				<div className="Stats-backBtn" onClick={() => {goToPage(null);}}/>
			
				{/* Navigation */}
				<div className="Stats-navigation">
					<div 
						className={'Stats-navBtn' + (statsType === 'users' ? ' selected' : '')}
						onClick={() => {showStatsOfType('users');}}	
					>{getText(statsUiTexts.facilitators, languageId)} ({users.length})</div>
					<div 
						className={'Stats-navBtn' + (statsType === 'games' ? ' selected' : '')}
						onClick={() => {showStatsOfType('games');}}	
					>{getText(statsUiTexts.games, languageId)} ({games.length})</div>
				</div>


				{/* User stats */}
				<div className={'Stats-users' + (statsType === 'users' ? ' show' : '')}>
					<div 
						className="Stats-downloadBtn" 
						onClick={() => {downloadEmails();}}>{getText(statsUiTexts.downloadEmails, languageId)}</div>
					<table>
						<thead>
							<tr>
								<td className="clickable" onClick={() => {sortUsersBy('email');}}>
									{getText(statsUiTexts.email, languageId)}
								</td>
								<td className="clickable" onClick={() => {sortUsersBy('created');}}>
									{getText(statsUiTexts.created, languageId)}
								</td>
								<td>{getText(statsUiTexts.numberOfGames, languageId)}</td>
							</tr>
						</thead>
						<tbody>
							{users.map((user, index) => {
								return (
									<tr key={index}>
										<td>{user.email}</td>
										<td>{user.created ? formatDate(user.created) : ''}</td>
										<td>{user.games.length}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				{/* Game stats */}
				<div className={'Stats-games' + (statsType === 'games' ? ' show' : '')}>
					<table>
						<thead>
							<tr>
								<td className="clickable" onClick={() => {sortGamesBy('created');}}>
									{getText(statsUiTexts.created, languageId)}
								</td>
								<td className="clickable" onClick={() => {sortGamesBy('facilitatorId');}}>
									{getText(statsUiTexts.facilitator, languageId)}
								</td>
								<td className="clickable" onClick={() => {sortGamesBy('scenarioId');}}>
									{getText(statsUiTexts.scenario, languageId)}
								</td>
								<td className="clickable" onClick={() => {sortGamesBy('phaseId');}}>
									{getText(statsUiTexts.round, languageId)}
								</td>
								<td>{getText(statsUiTexts.groups, languageId)}</td>
							</tr>
						</thead>
						<tbody>
							{games.map((game, index) => {
								let gameScenarioNumber = (game.scenarioId 
									? game.scenarioId.charAt(game.scenarioId.length - 1) 
									: '-'
								);
								return (
									<tr key={index}>
										<td>{game.created ? formatDate(game.created) : '-'}</td>
										<td>{game.facilitatorEmail}</td>
										<td>{gameScenarioNumber}</td>
										<td>{game.phaseId}</td>
										<td>{game.groups.length}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			
		</div>
	);
};

Stats.propTypes = {
	languageId: PropTypes.string.isRequired,
	statsType: PropTypes.string.isRequired,
	users: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	showStatsOfType: PropTypes.func.isRequired,
	downloadEmails: PropTypes.func.isRequired,
	sortUsersBy: PropTypes.func.isRequired,
	sortGamesBy: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Stats;