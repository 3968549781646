import React from 'react';
import PropTypes from 'prop-types';
import ConfirmButton from './confirm-button';
import {formatNumber} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import './buy-item.scss';

const BuyItem = (props) => {
	const {isConfirmed, confirmBtnIsDisabled, languageId, actionData, selectedActionData, handleToggleAction} = props;

	let isMandatory = (selectedActionData && selectedActionData.isMandatory === true ? true : false);
	
	return (
		<div className="BuyItem">
			<div className="ChangeParameter-totalCost">
				{actionData.totalCost > 0 && <span>
					{formatNumber(languageId, actionData.totalCost, 0, false)} {getText(actionData.unit, languageId)}
				</span>}
			</div>
			<ConfirmButton 
				isConfirmed={isConfirmed}
				isDisabled={confirmBtnIsDisabled || isMandatory}
				actionId={actionData.id}
				handleToggleAction={handleToggleAction}
			/>
		</div>
	);
};

BuyItem.propTypes = {
	isConfirmed: PropTypes.bool.isRequired,
	confirmBtnIsDisabled: PropTypes.bool,
	languageId: PropTypes.string.isRequired,
	actionData: PropTypes.object.isRequired,
	selectedActionData: PropTypes.object,
	handleToggleAction: PropTypes.func.isRequired,
};

export default BuyItem;
