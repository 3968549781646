import {generalUiTexts} from './ui-texts';

const pagesData = [
	{
		id: 'overview',
		type: 'overview',
		title: generalUiTexts.overview
	},
	{
		id: 'wash-and-sterilization',
		type: 'area',
		areaId: 'wash-and-sterilization',
		title: generalUiTexts.washAndSterilization
	},
	{
		id: 'formulation-and-stabilization',
		type: 'area',
		areaId: 'formulation-and-stabilization',
		title: generalUiTexts.formulationAndStabilization
	},
	{
		id: 'filling-and-inspection',
		type: 'area',
		areaId: 'filling-and-inspection',
		title: generalUiTexts.fillingAndInspection
	},
	{
		id: 'office-and-storage',
		type: 'area',
		areaId: 'office-and-storage',
		title: generalUiTexts.officeAndStorage
	}
];

export {pagesData};