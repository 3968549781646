import './panels-data';
import { panelsData } from './panels-data';
import {generalUiTexts} from './ui-texts';

const areasData = [
	{
		id: 'wash-and-sterilization',
		index: 1,
		title: generalUiTexts.washAndSterilization,
		costPerOperator: 2229,
		costPerSupporter: 3343,
		overviewPanel: panelsData.overviewPanel,
		costsPanel: panelsData.costsPanel,
		productionPanel: panelsData.productionPanel,
		subAreaIds: ['personnel', 'materials', 'machines'],
		polygonPoints: '0 0 487 0 843.5 206 0 480 0 0',
		objects: [
			{id: 'background-1', x: '0', y: '0'},
			{id: 'interface-1', x: '454', y: '168'},
			{id: 'interface-3', x: '439', y: '243'},
			{id: 'interface-4', x: '1205', y: '306'},
			{id: 'machine-3', x: '1358', y: '551'},
			{id: 'machine-2', x: '70', y: '405'},
			{id: 'machine-1', x: '759', y: '202'},
			{id: 'washing-machine-1', x: '678', y: '354'},
			{id: 'staff-1-a', x: '842', y: '447'}, // support #1 (machine 2)
			{id: 'staff-2-b', x: '1183', y: '405', conditionType: 'parameter', parameterId: 'support-teams', minVal: 3}, // support #3
			{id: 'staff-3-b', x: '570', y: '420', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 5}, // operator #5
			{id: 'washing-machine-2', x: '1000', y: '418', conditionType: 'machine-level', level: 1},
			{id: 'washing-machine-2-b', x: '1000', y: '416', conditionType: 'machine-level', level: 2},
			{id: 'staff-1-d', x: '721', y: '449', conditionType: 'parameter', parameterId: 'support-teams', minVal: 2}, // support #2
			{id: 'staff-1-b', x: '629', y: '464', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 3}, // operator #3
			{id: 'staff-4-b', x: '352', y: '364'}, // operator #1 (floor left)
			{id: 'staff-4-e', x: '448', y: '366'}, // operator #2 (floor right)
			{id: 'box-open', x: '834', y: '558'},
			{id: 'console-2', x: '944', y: '557'},
			{id: 'staff-3-a', x: '913', y: '557', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 4}, // operator #4
			{id: 'pallets-1', x: '410', y: '714'},
		]
	},
	{
		id: 'formulation-and-stabilization',
		index: 2,
		title: generalUiTexts.formulationAndStabilization,
		costPerOperator: 3250,
		costPerSupporter: 4550,
		overviewPanel: panelsData.overviewPanel,
		costsPanel: panelsData.costsPanel,
		productionPanel: panelsData.productionPanel,
		subAreaIds: ['personnel', 'materials', 'machines'],
		polygonPoints: '0 864 0 488.02 489.25 329.08 790.71 503.13 640.03 552.13 1012.38 767.11 714.17 864 0 864',
		objects: [
			{id: 'background-2', x: '0', y: '0'},
			{id: 'wall-1', x: '1281', y: '472'},
			{id: 'interface-1', x: '237', y: '211'},
			{id: 'interface-2', x: '859', y: '115'},
			{id: 'interface-4', x: '1118', y: '218'},
			{id: 'interface-4', x: '1245', y: '526'},
			{id: 'machine-4', x: '709', y: '200'},
			{id: 'staff-1-b', x: '553', y: '338', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 4}, // operator #4 (floor left)
			{id: 'staff-1-d', x: '645', y: '338', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 3}, // operator #3 (floor right)
			{id: 'console-1', x: '718', y: '297'},
			{id: 'staff-2-e', x: '970', y: '280', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 5}, // operator #5 (door right)
			{id: 'machine-4', x: '973', y: '534', conditionType: 'machine-level', level: 1},
			{id: 'machine-4-b', x: '972', y: '562', conditionType: 'machine-level', level: 2},
			{id: 'console-2', x: '779', y: '622'},
			{id: 'machine-1', x: '228', y: '416'},
			{id: 'machine-2', x: '163', y: '678'},
			{id: 'cabinet-1', x: '1406', y: '713'},
			{id: 'staff-1-a', x: '1285', y: '700', conditionType: 'parameter', parameterId: 'support-teams', minVal: 3}, // support #3 (stink vent)
			{id: 'staff-3-a', x: '1345', y: '744'}, // operator #2 (stink vent)
			{id: 'staff-2-a', x: '591', y: '563'}, // support #1 (table right)
			{id: 'table', x: '505', y: '653'},
			{id: 'staff-3-b', x: '411', y: '651', conditionType: 'parameter', parameterId: 'support-teams', minVal: 2}, // support #2 (table left)
			{id: 'staff-4-f',x: '423', y: '243'} // operator #1 (box guy)
		]
	},
	{
		id: 'filling-and-inspection',
		index: 3,
		title: generalUiTexts.fillingAndInspection,
		costPerOperator: 3482,
		costPerSupporter: 4318,
		overviewPanel: panelsData.overviewPanel,
		costsPanel: panelsData.costsPanel,
		productionPanel: panelsData.productionPanel,
		subAreaIds: ['personnel', 'materials', 'machines'],
		polygonPoints: '1536 0 1536 260.5 799.5 500.25 498 326 861.5 208 500 0 0 0',
		objects: [
			{id: 'background-3', x: '0', y: '0'},
			{id: 'windows', x: '1258', y: '287'},
			{id: 'machine-1', x: '697', y: '106'},
			{id: 'machine-2', x: '265', y: '223'},
			{id: 'machine-belt-1', x: '796', y: '299'},
			{id: 'staff-3-a', x: '960', y: '275', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 5}, // operator #5
			{id: 'wall-2', x: '308', y: '500'},
			{id: 'poster', x: '289', y: '669'},
			{id: 'interface-1', x: '567', y: '569'},
			{id: 'interface-3', x: '584', y: '621'},
			{id: 'cabinet-2', x: '424', y: '703'},
			{id: 'console-2', x: '846', y: '417'},
			{id: 'staff-2-b', x: '1103', y: '645', conditionType: 'parameter', parameterId: 'support-teams', minVal: 2}, // support #2 (belt right)
			{id: 'staff-1-b', x: '712', y: '375'}, // operator #2
			{id: 'staff-1-e', x: '640', y: '402', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 3}, // operator #3
			{id: 'staff-4-d', x: '786', y: '397'}, // operator #1
			{id: 'machine-belt-2', x: '923', y: '571', conditionType: 'machine-level', level: 1},
			{id: 'machine-belt-2-b', x: '923', y: '581', conditionType: 'machine-level', level: 2},
			{id: 'machine-3', x: '1350', y: '488'},
			{id: 'staff-2-f', x: '1065', y: '495', conditionType: 'parameter', parameterId: 'operator-teams', minVal: 4}, // operator #4
			{id: 'box-open-2', x: '1009', y: '573'},
			{id: 'box-open-2', x: '1218', y: '670'},
			{id: 'box-closed', x: '1183', y: '758'}, 
			{id: 'staff-3-b', x: '788', y: '619'}, // support #1 (belt left)
			{id: 'staff-3-c', x: '458', y: '749', conditionType: 'parameter', parameterId: 'support-teams', minVal: 3}, // support #3 (stink vent))
		]
	},
	{
		id: 'office-and-storage',
		index: 4,
		title: generalUiTexts.officeAndStorage,
		costPerOfficeTeam: 3342.84,
		costPerStorageTeam: 3342.84,
		overviewPanel: panelsData.overviewPanelStorage,
		costsPanel: panelsData.costsPanelStorage,
		productionPanel: panelsData.balancePanel,
		subAreaIds: ['office', 'storage', 'initiatives'],
		polygonPoints: '714.17 864 1021.21 764.21 657.72 554.34 1403.89 311.9 1404.33 304.11 1480.94 279.22 1489.35 284.13 1536 268.97 1536 864 714.17 864',
		objects: [
			{id: 'background-4', x: '0', y: '0'},
			{id: 'pallets-2', x: '1159', y: '137'},
			// {id: 'staff-1-d', x: '843', y: '238'},
			// {id: 'staff-1-c', x: '910', y: '244'},
			{id: 'interface-1', x: '578', y: '213'},
			{id: 'interface-3', x: '515', y: '240'},
			{id: 'pallet-lift', x: '1008', y: '473'},
			{id: 'staff-5-a', x: '1157', y: '508'},
			{id: 'staff-4-c', x: '769', y: '293'},
			{id: 'desks-2', x: '363', y: '663'},
			{id: 'monitor-2', x: '300', y: '653'},
			{id: 'monitor-1', x: '563', y: '551'},
			{id: 'staff-6-a', x: '610', y: '654'},
			{id: 'wall-3', x: '506', y: '583'},
			{id: 'desks-1', x: '213', y: '450'},
			{id: 'monitor-1', x: '70', y: '386'},
			{id: 'monitor-2', x: '360', y: '298'},
			{id: 'staff-6-b', x: '392', y: '394'},
			{id: 'staff-4-a', x: '469', y: '381'},
			{id: 'staff-2-c', x: '568', y: '418', conditionType: 'action-selected', actionId: 11},
			{id: 'staff-2-d', x: '649', y: '451', conditionType: 'action-selected', actionId: 11},
			{id: 'interface-3', x: '488', y: '725'},
			{id: 'clock', x: '908', y: '537'},
			{id: 'poster', x: '977', y: '596'},
		]
	}
];

export {areasData};