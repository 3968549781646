import Background from 'assets/images/overview/background.svg';
import FillingAndInspection1 from 'assets/images/overview/filling-and-inspection-1.svg';
import FillingAndInspection2 from 'assets/images/overview/filling-and-inspection-2.svg';
import FillingAndInspection3 from 'assets/images/overview/filling-and-inspection-3.svg';
import MachineBelt2 from 'assets/images/areas/machine-belt-2.svg';
import MachineBelt2b from 'assets/images/areas/machine-belt-2-b.svg';
import WashAndSterilization1 from 'assets/images/overview/wash-and-sterilization-1.svg';
import WashAndSterilization2 from 'assets/images/overview/wash-and-sterilization-2.svg';
import WashingMachine2 from 'assets/images/areas/washing-machine-2.svg';
import WashingMachine2b from 'assets/images/areas/washing-machine-2-b.svg';
import OfficeAndStorage1 from 'assets/images/overview/office-and-storage-1.svg';
import OfficeAndStorage2 from 'assets/images/overview/office-and-storage-2.svg';
import FormulationAndStabilization1 from 'assets/images/overview/formulation-and-stabilization-1.svg';
import FormulationAndStabilization2 from 'assets/images/overview/formulation-and-stabilization-2.svg';
import Machine4 from 'assets/images/areas/machine-4.svg';
import Machine4b from 'assets/images/areas/machine-4-b.svg';
import Staff1a from 'assets/images/staff/staff-1-a.svg';
import Staff1b from 'assets/images/staff/staff-1-b.svg';
// import Staff1c from 'assets/images/staff/staff-1-c.svg';
import Staff1d from 'assets/images/staff/staff-1-d.svg';
import Staff1e from 'assets/images/staff/staff-1-e.svg';
// import Staff2a from 'assets/images/staff/staff-2-a.svg';
import Staff2b from 'assets/images/staff/staff-2-b.svg';
import Staff2c from 'assets/images/staff/staff-2-c.svg';
import Staff2d from 'assets/images/staff/staff-2-d.svg';
import Staff2e from 'assets/images/staff/staff-2-e.svg';
import Staff2f from 'assets/images/staff/staff-2-f.svg';
import Staff3a from 'assets/images/staff/staff-3-a.svg';
import Staff3b from 'assets/images/staff/staff-3-b.svg';
import Staff3c from 'assets/images/staff/staff-3-c.svg';
import Staff4a from 'assets/images/staff/staff-4-a.svg';
import Staff4b from 'assets/images/staff/staff-4-b.svg';
import Staff4c from 'assets/images/staff/staff-4-c.svg';
import Staff4d from 'assets/images/staff/staff-4-d.svg';
import Staff4e from 'assets/images/staff/staff-4-e.svg';
import Staff4f from 'assets/images/staff/staff-4-f.svg';
import Staff5a from 'assets/images/staff/staff-5-a.svg';
import Staff6a from 'assets/images/staff/staff-6-a.svg';
import Staff6b from 'assets/images/staff/staff-6-b.svg';

const staticOverviewObjectsData = [
	{id: 'background', width: '1536', height: '864', x: '0', y: '0', offsetX: 0, offsetY: 0, href: Background, type: 'background'},

	{id: 'filling-and-inspection-1', width: '978', height: '577', x: '988', y: '135', href: FillingAndInspection1},
	{id: 'staff-2-b', width: '45', height: '110', x: '1177', y: '333', href: Staff2b}, // support #2 (belt right)
	{id: 'machine-belt-2', width: '383', height: '334', x: '1062', y: '284', href: MachineBelt2},
	{id: 'staff-1-b', width: '33', height: '119', x: '925', y: '160', href: Staff1b}, // operator #2
	{id: 'staff-4-d', width: '37', height: '112', x: '978', y: '161', href: Staff4d}, // operator #1
	{id: 'staff-3-b', width: '41', height: '111', x: '975', y: '304', href: Staff3b}, // support #1 (belt left)
	{id: 'filling-and-inspection-2', width:'285', height: '274', x: '1335', y: '231', href: FillingAndInspection2},
	
	{id: 'wash-and-sterilization-1', width: '1035', height: '562', x: '336', y: '254', href: WashAndSterilization1},
	{id: 'staff-1-d', width: '36', height: '112', x: '358', y: '255', href: Staff1d}, // support #2
	{id: 'staff-4-b', width: '50', height: '113', x: '130', y: '199', href: Staff4b}, // operator #1 (floor left)
	{id: 'staff-4-e', width: '37', height: '112', x: '189', y: '200', href: Staff4e}, // operator #2 (floor right)
	{id: 'staff-1-a', width: '39', height: '113', x: '434', y: '250', href: Staff1a, offsetY: 70}, // support #1 (machine 2)
	{id: 'washing-machine-2', width: '208', height: '219', x: '531', y: '232', href: WashingMachine2},
	{id: 'wash-and-sterilization-2', width: '127', height: '96', x: '455', y: '318', href: WashAndSterilization2},
	
	{id: 'office-and-storage-1', width: '1685', height: '553', x: '842', y: '479', href: OfficeAndStorage1},
	{id: 'staff-4-c', width: '52', height: '117', x: '1274', y: '561', href: Staff4c},
	{id: 'staff-5-a', width: '55', height: '121', x: '1483', y: '671', href: Staff5a},	
	{id: 'office-and-storage-2', width: '713', height: '409', x: '1037', y: '638', href: OfficeAndStorage2},
	{id: 'staff-4-a', width: '48', height: '110', x: '1043', y: '591', href: Staff4a},
	{id: 'staff-6-b', width: '68', height: '101', x: '990', y: '598', href: Staff6b},
	{id: 'staff-6-a', width: '65', height: '92', x: '1133', y: '767', href: Staff6a},

	{id: 'formulation-and-stabilization-1', width: '1075', height: '584', x: '484', y: '644', href: FormulationAndStabilization1},
	{id: 'staff-4-f', width: '59', height: '114', x: '255', y: '514', href: Staff4f}, // operator #1 (box guy)
	{id: 'staff-1-d', width: '36', height: '112', x: '400', y: '568', href: Staff1d}, // operator #3 (floor right)

	{id: 'machine-4', width: '285', height: '250', x: '606', y: '700', href: Machine4},
	{id: 'staff-3-b', width: '41', height: '111', x: '247', y: '775', href: Staff3b}, // support #2 (table left)
	{id: 'staff-1-a', width: '39', height: '113', x: '810', y: '800', href: Staff1a}, // support #3 (stink vent)
	{id: 'staff-3-a', width: '42', height: '112', x: '844', y: '833', href: Staff3a}, // operator #2 (stink vent)
	{id: 'formulation-and-stabilization-2', width: '45', height: '99', x: '483', y: '756', href: FormulationAndStabilization2},
	{id: 'filling-and-inspection-3', width: '751', height: '765', x: '1020', y: '632', href: FillingAndInspection3},
];

const overviewObjectsData = [
	{id: 'background', width: '1536', height: '864', x: '0', y: '0', offsetX: 0, offsetY: 0, href: Background, type: 'background'},

	{id: 'filling-and-inspection-1', width: '978', height: '577', x: '988', y: '135', href: FillingAndInspection1},
	{id: 'staff-2-b', width: '45', height: '110', x: '1177', y: '333', href: Staff2b, conditionType: 'parameter', parameterId: 'support-teams', minVal: 2, areaId: 'filling-and-inspection'}, // support #2 (belt right)
	{id: 'staff-3-a', width: '42', height: '112', x: '1085', y: '99', href: Staff3a, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 5, areaId: 'filling-and-inspection'}, // operator #5
	{id: 'machine-belt-2', width: '383', height: '334', x: '1062', y: '284', href: MachineBelt2, conditionType: 'machine-level', level: 1, areaId: 'filling-and-inspection'},
	{id: 'machine-belt-2-b', width: '383', height: '344', x: '1062', y: '281', href: MachineBelt2b, conditionType: 'machine-level', level: 2, areaId: 'filling-and-inspection'},
	{id: 'staff-2-f', width: '39', height: '113', x: '1160', y: '250', href: Staff2f, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 4, areaId: 'filling-and-inspection'}, // operator #4
	{id: 'staff-1-e', width: '36', height: '112', x: '880', y: '180', href: Staff1e, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 3, areaId: 'filling-and-inspection'}, // operator #3
	{id: 'staff-1-b', width: '33', height: '119', x: '925', y: '160', href: Staff1b}, // operator #2
	{id: 'staff-4-d', width: '37', height: '112', x: '978', y: '161', href: Staff4d}, // operator #1
	{id: 'staff-3-b', width: '41', height: '111', x: '975', y: '304', href: Staff3b}, // support #1 (belt left)
	{id: 'filling-and-inspection-2', width:'285', height: '274', x: '1335', y: '231', href: FillingAndInspection2},
	
	{id: 'wash-and-sterilization-1', width: '1035', height: '562', x: '336', y: '254', href: WashAndSterilization1},
	{id: 'staff-2-b', width: '45', height: '110', x: '645', y: '228', href: Staff2b, conditionType: 'parameter', parameterId: 'support-teams', minVal: 3, areaId: 'wash-and-sterilization'}, // support #3
	{id: 'staff-3-b', width: '41', height: '111', x: '265', y: '234', href: Staff3b, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 5, areaId: 'wash-and-sterilization'}, // operator #5
	{id: 'staff-1-d', width: '36', height: '112', x: '358', y: '255', href: Staff1d, conditionType: 'parameter', parameterId: 'support-teams', minVal: 2, areaId: 'wash-and-sterilization'}, // support #2
	{id: 'staff-1-b', width: '33', height: '119', x: '301', y: '262', href: Staff1b, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 3, areaId: 'wash-and-sterilization'}, // operator #3
	{id: 'staff-4-b', width: '50', height: '113', x: '130', y: '199', href: Staff4b}, // operator #1 (floor left)
	{id: 'staff-4-e', width: '37', height: '112', x: '189', y: '200', href: Staff4e}, // operator #2 (floor right)
	{id: 'staff-1-a', width: '39', height: '113', x: '434', y: '250', href: Staff1a, offsetY: 70}, // support #1 (machine 2)
	{id: 'washing-machine-2', width: '208', height: '219', x: '531', y: '232', href: WashingMachine2, conditionType: 'machine-level', level: 1, areaId: 'wash-and-sterilization'},
	{id: 'washing-machine-2-b', width: '208', height: '225', x: '531', y: '232', href: WashingMachine2b, conditionType: 'machine-level', level: 2, areaId: 'wash-and-sterilization'},
	{id: 'wash-and-sterilization-2', width: '127', height: '96', x: '455', y: '318', href: WashAndSterilization2},
	{id: 'staff-3-a', width: '42', height: '112', x: '475', y: '325', href: Staff3a, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 4, areaId: 'wash-and-sterilization'}, // operator #4
	
	{id: 'office-and-storage-1', width: '1685', height: '553', x: '842', y: '479', href: OfficeAndStorage1},
	{id: 'staff-4-c', width: '52', height: '117', x: '1274', y: '561', href: Staff4c},
	{id: 'staff-5-a', width: '55', height: '121', x: '1483', y: '671', href: Staff5a},	
	{id: 'office-and-storage-2', width: '713', height: '409', x: '1037', y: '638', href: OfficeAndStorage2},
	{id: 'staff-4-a', width: '48', height: '110', x: '1043', y: '591', href: Staff4a},
	{id: 'staff-6-b', width: '68', height: '101', x: '990', y: '598', href: Staff6b},
	{id: 'staff-6-a', width: '65', height: '92', x: '1133', y: '767', href: Staff6a},
	{id: 'staff-2-c', width: '36', height: '112', x: '1105', y: '615', href: Staff2c, conditionType: 'action-selected', actionId: 11}, // analyst
	{id: 'staff-2-d', width: '40', height: '119', x: '1150', y: '635', href: Staff2d, conditionType: 'action-selected', actionId: 11}, // analyst

	{id: 'formulation-and-stabilization-1', width: '1075', height: '584', x: '484', y: '644', href: FormulationAndStabilization1},
	{id: 'staff-4-f', width: '59', height: '114', x: '255', y: '514', href: Staff4f}, // operator #1 (box guy)
	{id: 'staff-1-b', width: '33', height: '119', x: '343', y: '568', href: Staff1b, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 4, areaId: 'formulation-and-stabilization'}, // operator #4 (floor left)
	{id: 'staff-1-d', width: '36', height: '112', x: '400', y: '568', href: Staff1d, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 3, areaId: 'formulation-and-stabilization'}, // operator #3 (floor right)
	{id: 'staff-2-e',  width: '33', height: '119', x: '605', y: '540', href: Staff2e, conditionType: 'parameter', parameterId: 'operator-teams', minVal: 5, areaId: 'formulation-and-stabilization'}, // operator #5 (door right)
	{id: 'machine-4', width: '285', height: '250', x: '606', y: '700', href: Machine4, conditionType: 'machine-level', level: 1, areaId: 'formulation-and-stabilization'},
	{id: 'machine-4-b', width: '285', height: '286', x: '606', y: '720', href: Machine4b, conditionType: 'machine-level', level: 2, areaId: 'formulation-and-stabilization'},
	{id: 'staff-3-b', width: '41', height: '111', x: '247', y: '775', href: Staff3b, conditionType: 'parameter', parameterId: 'support-teams', minVal: 2, areaId: 'formulation-and-stabilization'}, // support #2 (table left)
	{id: 'staff-1-a', width: '39', height: '113', x: '810', y: '800', href: Staff1a, conditionType: 'parameter', parameterId: 'support-teams', minVal: 3, areaId: 'formulation-and-stabilization'}, // support #3 (stink vent)
	{id: 'staff-3-a', width: '42', height: '112', x: '844', y: '833', href: Staff3a}, // operator #2 (stink vent)
	{id: 'formulation-and-stabilization-2', width: '45', height: '99', x: '483', y: '756', href: FormulationAndStabilization2},
	{id: 'filling-and-inspection-3', width: '751', height: '765', x: '1020', y: '632', href: FillingAndInspection3},
	{id: 'staff-3-c', width: '41', height: '111', x: '765', y: '390', href: Staff3c, conditionType: 'parameter', parameterId: 'support-teams', minVal: 3, areaId: 'filling-and-inspection'}, // support #3 (stink vent))
];

export {
	staticOverviewObjectsData,
	overviewObjectsData
};