import {teamsData} from './teams-data';

const tooltipsData = {
	'material-costs': {
		text: {
			da: 'De samlede omkostninger til råvarer i dette afsnit.',
			en: 'Total cost of raw materials in this section.'
		}
	},
	'operator-costs': {
		text: {
			da: 'De samlede omkostninger til Operatør-skiftehold.',
			en: 'Total cost of operator shift work.'
		}
	},
	'support-costs': {
		text: {
			da: 'De samlede omkostninger til Produktionssupport-skiftehold.',
			en: 'Total cost of production support shift work.'
		}
	},
	'running-expenses': {
		text: {
			da: 'De samlede omkostninger til andre løbende udgifter.',
			en: 'Total cost of other running costs.'
		}
	},
	'running-expenses2': {
		text: {
			da: 'De samlede omkostninger til løbende udgifter.',
			en: 'Total cost of running costs.'
		}
	},
	'machine-write-offs': {
		type: 'machine-write-offs'
	},
	'equipment-capacity': {
		text: {
			da: 'Den mængde units maskinen i dette afsnit kan producere ved 100% udnyttelse.',
			en: 'The amount of units the machines in this section can produce at 100% utilisation.'
		}
	},
	'operator-teams': {
		text: {
			da: 'Afgør hvor meget af maskinens kapacitet der kan udnyttes.',
			en: 'Determines how much of the machine\'s capacity that is utilised. '
		},
		workEnvModifierText: {
			da: 'I øjeblikket påvirkes kapacitetsudnyttelsen af arbejdsmiljøet.',
			en: 'The capacity utilisation is currently being affected by the work environment.'
		},
		table: {
			header: [
				{
					value: {
						da: 'Antal skiftehold',
						en: 'Number of teams'
					}
				}, 
				{
					value: {
						da: 'Kapacitetsudnyttelse',
						en: 'Capacity utilisation'
					}
				}
			],
			rows: teamsData['operator-teams'].efficiency.map((level, index) => {
				if (level > 0) {
					return [{value: index}, {value: Math.round(level * 100) + '%'}]
				}
				return null;
			})
		}
	},
	'production-teams': {
		text: {
			da: 'Afgør hvor meget af Operatørkapaciteten der kan udnyttes.',
			en: 'Determines how much of the operator capacity that is utilised.'
		},
		oldMachineText: {
			da: 'Bedre maskiner og opgraderinger vil kræve mindre vedligeholdelse og vil ændre på disse tal.',
			en: 'Better machines and upgrades will require less maintenance and will change these numbers.'
		},
		oldMachineText2: {
			da: 'Maskinen er gammel og har nedsat effektivitet.',
			en: 'The machine is old and has a reduced efficiency.'
		},
		newMachineText: {
			da: 'Den nye maskine påvirker udnyttelsesgraden.',
			en: 'The new machine impacts capacity'
		},
		repairText: {
			da: 'Jeres opgradering påvirker udnyttelsesgraden.',
			en: 'Your upgrades impact the capacity.'
		},
		table: {
			header: [
				{
					value: {
						da: 'Antal skiftehold',
						en: 'Number of teams'
					}
				}, 
				{
					value: {
						da: 'Kapacitetsudnyttelse',
						en: 'Capacity utilisation'
					}
				}
			],
			rows: teamsData['support-teams']['wash-and-sterilization']['level-1'].efficiency.map((level, index) => {
				if (level > 0) {
					return [{value: index}, {value: '%support-team-efficiency%'}]
				}
				return null;
			}),
		}
	},
	'effective-capacity': {
		text: {
			da: 'Hvor mange units kan der produceres i slutningen af turen hvis der ikke tages handlinger der påvirker dette.',
			en: 'The number of units that can be produced at the end of the turn if no actions are performed to impact this.'
		}
	},
	'prev-area-throughput': {
		text: {
			da: 'Det antal enheder som afsnittet modtager fra forrige afsnit uden hensyn til spild.',
			en: 'The number of units the section is receiving from the previous section without considering in-process scrap.'
		}
	},
	'waste': {
		text: {
			da: 'Hvor mange procent af de producerede units går til spilde.',
			en: 'Percentage of the produced units that are scrapped.'
		}
	},
	'throughput-units': {
		text: {
			da: 'Hvor mange units kan der produceres og sendes videre til næste afsnit. Tallet tager hensyn til flaskehalse.',
			en: 'How many units that can be produced and sent to the next section. The number takes any bottlenecks into consideration.'
		}
	},

	'office-staff': {
		text: {
			da: 'De samlede omkostninger til Kontorpersonel.',
			en: 'Total costs for office personnel.'
		}
	},
	'storage-staff': {
		text: {
			da: 'De samlede omkostninger til Lagerpersonel.',
			en: 'Total costs for warehouse personnel.'
		}
	},
	'inventory-primo': {
		text: {
			da: 'Jeres lagerbeholdning i starten af turen.',
			en: 'Your inventory at the start of the turn.'
		}
	},
	'waste-percent': {
		text: {
			da: 'Så meget af jeres lager går til spilde mellem hver tur.',
			en: 'This much of your inventory will be scrapped every turn.'
		}
	},
	'production': {
		text: {
			da: 'Jeres produktion hvis I ikke foretager nogen handlinger i denne tur.',
			en: 'Your production if you don\'t perform any actions this round.'
		}
	},
	'expected-demand': {
		text: {
			da: 'I forventer at skulle levere et antal units inden for dette spænd.',
			en: 'You expect to have to deliver an amount of units within this span.'
		}
	},
	'expected-inventory-ultimo': {
		text: {
			da: 'Lagerbeholdning i slutningen af turen hvis I ikke tager nogen handlinger.',
			en: 'Inventory at the end of the turn if you don\'t perform any actions.'
		}
	}
};

export {tooltipsData};