import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';

const LoginGroup = (props) => {
	const {
		isLoading,
		showNewGroupPopup,
		languageId,
		code,
		feedback,
		groupId,
		groupName,
		groups,
		handleInput,
		handleFindGame,
		handleCreateGroup,
		handleGetLoginToken,
		toggleCreateNewGroupPopup,
	} = props;

	/* Input game code */
	if (groups === null) {
		return (
			<div className="LoginGroup">
				<form className="Login-form" onSubmit={handleFindGame}>
					<input
						name="code"
						type="text"
						placeholder={getText(loginUiTexts.gameCode, languageId)} 
						autoComplete="section-grouplogin code"
						value={code ? code : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<input 
						className={'Button Button--login' + (isLoading ? ' Button--isLoading' : '')}
						type="submit"
						value={getText(loginUiTexts.login, languageId)} 
					/>
					<div className="Login-errorMessage">{feedback}</div>
				</form>
			</div>
		);
	}
	
	/* Select group */
	if (!showNewGroupPopup) {
		return (
			<div className="LoginChooseGroup">
				<div className="Login-groups">
					<div className="Login-newGroupBtn" onClick={() => {toggleCreateNewGroupPopup();}}>
						<span>+ {getText(loginUiTexts.newPlayer, languageId)}</span>
					</div>
					{groups.map((group, index)=>{
						let groupIsLoading = (isLoading && group.id === groupId);
						return (
							<div 
								key={index} 
								className={'Login-groupSelectBtn' + (groupIsLoading ? ' loading' : '')}
								onClick={() => {handleGetLoginToken(group.id);}}
							><span>{group.title}</span></div>
						);
					})}
				</div>
			</div>
		);
	}

	/* Create new group */
	return (
		<div className="LoginChooseGroup">
			<form className="Login-form" onSubmit={(e) => {handleCreateGroup(e, groupName);}}>
				<input
					name="groupName"
					type="text"
					placeholder={getText(loginUiTexts.groupName, languageId)} 
					autoComplete="section-grouplogin name"
					value={groupName ? groupName : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<div className="Login-buttons">
					<input 
						className={'Button Button--login' + (isLoading ? ' Button--isLoading' : '')}
						type="submit"
						value={getText(loginUiTexts.create, languageId)} 
					/>
					<Button 
						classes={['login']} 
						text={getText(loginUiTexts.cancel2, languageId)} 
						onClick={() => {toggleCreateNewGroupPopup();}}
					/>
				</div>
				<div className="Login-errorMessage">{feedback}</div>
			</form>
		</div>
	);
	
};

LoginGroup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	showNewGroupPopup: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	code: PropTypes.any,
	feedback: PropTypes.string,
	groupId: PropTypes.string,
	groupName: PropTypes.string,
	groups: PropTypes.any,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	handleCreateGroup: PropTypes.func.isRequired,
	handleGetLoginToken: PropTypes.func.isRequired,
	toggleCreateNewGroupPopup: PropTypes.func.isRequired
};

export default LoginGroup;