import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {pagesData} from 'data/pages-data';
import {formatNumber} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import './navigation.scss';

const Navigation = ({languageId, pageId, hoverAreaId, unitCost, handleHoverArea, handleGoToPage}) => {
	// Identify selected page and compute layout of "selected item" marker
	let [selectedMarkerStyle, setSelectedMarkerStyle] = useState(null);
	const updateSelectedMarkerStyle = () => {
		let navItems = document.getElementsByClassName('Navigation-item');
		let selectedPageIndex = pagesData.findIndex((page) => {return page.id === pageId;});
		if (navItems && selectedPageIndex >= 0 && selectedPageIndex < navItems.length) {
			let selectedNavItem = navItems[selectedPageIndex];
			if (selectedNavItem) {
				setSelectedMarkerStyle({
					width: selectedNavItem.offsetWidth + 'px',
					left: selectedNavItem.offsetLeft + 'px'
				});
			}
		}
	};
	
	// Initiate marker on page load or move on navigation
	useEffect(() => {
		updateSelectedMarkerStyle();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageId]);

	return (
		<div className={'Navigation ' + pageId}>
			<div className="Navigation-title">
				<div className="Navigation-titleText">{getText(generalUiTexts.unitCost, languageId)}</div>
				<div className="Navigation-titleUC">
					<div className="Navigation-titleUCValue">
						<span>{formatNumber(languageId, unitCost, 1, false)}</span>
						{' ' + getText(generalUiTexts.costPerUnit, languageId)}
					</div>
				</div>
			</div>
			<div className="Navigation-items">
				{pagesData.map((pageData, index) => {
					let navItemClass = 'Navigation-item' + 
						(pageData.id === pageId ? ' selected' : '') + 
						(pageData.areaId === hoverAreaId ? ' hover' : '');
					let triggerHoverEffect = (
						pageId === 'overview' && 
						pageData.type === 'area' && 
						hoverAreaId !== pageData.areaId
					);
					return (
						<div 
							key={index} 
							className={navItemClass}
							onClick={() => {handleGoToPage(pageData.id);}}
							onMouseOver={() => {if (triggerHoverEffect) {handleHoverArea(pageData.areaId);}}}
							onMouseOut={() => {if (hoverAreaId) handleHoverArea(null);}}	
						>
							<span>{getText(pageData.title, languageId)}</span>
						</div>
					);
				})}
				{selectedMarkerStyle && <div style={selectedMarkerStyle} className="Navigation-selectedMarker" />}
			</div>
		</div>
	);
};

Navigation.propTypes = {
	languageId: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	hoverAreaId: PropTypes.string,
	unitCost: PropTypes.number.isRequired,
	handleHoverArea: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default Navigation;
