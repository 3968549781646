import {useEffect} from 'react';
import firebaseInit from 'firebase-init';
import LoginController from 'components/login/login-controller';
import ImageLoader from 'components/image-loader/image-loader';
import './app.scss';

function App() {
	useEffect(() => {
		/* Initialize firebase */
		firebaseInit(); // .then(() => {console.log('Firestore initialized');});

	});
	
	return (
		<div className="App">
			<LoginController />
			<ImageLoader />
		</div>
	);
}

export default App;
