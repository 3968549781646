const popupsData = {
	/* Pause overlay */
	pause: {
		text: {
			da: 'Venter på facilitator ...',
			en: 'Waiting for facilitator ...'
		} 
	},

	/* Intro round 1-4 */
	intro: {
		title: {
			da: 'Introduktion',
			en: 'Introduction'
		},
		text: {
			da: require('data/intros/intro_da.md').default,
			en: require('data/intros/intro_en.md').default,
		}
	},

	/* Intro round 5-8 */
	intro2: {
		title: {
			da: 'Status',
			en: 'Status'
		},
		text: {
			da: require('data/intros/intro2_da.md').default,
			en: require('data/intros/intro2_en.md').default
		}
	},

	/* Info (game mechanic) */
	info: {
		title: {
			da: 'Spillets mekanik',
			en: 'Gameplay'
		},
		text: {
			da: require('data/infos/info_da.md').default,
			en: require('data/infos/info_en.md').default
		}
	},

	/* Report */
	report: {
		title: {
			da: 'Rapport',
			en: 'Report'
		},
		section1: {
			title: {
				da: 'Intern rapportering',
				en: 'Internal reporting'
			}
		},
		section2: {
			title: {
				da: 'Forventninger',
				en: 'Expectations'
			},
			title2: {
				da: 'Afslutning',
				en: 'Ending'
			}
		},
		rounds: {
			da: 'Ture',
			en: 'Turns'
		},
		labels: [
			{
				id: 'unit-cost',
				title: {
					da: 'Unit Cost', 
					en: 'Unit Cost'
				},
				title2: {
					da: 'Unit Cost',
					en: 'Unit Cost'
				},
			},	
			{
				id: 'unit-cost2', 
				title2: {
					da: 'Unit Cost før indkøb',
					en: 'Unit Cost before purchase'
				}
			},	
			{
				id: 'costs', 
				title: {
					da: 'Omkostninger', 
					en: 'Costs'
				},
				title2: {
					da: 'Omkostninger',
					en: 'Costs'
				}
			},
			{
				id: 'production', 
				title: {
					da: 'Produktion', 
					en: 'Production'
				},
				title2: {
					da: 'Produktion', 
					en: 'Production'
				}
			},
			{
				id: 'demand', 
				title: {
					da: 'Forventet/faktisk demand', 
					en: 'Expected/actual demand'
				},
				title2: {
					da: 'Demand',
					en: 'Demand'
				}
			},
			{
				id: 'demand2', 
				title2: {
					da: 'Forventet demand',
					en: 'Expected demand'
				}
			}
		]
	}
};

export {popupsData};