const tendencyData = [
	// 1
	{
		'scenario-1': 0,
		'scenario-2': 0,
		'scenario-3': 0
	},
	// 2
	{
		'scenario-1': 0,
		'scenario-2': 0,
		'scenario-3': 0,
	},
	// 3
	{
		'scenario-1': 0,
		'scenario-2': 0,
		'scenario-3': 0,
	},
	// 4
	{
		'scenario-1': 60000,
		'scenario-2': 60000,
		'scenario-3': 60000,
	},
	// 5
	{
		'scenario-1': 65000,
		'scenario-2': 65000,
		'scenario-3': 65000,
	},
	// 6
	{
		'scenario-1': 97000,
		'scenario-2': 79000,
		'scenario-3': 78000,
	},
	// 7
	{
		'scenario-1': 117000,
		'scenario-2': 90000,
		'scenario-3': 73000,
	},
	// 8
	{
		'scenario-1': 127000,
		'scenario-2': 90000,
		'scenario-3': 56000,
	},
	// 9
	{
		'scenario-1': 135000,
		'scenario-2': 95000,
		'scenario-3': 49000,
	},
	// 10
	{
		'scenario-1': 145000,
		'scenario-2': 97000,
		'scenario-3': 47000,
	},
	// 11
	{
		'scenario-1': 155000,
		'scenario-2': 100000,
		'scenario-3': 50000,
	},
];

export {tendencyData};