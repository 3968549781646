import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';

const LoginFacilitator = (props) => {
	let {
		handleInput,
		handleLogin,
		languageId,
		setFacilitatorBoxType,
		email,
		password,
		feedback,
		isLoading
	} = props;

	return (
		<div className="LoginFacilitator">
			<form className="Login-form" onSubmit={handleLogin}>
				<input
					name="email"
					type="text"
					placeholder={getText(loginUiTexts.email, languageId)} 
					autoComplete="section-facilitatorlogin email"
					value={email ? email : ''} 
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="password"
					type="password"
					placeholder={getText(loginUiTexts.password, languageId)}
					autoComplete="section-facilitatorlogin password"
					value={password ? password : ''} 
					onChange={(event)=>{handleInput(event);}}
				/>
				<input 
					className={'Button Button--login' + (isLoading ? ' Button--isLoading' : '')}
					type="submit"
					value={getText(loginUiTexts.login, languageId)} 
				/>
				<p className="Login-errorMessage">{feedback}</p> 
			</form>
			<div 
				className="Login-resetPasswordBtn"
				onClick={()=>{setFacilitatorBoxType('resetPassword');}}
			>{getText(loginUiTexts.forgotPassword, languageId)}</div>
			<div 
				className="Login-createUserBtn"
				onClick={()=>{setFacilitatorBoxType('createUser');}}
			>{getText(loginUiTexts.createNewUser, languageId)}</div>
		</div>
	);
};

LoginFacilitator.propTypes = {
	handleLogin: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
	languageId: PropTypes.string.isRequired,
	handleInput: PropTypes.func.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	isLoading: PropTypes.bool.isRequired
};

export default LoginFacilitator;