import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAyhtFMB59o8gxntSa7aafuloYxQaHMzPU',
		authDomain: 'cgl-unitcost-test.firebaseapp.com',
		projectId: 'cgl-unitcost-test',
		storageBucket: 'cgl-unitcost-test.appspot.com',
		messagingSenderId: '175765987309',
		appId: '1:175765987309:web:2db8d920a137caf24e51e4'
	};
}


/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyB4b666KEaQvmDuMSLKGC9iVs2n2HMW6a8',
		authDomain: 'cgl-unitcost-production.firebaseapp.com',
		projectId: 'cgl-unitcost-production',
		storageBucket: 'cgl-unitcost-production.appspot.com',
		messagingSenderId: '782141161713',
		appId: '1:782141161713:web:c32eb8c39b15976581a950',
		measurementId: 'G-Z0SK0J3JPB'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalForceLongPolling: true });

/* Initialize analytics */
if (env === 'production') {
	firebase.analytics();
}

export default firebase.firestore;