import React from 'react';
import PropTypes from 'prop-types';
import {popupsData} from 'data/popups-game-data';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import {formatNumber} from 'helpers/game-helper';
import './intro-popup.scss';

const IntroPopup = ({isLoading, groupIsPlaying, showIntroPopup, languageId, group, handleToggleIntroPopup}) => {
	let hideContent = (isLoading || groupIsPlaying);
	let introPopupClass = 'IntroPopup' + (showIntroPopup ? ' show' : '');
	let popupData = JSON.parse(JSON.stringify(popupsData.intro));

	if (group && group.roundId >= 5) {
		hideContent = false;
		popupData = JSON.parse(JSON.stringify(popupsData.intro2));
		let round4 = group.rounds.find((round) => {return round.id === 4;});
		let gameScore = (round4 && round4.results && round4.results.gameScore 
			? formatNumber(languageId, round4.results.gameScore, 2, false)
			: '?' 
		);
		popupData.text = getText(popupData.text, languageId).replace(/%gameScore%/g, gameScore);
		introPopupClass += ' wide';
	} else {
		popupData.text = getText(popupData.text, languageId);
	}

	return (
		<div className={introPopupClass + (hideContent ? ' loading' : '')}
			onClick={() => {handleToggleIntroPopup();}} >
			<div className="IntroPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="IntroPopup-header">
					<div onClick={() => {handleToggleIntroPopup();}} className="IntroPopup-closeBtn" />
					<div className="IntroPopup-title">{getText(popupData.title, languageId)}</div>
				</div>
				<div className="IntroPopup-body">
					<div id="IntroPopupText" className="IntroPopup-text">
						{renderMarkdown(popupData.text)}
					</div>
				</div>
			</div>
		</div>
	);
};

IntroPopup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	groupIsPlaying: PropTypes.bool.isRequired,
	showIntroPopup: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	group: PropTypes.object,
	handleToggleIntroPopup: PropTypes.func.isRequired,
};

export default IntroPopup;
