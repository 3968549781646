import React from 'react';
import PropTypes from 'prop-types';
import {subAreasData} from 'data/sub-areas-data';
import {getAvailableActions} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import Action from 'components/action/action';
import './available-actions.scss';

const AvailableActions = (props) => {
	let {
		languageId,
		areaId, 
		subAreaId, 
		hoverActionId, 
		areaStats, 
		group, 
		openActionContainers,
		openActions,
		handleToggleActionContainer,
		handleOpenCloseAction,
		handleToggleAction, 
		handleHoverAction
	} = props;

	// Open / close action
	const toggleAction = (actionId) => {
		handleOpenCloseAction(areaId + '-' + subAreaId + '-' + actionId);
	};

	// Available actions & subarea data
	let availableActions = getAvailableActions(areaId, subAreaId, group);
	let subAreaData = (subAreasData.hasOwnProperty(subAreaId) ? subAreasData[subAreaId] : null);
	let canAnimate = !(availableActions.some((action) => {
		return openActions.indexOf(areaId + '-' + subAreaId + '-' + action.id) >= 0;
	}));

	// Check if container is open
	let actionContainerIsOpen = openActionContainers.indexOf(areaId + '-' + subAreaId) >= 0;

	// Render
	if (!subAreaData) {
		console.error('Unknown subarea: ', subAreaId);
		return null;
	}

	return (
		<div className={'AvailableActions' + (actionContainerIsOpen ? ' open ' : ' ') + areaId + ' ' + subAreaId}>
			<div className="AvailableActions-bgColor" />
			<div 
				className={'AvailableActions-header'} 
				onClick={() => {handleToggleActionContainer(areaId + '-' + subAreaId);}}
			>
				<div className={'AvailableActions-toggleBtn'} />					
				<div className="AvailableActions-title">{getText(subAreaData.title, languageId)}</div>
			</div>
			<div className={'AvailableActions-body' + (canAnimate ? ' animate-' + availableActions.length : '')}>
				{availableActions.map((action) => {
					let isOpen = (openActions.indexOf(areaId + '-' + subAreaId + '-' + action.id) >= 0);
					return (
						<Action 
							key={action.id} 
							isOpen={isOpen}
							languageId={languageId}
							type="available"
							areaId={areaId}
							hoverActionId={hoverActionId}
							actionData={action}
							areaStats={areaStats}
							group={group}
							toggleAction={toggleAction}
							handleToggleAction={handleToggleAction}
							handleHoverAction={handleHoverAction}
						/>
					);
				})}
			</div>
		</div>
	);
};

AvailableActions.propTypes = {
	languageId: PropTypes.string.isRequired,
	areaId: PropTypes.string.isRequired,
	subAreaId: PropTypes.string.isRequired,
	hoverActionId: PropTypes.number,
	areaStats: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	openActionContainers: PropTypes.array.isRequired,
	openActions: PropTypes.array.isRequired,
	handleToggleActionContainer: PropTypes.func.isRequired,
	handleOpenCloseAction: PropTypes.func.isRequired,
	handleToggleAction: PropTypes.func.isRequired,
	handleHoverAction: PropTypes.func.isRequired,
};

export default AvailableActions;
