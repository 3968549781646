
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {areasData} from 'data/areas-data';
import {parametersData} from 'data/parameters-data';
import {overviewObjectsData} from 'data/overview-objects-data';
import {getImageSize} from 'helpers/image-helper';
import {formatNumber} from 'helpers/game-helper';
import {getStatusOfObject} from 'helpers/area-objects-helper';
import {getText} from 'helpers/language-helper';
import RoundTrack from 'components/round-track/round-track';
import Button from 'components/button/button';
import './overview.scss';

const Overview = ({
	isGameOver,
	animateInfoIcon,
	popupIsOpen,
	hoverAreaId,
	languageId,
	group,
	roundStats,
	handleHoverArea,
	handleGoToPage,
	handleToggleReportPopup,
	handleToggleInfoPopup,
	animateRoundIdProp
}) => {
	// Get window size -> size / position of background image
	let [bgImageWidth, setBgImageWidth] = useState(0);
	let [bgImageHeight, setBgImageHeight] = useState(0);
	let [bgImageTranslation, setBgImageTranslation] = useState(0);
	let [animateRoundId, setAnimateRoundId] = useState(false);
	
	// Set size of overlay images to match background image 
	const setImageSize = () => {
		let container = document.getElementById('overview');
		if (container) {
			let containerWidth = container.offsetWidth;
			let containerHeight = container.offsetHeight;		
			let imageRatio = 1536. / 864.;
			let [w, h] = getImageSize(containerWidth, containerHeight, imageRatio);
			setBgImageWidth(w);
			setBgImageHeight(h);
			if (w > containerWidth) {
				setBgImageTranslation((w - containerWidth) / 2.);
			} else {
				setBgImageTranslation(0);
			}
		}
	};

	// Set image size after render or when window is resized
	useEffect(() => {
		setImageSize();
		window.addEventListener('resize', setImageSize);
	});

	/* Get background style */
	const backgroundStyle = { 
		width: bgImageWidth + 'px',
		height: bgImageHeight + 'px',
		transform: `translate(${-bgImageTranslation}px, 0px)` 
	};

	// Hook for checking previous props
	function usePrevious(value) {
		const ref = useRef();
		
		useEffect(() => {
			ref.current = value;
		}, [value]);
		
		return ref.current;
	}

	// Check if roundId changed
	const prevAnimateRoundIdProp = usePrevious(animateRoundIdProp);
	useEffect(() => {
		if (prevAnimateRoundIdProp === false && animateRoundIdProp === true && !isGameOver) {
			setAnimateRoundId(true);
		}
	}, [prevAnimateRoundIdProp, animateRoundIdProp, isGameOver]);

	// Check if info popup btn should blink
	let animateInfoPopupBtn = (!popupIsOpen && animateInfoIcon && group.roundId < 2);

	return (
		<div id="overview" className={'Overview' + (hoverAreaId ? ' ' + hoverAreaId : '')}>
			{/* Objects */}
			<div className="Overview-objects">
				<svg style={backgroundStyle} viewBox='0 0 1536 864' preserveAspectRatio="xMidYMax" version='1.1' xmlns='http://www.w3.org/2000/svg'>
					{overviewObjectsData.map((object, index) => {
						/* Status: visible / hidden */
						let groupRound = group.rounds.find((r) => {return r.id === group.roundId;});
						let status = getStatusOfObject(
							object.areaId, 
							roundStats[object.areaId], 
							groupRound,
							object, 
							group
						);
						/* Object translation */
						let offsetX = (object.width / 2.);
						let offsetY = (object.height / 2.);
						if (object.hasOwnProperty('offsetX')) offsetX = object.offsetX;
						if (object.hasOwnProperty('offsetY')) offsetY = object.offsetY;

						return (
							<g 
								key={index} 
								width={object.width}
								height={object.height}
								transform={'translate(' + (object.x - offsetX) + ', ' + (object.y - offsetY) + ')'}
							>
								<image 
									className={'Overview-object ' + status}
									x={(status === 'visible' ? 0 : (object.width / 2.))}
									y={(status === 'visible' ? 0 : object.height)}
									width={(status === 'visible' ? object.width : 0)}
									height={(status === 'visible' ? object.height : 0)}
									href={object.href}
								/>
							</g>
						);
					})}
				</svg>
			</div>

			{/* Overlay */}
			<div className={'Overview-overlay'} />

			{/* Area info panels */}
			{areasData.map((area, index) => {
				return (
					<div key={index} className={'Overview-areaInfo ' + area.id}>
						<div className="Overview-panel">
							{area.overviewPanel.map((row, index) => {
								if (row.hideIfGameIsOver === true && isGameOver) return null;
								if (row.areaId && row.areaId !== area.id) return null;
								let paramData = (parametersData.hasOwnProperty(row.parameterId) 
									? parametersData[row.parameterId] : null);
								let title = (row.title 
									? getText(row.title, languageId)
									: (paramData ? getText(paramData.title, languageId) : '?')
								);
								let value = '???';
								if (roundStats[area.id].hasOwnProperty(row.parameterId)) {
									value = formatNumber(
										languageId,
										roundStats[area.id][row.parameterId], 
										(paramData ? paramData.decimals : 0),
										(row.isRange === true)
									);
								}								
								let unit = (paramData
									? (paramData.unitSpace ? ' ' : '') + getText(paramData.unit, languageId)
									: (row.unit ? ' ' + getText(row.unit, languageId) : ' ?')
								);
								return (
									<div 
										key={index} 
										className={'Overview-panelRow' + (row.class ? ' ' + row.class : '')}
									><span>{title}: </span><span>{value}{unit}</span></div>
								);
							})}
						</div>
						<div className={'Overview-areaTitle' + (hoverAreaId === area.id ? ' hover' : '')}>
							<span>{getText(area.title, languageId)}</span>
						</div>
					</div>
				);
			})}

			{/* Hover areas */}
			<div className="Overview-hoverAreas">
				<svg viewBox="0 0 1536 864" style={backgroundStyle}>
					{areasData.map((area, index) => {
						return (
							<polygon 
								key={index} 
								points={area.polygonPoints}
								onMouseOver={() => {if (hoverAreaId !== area.id) handleHoverArea(area.id);}}
								onMouseOut={() => {if (hoverAreaId) handleHoverArea(null);}}
								onClick={() => {handleGoToPage(area.id);}}
							/>
						);
					})}
				</svg>
			</div>

			{/* Rounds overview */}
			<RoundTrack page='overview' group={group} animateRoundId={animateRoundId}/>

			{/* Popup buttons */}
			<Button classes={['icon', 'report']} text="" onClick={() => {handleToggleReportPopup();}} />
			<Button 
				classes={['icon', 'info', (animateInfoPopupBtn ? 'blink' : '')]} 
				text="" 
				onClick={() => {handleToggleInfoPopup();}}
			/>
		</div>
	);
};

Overview.propTypes = {
	isGameOver: PropTypes.bool.isRequired,
	animateInfoIcon: PropTypes.bool.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	hoverAreaId: PropTypes.string,
	group: PropTypes.object.isRequired,
	roundStats: PropTypes.object.isRequired,
	handleHoverArea: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleToggleReportPopup: PropTypes.func.isRequired,
	handleToggleInfoPopup: PropTypes.func.isRequired,
	animateRoundIdProp: PropTypes.bool.isRequired
};

export default Overview;
