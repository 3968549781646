let imagesData = [
	'icon-arrow-down-light.svg',
	'icon-arrow-down.svg',
	'icon-arrow-up-light.svg',
	'icon-arrow-up.svg',
	'icon-check-light.svg',
	'icon-check.svg',
	'icon-close.svg',
	'icon-home.svg',
	'icon-hourglass.svg',
	'icon-info.svg',
	'icon-loading-blue.svg',
	'icon-loading.svg',
	'icon-logout.svg',
	'icon-minus-btn.svg',
	'icon-minus.svg',
	'icon-plus-btn.svg',
	'icon-plus.svg',
	'icon-report.svg',
	'icon-trash.svg',
];


const areaImages = [
	'background-1.svg',
	'background-2.svg',
	'background-3.svg',
	'background-4.svg',
	'box-closed.svg',
	'box-open.svg',
	'box-open-2.svg',
	'cabinet-1.svg',
	'cabinet-2.svg',
	'clock.svg',
	'console-1.svg',
	'console-2.svg',
	'desks-1.svg',
	'desks-2.svg',
	'interface-1.svg',
	'interface-2.svg',
	'interface-3.svg',
	'interface-4.svg',
	'machine-1.svg',
	'machine-2.svg',
	'machine-3.svg',
	'machine-4.svg',
	'machine-4-b.svg',
	'machine-belt-1.svg',
	'machine-belt-2.svg',
	'machine-belt-2-b.svg',
	'monitor-1.svg',
	'monitor-2.svg',
	'pallet-lift.svg',
	'pallets-1.svg',
	'pallets-2.svg',
	'poster.svg',
	'table.svg',
	'wall-1.svg',
	'wall-2.svg',
	'wall-3.svg',
	'washing-machine-1.svg',
	'washing-machine-2.svg',
	'washing-machine-2-b.svg',
	'windows.svg'
];

const overviewImages = [
	'background.svg',
	'filling-and-inspection-1.svg',
	'filling-and-inspection-2.svg',
	'filling-and-inspection-3.svg',
	'formulation-and-stabilization-1.svg',
	'formulation-and-stabilization-2.svg',
	'office-and-storage-1.svg',
	'office-and-storage-2.svg',
	'overlay-1.svg',
	'overlay-2.svg',
	'overlay-3.svg',
	'overlay-4.svg',
	'overlay.svg',
	'wash-and-sterilization-1.svg',
	'wash-and-sterilization-2.svg'
];

const roundsImages = [
	'animation.svg',
	'round-1.svg',
	'round-2.svg',
	'round-3.svg',
	'round-4.svg',
	'round-5.svg',
	'round-6.svg',
	'round-7.svg',
	'round-8.svg',
];

const staffImages = [
	'staff-1-a.svg',
	'staff-1-b.svg',
	'staff-1-c.svg',
	'staff-1-d.svg',
	'staff-1-e.svg',
	'staff-2-a.svg',
	'staff-2-b.svg',
	'staff-2-c.svg',
	'staff-2-d.svg',
	'staff-2-e.svg',
	'staff-2-f.svg',
	'staff-3-a.svg',
	'staff-3-b.svg',
	'staff-3-c.svg',
	'staff-4-a.svg',
	'staff-4-b.svg',
	'staff-4-c.svg',
	'staff-4-d.svg',
	'staff-4-e.svg',
	'staff-4-f.svg',
	'staff-5-a.svg',
	'staff-6-a.svg',
	'staff-6-b.svg',
]



areaImages.forEach((image) => {imagesData.push('areas/' + image);});
roundsImages.forEach((image) => {imagesData.push('rounds/' + image);});
staffImages.forEach((image) => {imagesData.push('staff/' + image);});
overviewImages.forEach((image) => {imagesData.push('overview/' + image);});

export {
	imagesData
};
