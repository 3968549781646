import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import appConfig from 'config/app.config';
import Loading from 'components/loading/loading';
import SettingsController from 'components/settings/settings-controller';
import Game from 'components/settings/game';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			loadErrMsg: null,
			page: 'settings',
			userData: null,
			games: [],
			groups: [],
			gameId: null,
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeGames = null;
		this.unsubscribeGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get facilitator data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				/* Subscribe to games */
				this.subscribeToGames().then(() => {
					this.setState({isLoading: false});
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe from games */
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
	}

	/**
	 * Subscribe to user (facilitator) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection('users').doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Set facilitator language if not already set */
					if (!userData.languageId) {
						userData.languageId = this.props.languageId;
						this.updateUser({languageId: this.props.languageId});
					}

					/* Update state */
					this.setState({userData}, () => {
						resolve({ status: 'success' });
					});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			}
			);
		});
	}

	/**
	 * Called by the facilitator to subscribe to all their games.
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query = db.collection(appConfig.gamesDbName).where('facilitatorId', '==', this.props.userId);			
			this.unsubscribeGames = query.onSnapshot((querySnapshot) => {
				let games = [];

				querySnapshot.forEach((doc) => {
					let data = doc.data();
					data.id = doc.id;
					if (!data.languageId) data.languageId = appConfig.defaultLanguage;
					if (!data.scenarioId) data.scenarioId = appConfig.defaultScenario;
					games.push(data);
				});
				this.setState({games: games}, () => {resolve({status: 'success'});});
			},
			(error) => {
				console.error('could not get games: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Subscribe to the groups of a game
	 * @param {string} gameId
	 */
	subscribeToGroups = (gameId) => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		
		return new Promise((resolve, reject) => {
			let db = firebase.firestore();
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({ groups: groups }, () => {
						resolve();
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Update user (facilitator) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection('users').doc(userId);
		return userRef.update(updates);
	}

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.gameId;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};



	/**
	 * Go to game
	 * @param {string} gameId
	 */
	handleGoToGame = (gameId) => {
		/* No game */
		if (!gameId) {
			if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
			this.setState({page: 'settings', gameId: null, groups: []});
			return;
		}

		/* Game already selected */
		if (gameId === this.state.gameId) {
			this.setState({ page: 'game' });
			return;
		}

		/* Start game (it not already started) */
		let gameData = this.state.games.find((game) => {return game.id === gameId;});
		if (gameData && !gameData.isStarted) {
			this.updateGame({isStarted: true}, gameId);
		}

		/* Select game and subscribe to its groups */
		this.setState({gameId: gameId, page: 'game', groups: []}, () => {
			this.subscribeToGroups(gameId);
		});
	};

	/**
	 * Go to next game phase
	 */
	goToNextPhase = () => {
		let gameData = this.state.games.find((game) => {return game.id === this.state.gameId;});
		if (gameData) {
			if (gameData.phaseId < appConfig.numberOfPhases) {
				const nextPhaseId = gameData.phaseId + 1;
				this.updateGame({phaseId: nextPhaseId}, gameData.id);
			}
		}
	}

	toggleGamePause = () => {
		let gameData = this.state.games.find((game) => {return game.id === this.state.gameId;});
		let isPaused = (gameData.isPaused ? false : true);
		this.updateGame({isPaused}, gameData.id);
	}



	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading) {
			return (
				<Loading 
					languageId={this.props.languageId} 
					loadErrMsg={this.state.loadErrMsg}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		if (this.state.page === 'game') {
			let gameData = this.state.games.find((game) => {return game.id === this.state.gameId;});
			return (
				<Game 
					userData={this.state.userData}
					gameData={gameData}
					groups={this.state.groups}
					handleGoToGame={this.handleGoToGame}
					goToNextPhase={this.goToNextPhase}
					toggleGamePause={this.toggleGamePause}
				/>
			);
		}
		return (
			<SettingsController 
				userId={this.props.userId}
				userData={this.state.userData}
				games={this.state.games}
				updateUser={this.updateUser}
				updateGame={this.updateGame}
				handleGoToGame={this.handleGoToGame}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
}

FacilitatorController.propTypes = {
	userId: PropTypes.string.isRequired,
	languageId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default FacilitatorController;
