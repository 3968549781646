let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gamesDbName = 'games';
let groupsDbName = 'groups';
let archiveDbName = 'archive';
let gameUrl = 'localhost:8080';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-unitcost-test.web.app';
	apiURL = 'https://cgl-unitcost-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-unitcost-demo.web.app';
		apiURL = 'https://cgl-unitcost-demo.web.app/api/';
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
		archiveDbName = 'archive-demo';
	}
}

if (env === 'production') {
	gameUrl = 'cgl-unitcost-production.web.app';
	apiURL = 'https://cgl-unitcost-production.web.app/api/';
}

const appConfig = {
	env: env,
	name: 'unitcost',
	apiURL: apiURL,
	gameUrl: gameUrl,
	gamesDbName: gamesDbName,
	groupsDbName: groupsDbName,
	archiveDbName,
	validEmails: ['cphgamelab.dk', 'novonordisk.com'],
	defaultScenario: 'scenario-1',
	defaultLanguage: 'en',
	gameTitleMaxLength: 18,
	groupNameMinLength: 3,
	groupNameMaxLength: 12,
	numberOfPhases: 2,
	actionsPerRound: 3,
	graphFutureRoundsMax: 3,
	initialWorkEnvironment: 5,
	minWorkEnvironment: 1,
	maxWorkEnvironment: 9,
	minWaste: 1,
	demandUncertaintyPerRound: .25,
	demandUncertaintyPerRoundReduced: 0.05,
	extraProductionCostFactor: 1.5,
	futureRoundSpan: 40000,
	newRoundAnimationDuration: 7500,
	reportSnippets: 3,
	bottleneckDifferenceMax: 0.33,
	cookiesAcceptedCookieName: 'unitcost_cookies_accepted'
};

export default appConfig;
