
import React from 'react';
import {staticOverviewObjectsData} from 'data/overview-objects-data';
import './background.scss';
import Button from 'components/button/button';

const Background = () => {
	return (
		<div id="background" className="Background">
			<Button
				classes={['cgl']}
				type="a"
				href="https://cphgamelab.dk"
				text=""
			/>
			{/* Objects */}
			<div className="Background-objects">
				<svg viewBox='0 0 1536 864' version='1.1' xmlns='http://www.w3.org/2000/svg'>
					{staticOverviewObjectsData.map((object, index) => {
						/* Object translation */
						let offsetX = (object.width / 2.);
						let offsetY = (object.height / 2.);
						if (object.hasOwnProperty('offsetX')) offsetX = object.offsetX;
						if (object.hasOwnProperty('offsetY')) offsetY = object.offsetY;

						return (
							<g 
								key={index} 
								width={object.width}
								height={object.height}
								transform={'translate(' + (object.x - offsetX) + ', ' + (object.y - offsetY) + ')'}
							>
								<image 
									x={0}
									y={0}
									width={object.width}
									height={object.height}
									href={object.href}
								/>
							</g>
						);
					})}
				</svg>
			</div>

			{/* Overlay */}
			<div className={'Background-overlay'} />

		</div>
	);
};


export default Background;
