import {generalUiTexts} from './ui-texts';

const basicActionTexts = {
	adjustOperatorShifts: {
		da: 'Juster operatør-skiftehold',
		en: 'Adjust operator shifts'
	},
	adjustProductionSupportShifts: {
		da: 'Juster produktionssupport',
		en: 'Adjust production support shifts'
	},
	adjustNumberOfShifts: {
		da: 'Juster antal skiftehold.',
		en: 'Adjust number of shifts.'
	}
}

const actionsData = [
	// BASIC ACTIONS
	{
		id: 1,
		areaId: 'wash-and-sterilization',
		subAreaId: 'personnel',
		isRepeatable: true,
		rounds: [1, 8],
		title: basicActionTexts.adjustOperatorShifts,
		description: basicActionTexts.adjustNumberOfShifts,
		type: 'change-parameter',
		parameterId: 'operator-teams',
		affectedParameters: ['operators', 'operator-teams', 'effective-capacity']
	},
	{
		id: 2,
		areaId: 'wash-and-sterilization',
		subAreaId: 'personnel',
		isRepeatable: true,
		rounds: [1, 8],
		title: basicActionTexts.adjustProductionSupportShifts,
		description: basicActionTexts.adjustNumberOfShifts,
		type: 'change-parameter',
		parameterId: 'support-teams',
		affectedParameters: ['production-support', 'support-teams', 'effective-capacity']
	},
	{
		id: 3,
		areaId: 'formulation-and-stabilization',
		subAreaId: 'personnel',
		isRepeatable: true,
		rounds: [1, 8],
		title: basicActionTexts.adjustOperatorShifts,
		description: basicActionTexts.adjustNumberOfShifts,
		type: 'change-parameter',
		parameterId: 'operator-teams',
		affectedParameters: ['operators', 'operator-teams', 'effective-capacity']
	},	
	{
		id: 4,
		areaId: 'formulation-and-stabilization',
		subAreaId: 'personnel',
		isRepeatable: true,
		rounds: [1, 8],
		title: basicActionTexts.adjustProductionSupportShifts,
		description: basicActionTexts.adjustNumberOfShifts,
		type: 'change-parameter',
		parameterId: 'support-teams',
		affectedParameters: ['production-support', 'support-teams', 'effective-capacity']
	},
	{
		id: 5,
		areaId: 'filling-and-inspection',
		subAreaId: 'personnel',
		isRepeatable: true,
		rounds: [1, 8],
		title: basicActionTexts.adjustOperatorShifts,
		description: basicActionTexts.adjustNumberOfShifts,
		type: 'change-parameter',
		parameterId: 'operator-teams',
		affectedParameters: ['operators', 'operator-teams', 'effective-capacity']
	},	
	{
		id: 6,
		areaId: 'filling-and-inspection',
		subAreaId: 'personnel',
		isRepeatable: true,
		rounds: [1, 8],
		title: basicActionTexts.adjustProductionSupportShifts,
		description: basicActionTexts.adjustNumberOfShifts,
		type: 'change-parameter',
		parameterId: 'support-teams',
		affectedParameters: ['production-support', 'support-teams', 'effective-capacity']
	},

	// OTHER ACTIONS
	{
		id: 16, // 1
		areaId: 'wash-and-sterilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Afsøg råvaremarkedet',
			en: 'Search commodity market'
		},
		description: {
			da: 'Giver adgang til at købe andre råvarer. Dette kan muligvis give mindre spild eller lavere omkostninger.',
			en: 'Gives you access to buy other raw materials. This could result in less scrap or lower costs.',
		},
		type: 'buy-item',
		totalCost: 500,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'wash-and-sterilization', cost: 500, rounds: 1},
			{type: 'add-action-lock', actionId: 16},
			{type: 'remove-action-lock', actionId: 17},
			{type: 'remove-action-lock', actionId: 24},
		],
		affectedParameters: []
	},
	{
		id: 8, // 2
		areaId: 'formulation-and-stabilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Afsøg råvaremarkedet',
			en: 'Search commodity market'
		},
		description: {
			da: 'Giver adgang til at købe andre råvarer. Dette kan muligvis give mindre spild eller lavere omkostninger.',
			en: 'Gives you access to buy other raw materials. This could result in less scrap or lower costs.'
		},
		type: 'buy-item',
		totalCost: 500,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'formulation-and-stabilization', cost: 500, rounds: 1},
			{type: 'add-action-lock', actionId: 8},
			{type: 'remove-action-lock', actionId: 9},
			{type: 'remove-action-lock', actionId: 28},
		],
		affectedParameters: []
	},
	{
		id: 31, // 3
		areaId: 'filling-and-inspection',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Afsøg råvaremarkedet',
			en: 'Search commodity market'
		},
		description: {
			da: 'Giver adgang til at købe andre råvarer. Dette kan muligvis give mindre spild eller lavere omkostninger.',
			en: 'Gives you access to buy other raw materials. This could result in less scrap or lower costs.'
		},
		type: 'buy-item',
		totalCost: 500,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'filling-and-inspection', cost: 500, rounds: 1},
			{type: 'add-action-lock', actionId: 31},
			{type: 'remove-action-lock', actionId: 32},
			{type: 'remove-action-lock', actionId: 33},
		],
		affectedParameters: []
	},
	{
		id: 22, // 4
		areaId: 'wash-and-sterilization',
		subAreaId: 'personnel',
		rounds: [1, 8],
		title: {
			da: 'cLEAN kvallitetskontrolsprocedure',
			en: 'cLEAN quality control procedure'
		},
		description: {
			da: 'Nedsætter spild med forventede 1,5%-point.',
			en: 'Reduces scrap with an expected 1.5% points.'
		},
		type: 'buy-item',
		totalCost: 500,
		unit: generalUiTexts.costPerTurn,
		requirements: [
			{type: 'action', actionId: 18}
		],
		effects: [
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'waste', value: -1.5},
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'expenses', value: 500},
		],
		affectedParameters: ['expenses', 'waste']
	},
	{
		id: 26, // 5
		areaId: 'formulation-and-stabilization',
		subAreaId: 'personnel',
		rounds: [1, 8],
		title: {
			da: 'cLEAN kvallitetskontrolsprocedure',
			en: 'cLEAN quality control procedure'
		},
		description: {
			da: 'Nedsætter spild med forventede 1,5%-point.',
			en: 'Reduces scrap with an expected 1.5% points.'
		},
		type: 'buy-item',
		totalCost: 500,
		unit: generalUiTexts.costPerTurn,
		requirements: [
			{type: 'action', actionId: 18}
		],
		effects: [
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'waste', value: -2},
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'expenses', value: 500},
		],
		affectedParameters: ['waste', 'expenses']
	},
	{
		id: 19, // 6
		areaId: 'filling-and-inspection',
		subAreaId: 'personnel',
		rounds: [1, 8],
		title: {
			da: 'cLEAN kvallitetskontrolsprocedure',
			en: 'cLEAN quality control procedure'
		},
		description: {
			da: 'Nedsætter spild med forventede 1%-point.',
			en: 'Reduces scrap with an expected 1% points.'
		},
		type: 'buy-item',
		totalCost: 500,
		unit: generalUiTexts.costPerTurn,
		requirements: [
			{type: 'action', actionId: 18}
		],
		effects: [
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'waste', value: -2},
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'expenses', value: 500},
		],
		affectedParameters: ['expenses', 'waste']
	},
	{
		id: 25, // 7
		areaId: 'wash-and-sterilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'cLEAN procesoptimering - mindre spild',
			en: 'cLEAN process optimisation - less scrap'
		},
		description: {
			da: 'Mindsker spild med forventet 1%-point.',
			en: 'Reduces scrap with an expected 1% points.'
		},
		type: 'buy-item',
		totalCost: 900,
		unit: generalUiTexts.nb,
		requirements: [
			{type: 'action', actionId: 18}
		],
		effects: [
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'waste', value: -1},
			{type: 'expenses-write-off', areaId: 'wash-and-sterilization', cost: 900, rounds: 1}
		],
		affectedParameters: ['waste']
	},
	{
		id: 29, // 8
		areaId: 'formulation-and-stabilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'cLEAN procesoptimering - mindre spild',
			en: 'cLEAN process optimisation - less scrap'
		},
		description: {
			da: 'Mindsker spild med 2%-point.',
			en: 'Reduces scrap with an expected 2% points.'
		},
		type: 'buy-item',
		totalCost: 3000,
		unit: generalUiTexts.nb,
		requirements: [
			{type: 'action', actionId: 18}
		],
		effects: [
			{type: 'expenses-write-off', areaId: 'formulation-and-stabilization', cost: 3000, rounds: 1},
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'waste', value: -2},
		],
		affectedParameters: ['waste']
	},
	{
		id: 20, // 9
		areaId: 'filling-and-inspection',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'cLEAN procesoptimering - mindre spild',
			en: 'cLEAN process optimisation - less scrap'
		},
		description: {
			da: 'Mindsker spild med forventet 2%-point.',
			en: 'Reduces scrap with an expected 2% points.'
		},
		type: 'buy-item',
		totalCost: 3000,
		unit: generalUiTexts.nb,
		requirements: [
			{type: 'action', actionId: 18}
		],
		effects: [
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'waste', value: -2},
			{type: 'expenses-write-off', areaId: 'filling-and-inspection', cost: 3000, rounds: 1}
		],
		affectedParameters: ['waste']
	},
	{
		id: 18, // 10
		areaId: 'office-and-storage',
		subAreaId: 'initiatives',
		rounds: [2, 8],
		title: {
			da: 'cLEAN træning',
			en: 'cLEAN training'
		},
		description: {
			da: 'cLEAN træning til hele fabrikken. Giver mulighed for at tage handlinger der forbedrer processer fremadrettet.',
			en: 'cLEAN training for the whole factory. Allows you to perform actions that improve processes going forward.'
		},
		type: 'buy-item',
		totalCost: 2300,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'office-and-storage', cost: 2300, rounds: 1}
		],
		affectedParameters: []
	},
	{
		id: 11, // 11
		areaId: 'office-and-storage',
		subAreaId: 'office',
		rounds: [3, 8],
		title: {
			da: 'Hyr demand-analytikere',
			en: 'Hire demand analysts'
		},
		description: {
			da: 'Ansæt analytikere til at analysere demand udvikling. Giver betydeligt bedre indblik i demand.',
			en: 'Hire analysts to analyze demand development. Gives you a much better overview of demand.'
		},
		type: 'buy-item',
		totalCost: 400,
		unit: generalUiTexts.costPerTurn,
		effects: [
			{type: 'upgrade', upgrade: {type: 'reduce-demand-uncertainty'}},
			{type: 'addition', areaId: 'office-and-storage', parameter: 'expenses', value: 400}
		],
		affectedParameters: ['expenses', 'expected-demand']
	},
	{
		id: 34, // 12
		areaId: 'office-and-storage',
		subAreaId: 'office',
		rounds: [5, 8],
		title: {
			da: 'Implementer nye GMP-krav',
			en: 'Implement new GMP requirements'
		},
		description: {
			da: 'Implementer nye GMP-krav der træder i kraft i tur 8. Handlingen skal tages senest i tur 8.',
			en: 'Implement new GMP requirements that become effective in turn no. 8. This action must be performed before or during turn 8.'
		},
		type: 'buy-item',
		totalCost: 0,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'upgrade', upgrade: {type: 'gmp-demands-implemented'}},
		],
		affectedParameters: []
	},
	{
		id: 24, // 13
		areaId: 'wash-and-sterilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Køb bedre råvarer',
			en: 'Buy better raw materials'
		},
		description: {
			da: 'Øger prisen per råvare i denne produktionsenhed med omkring 0,03NB/unit. Nedsætter spild med forventet 2%-point.',
			en: 'Increases price per raw material in this production unit by approx. 0.03NB/unit. Reduces scrap with an expected 2% points.'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 17}
		],
		effects: [
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'material-cost-per-unit', value: 0.0259},
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'waste', value: -1},
			{type: 'add-action-lock', actionId: 17},
			{type: 'add-action-lock', actionId: 24},
		],
		affectedParameters: ['waste', 'materials']
	},
	{
		id: 9, // 14
		areaId: 'formulation-and-stabilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Køb bedre råvarer',
			en: 'Buy better raw materials'
		},
		description: {
			da: 'Øger prisen per råvare i denne produktionsenhed med omkring 0,03NB/unit. Nedsætter spild med forventet 2%-point.',
			en: 'Increases price per raw material in this production unit by approx. 0.03NB/unit. Reduces scrap with an expected 2% points.'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 28}
		],
		effects: [
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'material-cost-per-unit', value: 0.0259},
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'waste', value: -1.3},
			{type: 'add-action-lock', actionId: 9},
			{type: 'add-action-lock', actionId: 28},
		],
		affectedParameters: ['waste', 'materials']
	},
	{
		id: 33, // 15
		areaId: 'filling-and-inspection',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Køb bedre råvarer',
			en: 'Buy better raw materials'
		},
		description: {
			da: 'Øger prisen per råvare i denne produktionsenhed med omkring 0,03NB/unit. Nedsætter spild med forventet 2%-point.',
			en: 'Increases price per raw material in this production unit by approx. 0.03NB/unit. Reduces scrap with an expected 2% points.'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 32}
		],
		effects: [
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'material-cost-per-unit', value: 0.0359},
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'waste', value: -1},
			{type: 'add-action-lock', actionId: 32},
			{type: 'add-action-lock', actionId: 33},
		],
		affectedParameters: ['waste', 'materials']
	},
	{
		id: 17, // 16
		areaId: 'wash-and-sterilization',
		subAreaId: 'materials',
		rounds: [2, 8],
		title: {
			da: 'Køb billigere råvarer af dårligere kvalitet',
			en: 'Buy cheaper raw materials of lower quality'
		},
		description: {
			da: 'Sænker råvareromkostninger med omkring -0,02NB/Unit. Øger spild med forventet 0,5%-point.',
			en: 'Lowers costs of raw materials with about -0.02NB/Unit. Increases scrap with an expected 0.5% points.',
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 24}
		],
		effects: [
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'material-cost-per-unit', value: -0.04},
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'waste', value: 0.3},
			{type: 'add-action-lock', actionId: 17},
			{type: 'add-action-lock', actionId: 24},

		],
		affectedParameters: ['materials', 'waste']
	},
	{
		id: 28, // 17
		areaId: 'formulation-and-stabilization',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Køb billigere råvarer af dårligere kvalitet',
			en: 'Buy cheaper raw materials of lower quality'
		},
		description: {
			da: 'Sænker råvareromkostninger med omkring -0,02NB/Unit. Øger spild med forventet 0,5%-point.',
			en: 'Lowers costs of raw materials with about -0.02NB/Unit. Increases scrap with an expected 0.5% points.'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 9}
		],
		effects: [
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'material-cost-per-unit', value: -0.03},
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'waste', value: 0.3},
			{type: 'add-action-lock', actionId: 9},
			{type: 'add-action-lock', actionId: 28},
		],
		affectedParameters: ['materials', 'waste']
	},
	{
		id: 32, // 18
		areaId: 'filling-and-inspection',
		subAreaId: 'materials',
		rounds: [1, 8],
		title: {
			da: 'Køb billigere råvarer af dårligere kvalitet',
			en: 'Buy cheaper raw materials of lower quality'
		},
		description: {
			da: 'Sænker råvareromkostninger med omkring -0,02NB/Unit. Øger spild med forventet 0,5%-point.',
			en: 'Lowers costs of raw materials with about -0.02NB/Unit. Increases scrap with an expected 0.5% points.'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 33}
		],
		effects: [
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'material-cost-per-unit', value: -0.04},
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'waste', value: 0.3},
			{type: 'add-action-lock', actionId: 32},
			{type: 'add-action-lock', actionId: 33},
		],
		affectedParameters: ['materials', 'waste']
	},
	{
		id: 12, // 19
		areaId: 'office-and-storage',
		subAreaId: 'initiatives',
		rounds: [4, 8],
		isRepeatable: true,
		title: {
			da: 'Kursus i arbejdsmiljø',
			en: 'Course in work environment'
		},
		description: {
			da: 'Forbedrer arbejdsmiljøet. Arbejdsmiljø påvirker effektiviteten af jeres medarbejdere med op til +/- 3%-point.',
			en: 'Improves the work environment. The work environment affects the efficiency of your workers with up to +/- 3% points.'
		},
		type: 'buy-item',
		totalCost: 4000,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'office-and-storage', cost: 4000, rounds: 1},
			{type: 'work-environment', value: 3},
			{type: 'add-action-lock', actionId: 12},
			{type: 'delayed-effect', effect: {type: 'remove-action-lock', actionId: 12, delay: 2}}
		],
		affectedParameters: []
	},
	{
		id: 10, // 20
		areaId: 'office-and-storage',
		subAreaId: 'initiatives',
		rounds: [1, 8],
		title: {
			da: 'Kursus i råvarehåndtering',
			en: 'Course in handling raw materials'
		},
		description: {
			da: 'Forventes at nedsætte spild med 1%-point i alle afsnit.',
			en: 'It is expected to reduce scrap by 1% points in all sections.'
		},
		type: 'buy-item',
		totalCost: 6500,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'office-and-storage', cost: 6500, rounds: 1},
			{type: 'addition', areaId: 'wash-and-sterilization', parameter: 'waste', value: -1},
			{type: 'addition', areaId: 'formulation-and-stabilization', parameter: 'waste', value: -1},
			{type: 'addition', areaId: 'filling-and-inspection', parameter: 'waste', value: -1},
		],
		affectedParameters: []
	},
	{
		id: 21, // 21
		areaId: 'office-and-storage',
		subAreaId: 'storage',
		rounds: [1, 8],
		title: {
			da: 'Lagerstyring',
			en: 'Warehouse control'
		},
		description: {
			da: 'Skab bedre procedurer for lagerstyring. Forventer at nedsætte lagerspild med 5%-point.',
			en: 'Improve procedures for inventory control. It is expected to decrease inventory scrap by 5% points.'
		},
		type: 'buy-item',
		totalCost: 4450,
		unit: generalUiTexts.nb,
		effects: [
			{type: 'expenses-write-off', areaId: 'office-and-storage', cost: 4450, rounds: 1},
			{type: 'addition', areaId: 'office-and-storage', parameter: 'waste', value: -5},
		],
		affectedParameters: ['waste']
	},
	{
		id: 14, // 22
		areaId: 'office-and-storage',
		subAreaId: 'office',
		rounds: [4, 8],
		title: {
			da: 'Opgrader udluftningssystem',
			en: 'Upgrade ventilation'
		},
		description: {
			da: 'Nedsætter strømforbruget. "Øvrige løbende udgifter" forventes reduceret med 8%-10% i alle afsnit. Forbedrer desuden arbejdsmiljøet.<br /><br />Investering: 10.000NB, som afskrives over 8 ture.<br /><br /><strong>Afskrivning pr tur: 1.250 NB.</strong>',
			en: 'Reduces power consumption. "Other running costs" are expected to be reduced by 8%-10% in all sections. Improves the work environment.<br /><br />Investment: 10,000NB that will be depreciated over 8 turns.<br /><br /><strong>Depreciation per turn: 1,250NB</strong>'
		},
		type: 'buy-item',
		effects: [
			{type: 'machine-write-off', areaId: 'office-and-storage', cost: 1250, rounds: 8},
			{type: 'multiplication', areaId: 'wash-and-sterilization', parameter: 'expenses', value: 0.91},
			{type: 'multiplication', areaId: 'formulation-and-stabilization', parameter: 'expenses', value: 0.91},
			{type: 'multiplication', areaId: 'filling-and-inspection', parameter: 'expenses', value: 0.91},
			{type: 'multiplication', areaId: 'office-and-storage', parameter: 'expenses', value: 0.91},
			{type: 'work-environment', value: 2},
		],
		affectedParameters: ['expenses']
	},
	{
		id: 27, // 23
		areaId: 'formulation-and-stabilization',
		subAreaId: 'machines',
		rounds: [1, 8],
		title: {
			da: 'Opgrader formuleringsmaskine',
			en: 'Upgrade formulation machine'
		},
		description: {
			da: 'Forventes at give 8-12%-point højere kapacitetsudnyttelse. Maskinen må sættes ud af drift i en periode. Det giver et effektivitetstab på 4%-point i første tur.<br /><br />Investering: 6.500 NB, afskrives over 2 ture.<br /><br /><strong>Afskrivning pr tur: 3.250 NB.</strong>',
			en: 'It is expected to give 8-12% points higher capacity utilisation. The machine will be out of operation for a period of time. This will result in an efficiency loss of 4% points in the first turn.<br /><br />Investment: 6,500 NB that will be written off over 2 turns.<br /><br /><strong>Depreciation per turn: 3,250 NB.</strong>'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 13}
		],
		effects: [
			{type: 'machine-is-upgraded', areaId: 'formulation-and-stabilization', value: true},
			{type: 'machine-write-off', areaId: 'formulation-and-stabilization', cost: 3250, rounds: 2},
			{type: 'upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'formulation-and-stabilization', modifierType: 'repair-implementation', value: -0.05, rounds: 1}},
			{type: 'upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'formulation-and-stabilization', modifierType: 'repair-bonus', value: 0.05, rounds: 8, delay: 1}},
		],
		affectedParameters: ['machines', 'effective-capacity']
	},
	{
		id: 30, // 24
		areaId: 'filling-and-inspection',
		subAreaId: 'machines',
		rounds: [1, 8],
		title: {
			da: 'Opgrader fyldningsmaskine',
			en: 'Upgrade filling machine'
		},
		description: {
			da: 'Forventes at give 8-12%-point højere kapacitetsudnyttelse. Maskinen må sættes ud af drift i en periode. Det giver et effektivitetstab på 4%-point i første tur.<br /><br />Investering: 7.500 NB, afskrives over 2 ture.<br /><br /><strong>Afskrivning pr tur: 3.750 NB.</strong>',
			en: 'It is expected to give 8-12% points higher capacity utilisation. The machine will be out of operation for a period of time. This will result in an efficiency loss of 4% points in the first turn.<br /><br />Investment: 7,500 NB that will be written off over 2 turns.<br /><br /><strong>Depreciation per turn: 3,750 NB.</strong>'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 15}
		],
		effects: [
			{type: 'machine-is-upgraded', areaId: 'filling-and-inspection', value: true},
			{type: 'machine-write-off', areaId: 'filling-and-inspection', cost: 3750, rounds: 2},
			{type: 'upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'filling-and-inspection', modifierType: 'repair-implementation', value: -0.05, rounds: 1}},
			{type: 'upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'filling-and-inspection', modifierType: 'repair-bonus', value: 0.05, rounds: 8, delay: 1}},
		],
		affectedParameters: ['machines', 'effective-capacity']
	},
	{
		id: 23, // 25
		areaId: 'wash-and-sterilization',
		subAreaId: 'machines',
		rounds: [1, 8],
		title: {
			da: 'Opgrader vaskemaskine',
			en: 'Upgrade washing machine'
		},
		description: {
			da: 'Forventes at give 8-12%-point højere kapacitetsudnyttelse. Maskinen må sættes ud af drift i en periode. Det giver et effektivitetstab på 4%-point i første tur.<br /><br />Investering: 8.350 NB, afskrives over 2 ture.<br /><br /><strong>Afskrivning pr tur: 4.175 NB.</strong>',
			en: 'It is expected to give 8-12% points higher capacity utilisation. The machine will be out of operation for a period of time. This will result in an efficiency loss of 4% points in the first turn.<br /><br />Investment: 8,350 NB that will be written off over 2 turns.<br /><br /><strong>Depreciation per turn: 4,175 NB.</strong>'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 7}
		],
		effects: [
			{type: 'machine-is-upgraded', areaId: 'wash-and-sterilization', value: true},
			{type: 'machine-write-off', areaId: 'wash-and-sterilization', cost: 4175, rounds: 2},
			{type: 'upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'wash-and-sterilization', modifierType: 'repair-implementation', value: -0.10, rounds: 1}},
			{type: 'upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'wash-and-sterilization', modifierType: 'repair-bonus', value: 0.05, rounds: 8, delay: 1}},
		],
		affectedParameters: ['machines', 'effective-capacity']
	},
	{
		id: 13, // 26
		areaId: 'formulation-and-stabilization',
		subAreaId: 'machines',
		rounds: [2, 8],
		title: {
			da: 'Udskift formuleringsmaskine',
			en: 'Change formulation machine'
		},
		description: {
			da: 'Har en udstyrskapacitet på 400.000 units og forventet 5-10%-point højere kapacitetsudnyttelse, så maskinen kræver i praksis mindre bemanding.<br /><br />Har nedsat kapacitet de første to ture: første tur 50%, så 75%, derefter 100%. <br /><br />Investering: 56.250 NB, afskrives over 8 ture. <br /><br /><strong>Afskrivning per tur: 7.031,25 NB.</strong>',
			en: 'Has an equipment capacity of 400,000 units and expected 5-10% points higher capacity utilisation so the machine will require less staff.<br /><br />Has a lowered capacity the first two turns: first turn 50%, then 75% and then 100%.<br /><br />Investment: 56,250 NB that will be depreciated over 8 turns.<br /><br /><strong>Depreciation per turn: 7,031.25 NB.</strong>'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 27}
		],
		effects: [
			{type: 'machine-level', areaId: 'formulation-and-stabilization', level: 2},
			{type: 'machine-is-upgraded', areaId: 'formulation-and-stabilization', value: false},
			{type: 'machine-write-off', areaId: 'formulation-and-stabilization', cost: 7031.25, rounds: 8},
			{type: 'upgrade', upgrade: {type: 'machine-capacity-modifier', areaId: 'formulation-and-stabilization', value: 0.5, rounds: 1}},
			{type: 'upgrade', upgrade: {type: 'machine-capacity-modifier', areaId: 'formulation-and-stabilization', value: 0.75, rounds: 1, delay: 1}},
			{type: 'remove-upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'formulation-and-stabilization'}},
			{type: 'add-action-lock', actionId: 27},
		],
		affectedParameters: ['equipment-capacity', 'machines']
	},
	{
		id: 15, // 27
		areaId: 'filling-and-inspection',
		subAreaId: 'machines',
		rounds: [2, 8],
		title: {
			da: 'Udskift gennemlysningsmaskine',
			en: 'Change transillumination machine'
		},
		description: {
			da: 'Har en udstyrskapacitet på 380.000 units og forventet 5-10%-point højere kapacitetsudnyttelse, så maskinen kræver i praksis mindre bemanding.<br /><br />Har nedsat kapacitet de første to ture: første tur 50%, så 75%, derefter 100%. <br /><br />Investering: 75.000 NB, afskrives over 8 ture. <br /><br /><strong>Afskrivning per tur: 9.375 NB.</strong>',
			en: 'Has an equipment capacity of 380,000 units and expected 5-10% points higher capacity utilisation so the machine will require less staff.<br /><br />Has a lowered capacity the first two turns: first turn 50%, then 75% and then 100%.<br /><br />Investment: 75,000 NB that will be depreciated over 8 turns.<br /><br /><strong>Depreciation per turn: 9,375 NB.</strong>',
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 30}
		],
		effects: [
			{type: 'machine-level', areaId: 'filling-and-inspection', level: 2},
			{type: 'machine-is-upgraded', areaId: 'filling-and-inspection', value: false},
			{type: 'machine-write-off', areaId: 'filling-and-inspection', cost: 9375, rounds: 8},
			{type: 'upgrade', upgrade: {type: 'machine-capacity-modifier', areaId: 'filling-and-inspection', value: 0.5, rounds: 1}},
			{type: 'upgrade', upgrade: {type: 'machine-capacity-modifier', areaId: 'filling-and-inspection', value: 0.75, rounds: 1, delay: 1}},
			{type: 'remove-upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'filling-and-inspection'}},
			{type: 'add-action-lock', actionId: 30},
		],
		affectedParameters: ['equipment-capacity', 'machines']
	},
	{
		id: 7, // 28
		areaId: 'wash-and-sterilization',
		subAreaId: 'machines',
		rounds: [1, 8],
		title: {
			da: 'Udskift vaskemaskine',
			en: 'Change washing machine'
		},
		description: {
			da: 'Har en udstyrskapacitet på 300.000 units og forventet 5-10%-point højere kapacitetsudnyttelse, så maskinen kræver i praksis mindre bemanding.<br /><br >Har nedsat kapacitet de første to ture: første tur 50%, så 75%, derefter 100%. <br /><br />Investering: 57.000 NB, afskrives over 8 ture. <br /><br /><strong>Afskrivning per tur: 7.125 NB.</strong>',
			en: 'Has an equipment capacity of 300,000 units and expected 5-10% points higher capacity utilisation so the machine will require less staff.<br /><br />Has a lowered capacity the first two turns: first turn 50%, then 75% and then 100%.<br /><br />Investment: 57,000 NB that will be depreciated over 8 turns.<br /><br /><strong>Depreciation per turn: 7,125 NB.</strong>'
		},
		type: 'buy-item',
		requirements: [
			{type: 'action-not-selected', actionId: 23}
		],
		effects: [
			{type: 'machine-level', areaId: 'wash-and-sterilization', level: 2},
			{type: 'machine-is-upgraded', areaId: 'wash-and-sterilization', value: false},
			{type: 'machine-write-off', areaId: 'wash-and-sterilization', cost: 7125, rounds: 8},
			{type: 'upgrade', upgrade: {type: 'machine-capacity-modifier', areaId: 'wash-and-sterilization', value: 0.5, rounds: 1}},
			{type: 'upgrade', upgrade: {type: 'machine-capacity-modifier', areaId: 'wash-and-sterilization', value: 0.75, rounds: 1, delay: 1}},
			{type: 'remove-upgrade', upgrade: {type: 'support-efficiency-modifier', areaId: 'wash-and-sterilization'}},
			{type: 'add-action-lock', actionId: 23},
		],
		affectedParameters: ['equipment-capacity', 'machines']
	},
];

export {actionsData};