import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import appConfig from 'config/app.config';
import {loginUiTexts} from 'data/ui-texts';
import {pagesData} from 'data/pages-data';
import {roundsData} from 'data/rounds-data';
import {actionsData} from 'data/actions-data';
import {getText} from 'helpers/language-helper';
import {getTotalUnitCost, calculateRoundStats, calculateRoundEffects} from 'helpers/game-helper';
import Navigation from 'components/navigation/navigation';
import Button from 'components/button/button';
import Overview from 'components/overview/overview';
import Area from 'components/area/area';
import PauseOverlay from 'components/pause-overlay/pause-overlay';
import IntroPopup from 'components/intro-popup/intro-popup';
import InfoPopup from 'components/info-popup/info-popup';
import ReportPopup from 'components/report-popup/report-popup';
import IllustrationPopup from 'components/illustration-popup/illustration-popup';

class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isCalculatingEffects: false,
			pageId: 'overview',
			hoverAreaId: null,
			game: null,
			group: null,
			roundStats: null,
			groupIsPlaying: false,
			showIntroPopup: true,
			showIntroPopupAfterReportPopup: false,
			showInfoPopup: false,
			showReportPopup: false,
			showIllustrationPopup: false,
			animateInfoIcon: false,
			animateRoundId: false,
			fireRoundIdAnimation: false,
			graphDataIds: ['demand', 'production', 'costs', 'unit-cost'],
			openActionContainers: [],
			openActions: []
		};

		this.timeout = null;
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
		this.pageTypeComponents = {
			overview: {component: Overview},
			area: {component: Area},
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let groupId = this.props.userId;
		let gameId = groupId.substr(0, groupId.indexOf('-'));
		Promise.all([
			/* Subscribe to game and group */
			this.subscribeToGame(gameId), 
			this.subscribeToGroup(groupId)
		]).then((responses) => {
			if (responses[0].status === 'ok' && responses[1].status === 'ok') {
				/* Calculate round stats */
				this.handlecalculateRoundStats().then(() => {
					/* Check if group is logging in for the first time */
					let groupIsPlaying = (this.state.group.isPlaying === true ? true : false);

					/* Only show some of the data in the graph in first round */
					if (this.state.group.roundId === 1) this.setState({graphDataIds: ['demand', 'unit-cost']});

					/* Check if intro popup should show (start of game (not playing), start of round 5) */
					let showIntroPopup = !groupIsPlaying;
					if (groupIsPlaying) {
						let roundData = roundsData.find((round) => {return round.id === this.state.group.roundId;});
						if (roundData && roundData.id === 5 && this.state.game.phaseId >= roundData.phaseId) {
							showIntroPopup = true;
						}
					}
					this.setState({isLoading: false, showIntroPopup, groupIsPlaying}, () => {
						if (!groupIsPlaying) this.updateGroup({isPlaying: true});
					});
				});
			} else {
				console.error(responses);
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / group */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();

		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	}	

	/**
	 * Subscribe to game
	 * @param {string} gameId
	 */
	subscribeToGame = (gameId) => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGame = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get updated game */
					let data = doc.data();
					data.id = doc.id;
					if (!data.languageId) data.languageId = appConfig.defaultLanguage;
					if (!data.scenarioId) data.scenarioId = appConfig.defaultScenario;
					let prevGameData = this.state.game;
					this.setState({ game: data }, () => {
						this.checkGameUpdates(prevGameData);
						resolve({ status: 'ok' });
					});
				} else {
					/* No game, probably deleted: log out group */
					this.props.handleLogout();
				}
			},
			(error) => {
				/* Error: game not found */
				console.error('could not get game: ', error);
				resolve({ status: 'error', error: error });
			}
			);
		});
	};

	/**
	 * Subscribe to own group
	 * @param {string} groupId
	 */
	subscribeToGroup = (groupId) => {
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGroup = db.collection(appConfig.groupsDbName).doc(groupId).onSnapshot((doc) => {
				if (doc.exists) {
					let data = doc.data();
					data.id = doc.id;
					let prevGroupData = this.state.group;
					this.setState({ group: data }, () => {
						this.checkGroupUpdates(prevGroupData);
						resolve({ status: 'ok' });
					});
				} else {
					this.props.handleLogout();	
				}
			},
			(error) => {
				console.error('could not get group: ', error);
				this.props.handleLogout();
			}
			);
		});
	};

	/**
	 * Check game updates
	 * @param {object} prevGameData
	 */
	checkGameUpdates = (prevGameData) => {
		/* Facilitator opened phase 2 */
		if (prevGameData && this.state.game && prevGameData.phaseId !== this.state.game.phaseId) {
			let roundData = roundsData.find((round) => {return round.id === this.state.group.roundId;});
			if (roundData && roundData.showIntroPopup === true && this.state.game.phaseId === roundData.phaseId) {
				this.setState({showIntroPopup: true});
			}
		}
	};

	/**
	 * Check group updates
	 * @param {object} prevGroupData
	 */
	checkGroupUpdates = (prevGroupData) => {
		/* Group progressed to next round */
		if (prevGroupData && prevGroupData.roundId !== this.state.group.roundId) {
			/* Recalculate round stats */
			this.handlecalculateRoundStats();

			/* Activate all plots in report popup */
			this.setState({graphDataIds: ['demand', 'production', 'costs', 'unit-cost']});
		}
	};

	/**
	 * Update group
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGroup = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update group */
		let groupId = this.props.userId;
		let db = firebase.firestore();
		let groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};

	/**
	 * Calculate area stats of current round
	 */
	handlecalculateRoundStats = () => {
		return new Promise((resolve) => {
			const scenarioId = this.state.game.scenarioId ? this.state.game.scenarioId : appConfig.defaultScenario;
			let roundStats = calculateRoundStats(scenarioId, this.state.group, this.state.group.roundId);
			this.setState({roundStats}, () => {
				resolve();
			});
		});
	}	

	/**
	 * Go to page
	 * @param {string} pageId
	 */
	handleGoToPage = (pageId) => {
		this.setState({pageId});
	};

	/**
	 * Track hovering over an area (overview page only)
	 * @param {string} hoverAreaId 
	 */
	handleHoverArea = (hoverAreaId) => {
		this.setState({hoverAreaId});
	}

	/**
	 * Hide / show intro popup
	 */
	handleToggleIntroPopup = () => {
		let showIntroPopup = !this.state.showIntroPopup;
		this.setState({showIntroPopup}, () => {
			/* Closing intro popup */
			if (!showIntroPopup) {
				// auto-scroll to top
				const introPopupDiv = document.getElementById('IntroPopupText');
				if (introPopupDiv) introPopupDiv.scrollTop = 0;

				// animate info popup icon
				if (this.state.group.infoPopupSeen !== true) this.setState({animateInfoIcon: true});

				// open report popup
				if (this.state.group.roundId === 5) {	
					this.timeout = setTimeout(() => {
						this.setState({showReportPopup: true, fireRoundIdAnimation: true});
					}, 1000);
				}
			}
		});
	};

	/**
	 * Hide / show info popup
	 */
	handleToggleInfoPopup = () => {
		let showInfoPopup = !this.state.showInfoPopup;
		this.setState({showInfoPopup, animateInfoIcon: false}, () => {
			/* Opening info popup -> flag as seen */
			if (showInfoPopup && this.state.group.infoPopupSeen !== true) {
				this.updateGroup({infoPopupSeen: true});
			}

			/* Closing info popup -> auto-scroll to top */
			if (!showInfoPopup) {
				const infoPopupDiv = document.getElementById('InfoPopupText');
				if (infoPopupDiv) infoPopupDiv.scrollTop = 0;
			}
		});
	}

	/**
	 * Hide / show report popup
	 */
	handleToggleReportPopup = () => {
		let showReportPopup = !this.state.showReportPopup;
		this.setState({showReportPopup}, () => {
			
			/* Closing report popup */ 	
			if (!showReportPopup) {
				/* flag as seen */
				if (this.state.group.reportPopupSeen !== true) this.updateGroup({reportPopupSeen: true});

				/* auto-show intro popup */
				if (this.state.showIntroPopupAfterReportPopup === true) {
					this.timeout = setTimeout(() => {
						this.setState({showIntroPopupAfterReportPopup: false, showIntroPopup: true});
					}, 1000);	
				}

				/* animate round id marker */
				if (this.state.fireRoundIdAnimation) {
					this.timeout = setTimeout(() => {
						this.setState({animateRoundId: true, fireRoundIdAnimation: false}, () => {
							this.setState({animateRoundId: false});
						});
					}, 1000);
				}
			}	
		});
	}

	/**
	 * Switch graph data on / off
	 * @param {string} dataId 
	 */
	handleToggleGraphData = (dataId) => {
		let graphDataIds = JSON.parse(JSON.stringify(this.state.graphDataIds));
		let dataIndex = graphDataIds.indexOf(dataId);
		if (dataIndex >= 0) {
			graphDataIds.splice(dataIndex, 1);
		} else {
			graphDataIds.push(dataId);
		}
		this.setState({graphDataIds});
	}

	/**
	 * Open / close action container
	 * @param {string} id 
	 */
	handleToggleActionContainer = (id) => {
		let openActionContainers = [...this.state.openActionContainers];
		let containerIndex = openActionContainers.indexOf(id);
		if (containerIndex >= 0) {
			/* Close container */
			openActionContainers.splice(containerIndex, 1);

			/* Close actions in container */
			let openActions = this.state.openActions.filter((action) => {
				return !action.includes(id);
			});
			this.setState({openActionContainers, openActions});
		} else {
			/* Open */
			openActionContainers.push(id);
			this.setState({openActionContainers});
		}
	}

	/**
	 * Open / close action
	 * @param {string} actionId 
	 */
	handleOpenCloseAction = (actionId) => {
		let openActions = [...this.state.openActions];
		let actionIndex = openActions.indexOf(actionId);
		if (actionIndex >= 0) {
			/* Close */
			openActions.splice(actionIndex, 1);
		} else {
			/* Open */
			openActions.push(actionId);
		}
		this.setState({openActions});
	}

	/**
	 * Select / deselect action
	 * @param {*} actionId 
	 * @param {*} parameterAdjustment 
	 */
	handleToggleAction = (actionId, parameterAdjustment = null) => {
		// Return if game is paused or last round is completed
		if (this.state.game.isPaused || this.state.group.isGameOver) return;

		// Get already selected actions
		let selectedActions = (this.state.group.selectedActions 
			? [...this.state.group.selectedActions]
			: []
		);

		let actionIndex = selectedActions.findIndex((action) => {return action.id === actionId;});
		let parameterId = null;
		if (actionIndex < 0) {
			// Max number of actions already selected
			if (selectedActions.length >= appConfig.actionsPerRound) return;

			// Check parameterAdjustment if required
			let actionData = actionsData.find((action) => {return action.id === actionId;});
			if (
				actionData && actionData.type === 'change-parameter' && 
				!(parameterAdjustment < 0 || parameterAdjustment > 0)
			) return;

			// Get parameter id
			if (actionData && actionData.type === 'change-parameter') {
				parameterId = actionData.parameterId;
			}
			
			// Select action
			selectedActions.push({id: actionId, parameterId, parameterAdjustment});
		} else {
			// Deselect action
			selectedActions.splice(actionIndex, 1);
		}

		this.updateGroup({selectedActions: selectedActions}).then(() => {
			this.handlecalculateRoundStats();
		});
	}

	/**
	 * Confirm selected actions
	 */
	handleConfirmSelectedActions = () => {
		// Return if game is paused or last round is completed
		if (this.state.game.isPaused || this.state.group.isGameOver) return;

		// Return if already calculating effects or if not enough actions are selected
		const selectedActions = this.state.group.selectedActions ? this.state.group.selectedActions : [];
		if (this.state.isCalculatingEffects || selectedActions.length < appConfig.actionsPerRound) return;

		// Calculate effects and fade out whole page to black
		this.setState({
			isCalculatingEffects: true,
			fadePageOut: true,
		}, () => {
			// Get round effects
			const scenarioId = this.state.game.scenarioId ? this.state.game.scenarioId : appConfig.defaultScenario;
			const groupUpdates = calculateRoundEffects(scenarioId, this.state.group, this.state.group.roundId);
			
			// Update group and start cascading animations related to round transition
			this.updateGroup(groupUpdates).then(() => {
				this.timeout = setTimeout(() => {
					// fade in on overview page, show illustration popup
					this.setState({
						isCalculatingEffects: false,
						showIllustrationPopup: true,
						pageId: 'overview',
					}, () => {
						this.timeout = setTimeout(() => {
							// close illustration popup
							this.setState({fadePageOut: false, showIllustrationPopup: false}, () => {
								if (groupUpdates.roundId === 5) {
									let roundData = roundsData.find((r) => {return r.id === groupUpdates.roundId;});
									if (roundData && this.state.game.phaseId >= roundData.phaseId) {
										// open intro popup
										this.timeout = setTimeout(() => {
											this.setState({showIntroPopup: true});
										}, 1000);
									}
								} else {
									// open report popup
									this.timeout = setTimeout(() => {
										this.setState({showReportPopup: true, fireRoundIdAnimation: true});
									}, 3000);
								}
							});
						}, appConfig.newRoundAnimationDuration);
					});
				}, 500);
			});
		});
	}

	/**
	 * Render component
	 */
	render() {
		/* Default page: overview */
		let PageComponent = Overview;
		let pageData = pagesData.find((page) => {return page.id === 'overview';});
		let currentRoundData = roundsData.find((round) => {return round.id === 1;});
		let unitCost = 0, showPauseOverlay = false, isGameOver = false;
		let languageId = this.props.languageId;
		let scenarioId = appConfig.defaultScenario;

		/* Game is loaded */
		if (!this.state.isLoading) {
			/* Get game language */
			languageId = this.state.game.languageId;

			/* Get game scenario */
			scenarioId = this.state.game.scenarioId;

			/* Get page data and component for page type */
			pageData = pagesData.find((page) => {return page.id === this.state.pageId;});
			if (pageData && pageData.type && this.pageTypeComponents.hasOwnProperty(pageData.type)) {
				PageComponent = this.pageTypeComponents[pageData.type].component;
			}			
			
			/* Unit cost */
			if (pageData && pageData.areaId) {
				/* Unit cost of area */
				unitCost = this.state.roundStats[pageData.areaId]['unitcost'];
			} else {
				/* Total unit cost */
				unitCost = getTotalUnitCost(this.state.roundStats);
			}

			/* Check if game is paused or last round has been played */
			currentRoundData = roundsData.find((round) => {return round.id === this.state.group.roundId;});
			const isPaused = (this.state.game.isPaused === true ? true : false);
			const isWaitingForFacilitator =  (currentRoundData.phaseId > this.state.game.phaseId ? true : false);
			showPauseOverlay = 
				(isPaused || isWaitingForFacilitator) && 
				(!this.state.fadePageOut && !this.state.isCalculatingEffects) ? true : false;
			isGameOver = (this.state.group.isGameOver === true ? true : false);			
		}

		return (
			<div className={'Page' + (this.state.fadePageOut ? ' Page--fadeOut' : '') }>
				{!this.state.isLoading && <PageComponent 
					isGameOver={isGameOver}
					isCalculatingEffects={this.state.isCalculatingEffects}
					animateInfoIcon={this.state.animateInfoIcon}
					popupIsOpen={this.state.showIntroPopup || this.state.showInfoPopup || this.state.showReportPopup}
					languageId={languageId}
					pageData={pageData}
					hoverAreaId={this.state.hoverAreaId}
					roundStats={this.state.roundStats}
					group={this.state.group}
					openActionContainers={this.state.openActionContainers}
					openActions={this.state.openActions}
					handleHoverArea={this.handleHoverArea}
					handleToggleActionContainer={this.handleToggleActionContainer}
					handleOpenCloseAction={this.handleOpenCloseAction}
					handleToggleAction={this.handleToggleAction}
					handleToggleInfoPopup={this.handleToggleInfoPopup}
					handleToggleReportPopup={this.handleToggleReportPopup}
					handleConfirmSelectedActions={this.handleConfirmSelectedActions}
					handleGoToPage={this.handleGoToPage}
					animateRoundIdProp={this.state.animateRoundId}
				/>}
				{!this.state.isLoading && <Navigation 
					languageId={languageId}
					pageId={this.state.pageId} 
					hoverAreaId={this.state.hoverAreaId}
					unitCost={unitCost} 
					handleGoToPage={this.handleGoToPage}
					handleHoverArea={this.handleHoverArea}
				/>}
				<Button
					classes={['cgl']}
					type="a"
					href="https://cphgamelab.dk"
					text=""
				/>
				<Button 
					classes={['logout']}
					text={getText(loginUiTexts.logout, languageId)} 
					onClick={this.props.handleLogout}
				/>

				{/* Game paused overlay */}
				{showPauseOverlay && <PauseOverlay languageId={languageId} />}

				{/* Intro popup */}
				<IntroPopup
					isLoading={this.state.isLoading}
					groupIsPlaying={this.state.groupIsPlaying}
					showIntroPopup={
						this.state.showIntroPopup && 
						!this.state.showIllustrationPopup && 
						!this.state.isCalculatingEffects &&
						!showPauseOverlay
					}
					languageId={languageId}
					group={this.state.group}
					handleToggleIntroPopup={this.handleToggleIntroPopup}
				/>

				{/* Illustration popup */}
				<IllustrationPopup
					isLoading={this.state.isLoading}
					showIllustrationPopup={this.state.showIllustrationPopup}
					languageId={languageId}
					roundId={this.state.group ? this.state.group.roundId : 2}
				/>

				{/* Info popup */}
				<InfoPopup
					isLoading={this.state.isLoading}
					showInfoPopup={this.state.showInfoPopup && !showPauseOverlay}
					languageId={languageId}
					roundId={this.state.group ? this.state.group.roundId : 1}
					handleToggleInfoPopup={this.handleToggleInfoPopup}
				/>

				{/* Report popup */}
				{!this.state.isLoading && <ReportPopup
					showReportPopup={this.state.showReportPopup && !showPauseOverlay}
					isGameOver={isGameOver}
					languageId={languageId}
					scenarioId={scenarioId}
					group={this.state.group}
					roundStats={this.state.roundStats}
					graphDataIds={this.state.graphDataIds}
					handleToggleGraphData={this.handleToggleGraphData}
					handleToggleReportPopup={this.handleToggleReportPopup} 
				/>}
			</div>
		
		);
	}
}

GroupController.propTypes = {
	userId: PropTypes.string.isRequired,
	languageId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default GroupController;
