import {generalUiTexts} from './ui-texts';

const panelsData = {
	// Overview page
	overviewPanel: [
		{title: generalUiTexts.costs, parameterId: 'total', class: 'title'},
		{parameterId: 'effective-capacity'},
		{areaId: 'formulation-and-stabilization', parameterId: 'prev-area-throughput'},
		{areaId: 'filling-and-inspection', parameterId: 'prev-area-throughput'},
		{parameterId: 'unitcost', class: 'footer'}
	],

	overviewPanelStorage: [
		{title: generalUiTexts.costs, parameterId: 'total', class: 'title'},
		{parameterId: 'inventory-primo'},
		{parameterId: 'production-no-actions', class: null},
		{parameterId: 'expected-demand', isRange: true, hideIfGameIsOver: true},
		{parameterId: 'unitcost', class: 'footer'}
	],

	// Costs
	costsPanel: {
		title: generalUiTexts.costs,
		subtitle: generalUiTexts.perRound,
		sections: [
			{
				id: 'materials',
				rows: [
					{parameterId: 'materials', class: 'title', tooltipId: 'material-costs'},
				]
			}, 
			{
				id: 'capacity',
				rows: [
					{parameterId: null, class: 'title', title: generalUiTexts.capacityCosts},
					{parameterId: 'operators', class: null, tooltipId: 'operator-costs'},
					{parameterId: 'production-support', class: null, tooltipId: 'support-costs'},
					{parameterId: 'expenses', class: null, tooltipId: 'running-expenses'}
				]
			},
			{
				id: 'machines',
				rows: [{parameterId: 'machines', class: 'title', tooltipId: 'machine-write-offs'},]
			},
			{
				id: 'total',
				rows: [{parameterId: 'total', title: generalUiTexts.total, unit: generalUiTexts.nb},]
			}
		]
	},

	// Costs (storage)
	costsPanelStorage: {
		title: generalUiTexts.costs,
		subtitle: generalUiTexts.perRound,
		sections: [
			{
				id: 'capacity',
				rows: [
					{parameterId: null, class: 'title', title: generalUiTexts.capacityCosts},
					{parameterId: 'office-staff', class: null, tooltipId: 'office-staff'},
					{parameterId: 'storage-staff', class: null, tooltipId: 'storage-staff'},
					{parameterId: 'expenses', class: null, tooltipId: 'running-expenses2'},
				]
			},
			{
				id: 'machines',
				rows: [{parameterId: 'machines', class: 'title', tooltipId: 'machine-write-offs'}]
			},
			{
				id: 'total',
				rows: [
					{parameterId: 'total', title: generalUiTexts.total, unit: generalUiTexts.nb},
				]
			}
		]
	},

	// Production
	productionPanel: {
		title: generalUiTexts.production,
		subtitle: generalUiTexts.perRound,
		class: 'production',
		sections: [
			{
				id: 'production',
				rows: [
					{parameterId: 'equipment-capacity', class: null, tooltipId: 'equipment-capacity'},
					{parameterId: 'operator-teams', class: null, tooltipId: 'operator-teams'},
					{parameterId: 'support-teams', class: null, tooltipId: 'production-teams'},
					{parameterId: 'effective-capacity', class: null, tooltipId: 'effective-capacity'},
					{areaId: 'formulation-and-stabilization', parameterId: 'prev-area-throughput', tooltipId: 'prev-area-throughput'},
					{areaId: 'filling-and-inspection', parameterId: 'prev-area-throughput', tooltipId: 'prev-area-throughput'},
					{parameterId: 'waste', class: null, tooltipId: 'waste'},
				]
			},
			{
				id: 'throughput',
				rows: [
					{parameterId: 'throughput', unit: 'units', tooltipId: 'throughput-units'},
				]
			}
		]
	},

	// Balance (storage)
	balancePanel: {
		title: generalUiTexts.inventoryBalance,
		subtitle: generalUiTexts.perRound,
		class: 'balance',
		sections: [
			{
				id: 'capacity',
				rows: [
					{parameterId: 'inventory-primo', class: null, tooltipId: 'inventory-primo'},
					{parameterId: 'waste', class: null, tooltipId: 'waste-percent'},
					{parameterId: 'production-no-actions', class: null, tooltipId: 'production'},
					{parameterId: 'expected-demand', class: null, isRange: true, hideIfGameIsOver: true, tooltipId: 'expected-demand'},
				]
			},
			{
				id: 'throughput',
				hideIfGameIsOver: true,
				rows: [
					{parameterId: 'expected-inventory-ultimo', unit: 'units', isRange: true, showFullNumbers: true, hideIfGameIsOver: true, tooltipId: 'expected-inventory-ultimo'},
				]				
			}
		]
	}


};

export {panelsData};