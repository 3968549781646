import appConfig from 'config/app.config';
import {roundsData} from 'data/rounds-data';
import {initialData} from 'data/initial-data';
import {calculateRoundStats} from './game-helper';

const getGroupColumns = () => {
	const groupColumns = [
		{value: 'groupId', label: 'Gruppe'},
		{value: 'roundId', label: 'Tur'},
		{value: 'action1', label: 'Handling 1'},
		{value: 'action2', label: 'Handling 2'},
		{value: 'action3', label: 'Handling 3'},
		{value: 'workenv', label: 'Work environment'},
		{value: 'production', label: 'Total production'},
		{value: 'productionX', label: 'Indkøbte units'},
		{value: 'cost', label: 'Total cost'},
		{value: 'inventoryPrimo', label: 'Inventory Primo'},
		{value: 'inventoryUltimo', label: 'Inventory Ultimo'},
		{value: 'unitCostInit', label: 'UnitCost initial'},
		{value: 'unitcost', label: 'UnitCost'},
		{value: 'gameScore', label: 'Unit Cost Score'},
		
		{value: 'washAndSterilization', label: 'Vask & Sterilisation'},
		{value: 'materialUCost1', label: 'Råvarer enhedspris'},
		{value: 'materialCost1', label: 'Råvarer totalpris'},
		{value: 'operatorTeams1', label: 'Operatørskiftehold (antal)'},
		{value: 'operatorCost1', label: 'Operatørskiftehold (cost)'},
		{value: 'operatorEfficiency1', label: 'Operatøreffektivitet'},
		{value: 'productionTeams1', label: 'Prod.support-skiftehold (antal)'},
		{value: 'productionCost1', label: 'Prod.support-skiftehold (cost)'},
		{value: 'productionEfficiency1', label: 'Prod.support-effektivitet'},
		{value: 'machineLevel1', label: 'Maskine niveau'},
		{value: 'machineCapacity1', label: 'Maskine kapacitet'},
		{value: 'machineRampUp1', label: 'Maskine ramp up'},
		{value: 'maschineEffCapacity1', label: 'Maskine eff. kapacitet'},
		{value: 'machineWriteOffs1', label: 'Maskinafskrivninger'},
		{value: 'otherExpenses1', label: 'Løbende udgifter'},
		{value: 'waste1', label: 'Spild'},
		{value: 'throughput1', label: 'Throughput'},
		{value: 'totalCost1', label: 'Samlede omkostninger'},
		{value: 'unitCost1', label: 'UnitCost'},

		{value: 'formulationAndStabilization', label: 'Formulering & Stablisering'},
		{value: 'materialUCost2', label: 'Råvarer enhedspris'},
		{value: 'materialCost2', label: 'Råvarer totalpris'},
		{value: 'operatorTeams2', label: 'Operatørskiftehold (antal)'},
		{value: 'operatorCost2', label: 'Operatørskiftehold (cost)'},
		{value: 'operatorEfficiency2', label: 'Operatøreffektivitet'},
		{value: 'productionTeams2', label: 'Prod.support-skiftehold (antal)'},
		{value: 'productionCost2', label: 'Prod.support-skiftehold (cost)'},
		{value: 'productionEfficiency2', label: 'Prod.support-effektivitet'},
		{value: 'machineLevel2', label: 'Maskine niveau'},
		{value: 'machineCapacity2', label: 'Maskine kapacitet'},
		{value: 'machineRampUp2', label: 'Maskine ramp up'},
		{value: 'maschineEffCapacity2', label: 'Maskine eff. kapacitet'},
		{value: 'machineWriteOffs2', label: 'Maskinafskrivninger'},
		{value: 'otherExpenses2', label: 'Løbende udgifter'},
		{value: 'waste2', label: 'Spild'},
		{value: 'throughput2', label: 'Throughput'},
		{value: 'totalCost2', label: 'Samlede omkostninger'},
		{value: 'unitCost2', label: 'UnitCost'},

		{value: 'fillingAndInspection', label: 'Fyldning & Inspektion'},
		{value: 'materialUCost3', label: 'Råvarer enhedspris'},
		{value: 'materialCost3', label: 'Råvarer totalpris'},
		{value: 'operatorTeams3', label: 'Operatørskiftehold (antal)'},
		{value: 'operatorCost3', label: 'Operatørskiftehold (cost)'},
		{value: 'operatorEfficiency3', label: 'Operatøreffektivitet'},
		{value: 'productionTeams3', label: 'Prod.support-skiftehold (antal)'},
		{value: 'productionCost3', label: 'Prod.support-skiftehold (cost)'},
		{value: 'productionEfficiency3', label: 'Prod.support-effektivitet'},
		{value: 'machineLevel3', label: 'Maskine niveau'},
		{value: 'machineCapacity3', label: 'Maskine kapacitet'},
		{value: 'machineRampUp3', label: 'Maskine ramp up'},
		{value: 'maschineEffCapacity3', label: 'Maskine eff. kapacitet'},
		{value: 'machineWriteOffs3', label: 'Maskinafskrivninger'},
		{value: 'otherExpenses3', label: 'Løbende udgifter'},
		{value: 'waste3', label: 'Spild'},
		{value: 'throughput3', label: 'Throughput'},
		{value: 'totalCost3', label: 'Samlede omkostninger'},
		{value: 'unitCost3', label: 'UnitCost'},

		{value: 'officeAndStorage', label: 'Kontor & Lager'},
		{value: 'officeTeams4', label: 'Kontorskiftehold (antal)'},
		{value: 'officeCost4', label: 'Kontorskiftehold (cost)'},
		{value: 'storageTeams4', label: 'Lagerskiftehold (antal)'},
		{value: 'storageCost4', label: 'Lagerskiftehold (cost)'},
		{value: 'machineWriteOffs4', label: 'Maskinafskrivninger'},
		{value: 'otherExpenses4', label: 'Løbende udgifter'},
		{value: 'waste4', label: 'Spild'},
		{value: 'throughput4', label: 'Throughput'},
		{value: 'totalCost4', label: 'Samlede omkostninger'},
		{value: 'unitCost4', label: 'UnitCost'},
	];
	return groupColumns;
};

const getSheetTemplate = () => {
	const sheetTemplate = {
		id: 'data', 
		name: 'UnitCost', 
		columns: getGroupColumns(), 
		data: []
	};
	return sheetTemplate;
};

const getDataSheet = (gameData, groups) => {
	let groupSheet = getSheetTemplate();

	groups.forEach((group) => {
		// Round 0
		groupSheet.data.push({
			groupId: group.title,
			roundId: 0,
			action1: '-',
			action2: '-',
			action3: '-',
			workenv: appConfig.initialWorkEnvironment,
			production: initialData.production[gameData.scenarioId],
			productionX: 0,
			inventoryPrimo: '-',
			inventoryUltimo: initialData.inventory[gameData.scenarioId],
			cost: initialData.cost[gameData.scenarioId],
			unitcost: (initialData.cost[gameData.scenarioId] / initialData.production[gameData.scenarioId]),
			gameScore: '-'
		});

		// Round 1+
		roundsData.forEach((roundData) => {
			let groupRoundData = group.rounds.find((round) => {return round.id === roundData.id;});
			if (
				groupRoundData && 
				groupRoundData.results && 
				groupRoundData.selectedActions &&
				groupRoundData.selectedActions.length === appConfig.actionsPerRound
			) {
				let roundStats = calculateRoundStats(gameData.scenarioId, group, roundData.id);

				let rampUpEffect1 = null, rampUpEffect2 = null, rampUpEffect3 = null;
				group.upgrades.forEach((upgrade) => {
					if (
						(!upgrade.hasOwnProperty('rounds') || 
						(upgrade.rounds[0] <= roundData.id && roundData.id <= upgrade.rounds[1])) &&
						upgrade.type === 'machine-capacity-modifier'
					) {
						if (upgrade.areaId === 'wash-and-sterilization') rampUpEffect1 = upgrade.value;
						if (upgrade.areaId === 'formulation-and-stabilization') rampUpEffect2 = upgrade.value;
						if (upgrade.areaId === 'filling-and-inspection') rampUpEffect3 = upgrade.value;
					}
				});

				groupSheet.data.push({
					groupId: group.title,
					roundId: roundData.id,
					action1: groupRoundData.selectedActions[0].id,
					action2: groupRoundData.selectedActions[1].id,
					action3: groupRoundData.selectedActions[2].id,
					workenv: groupRoundData.workEnvironment,
					cost: groupRoundData.results.cost,
					production: groupRoundData.results.production,
					productionX: groupRoundData.results.extraProduction,
					inventoryPrimo: groupRoundData.results.inventoryPrimo,
					inventoryMid: groupRoundData.results.inventory,
					inventoryUltimo: groupRoundData.results.inventoryUltimo,
					demand: roundData.demand,
					unitCostInit: groupRoundData.results.originalUnitCost,
					unitcost: groupRoundData.results.unitCost,
					gameScore: groupRoundData.results.gameScore,

					materialUCost1: roundStats['wash-and-sterilization']['material-cost-per-unit'],
					materialCost1: roundStats['wash-and-sterilization']['materials'],
					operatorTeams1: roundStats['wash-and-sterilization']['operator-teams'],
					operatorCost1: roundStats['wash-and-sterilization']['operators'],
					operatorEfficiency1: roundStats['wash-and-sterilization']['operator-efficiency'],
					productionTeams1: roundStats['wash-and-sterilization']['support-teams'],
					productionCost1: roundStats['wash-and-sterilization']['production-support'],
					productionEfficiency1: roundStats['wash-and-sterilization']['support-efficiency'],
					machineLevel1: roundStats['wash-and-sterilization']['machine-level'],
					machineCapacity1: roundStats['wash-and-sterilization']['equipment-capacity'],
					machineRampUp1: rampUpEffect1,
					maschineEffCapacity1: roundStats['wash-and-sterilization']['effective-capacity'],
					machineWriteOffs1: roundStats['wash-and-sterilization']['machines'],
					otherExpenses1: roundStats['wash-and-sterilization']['expenses'],
					waste1: roundStats['wash-and-sterilization']['waste'],
					throughput1: roundStats['wash-and-sterilization']['throughput'],
					totalCost1: roundStats['wash-and-sterilization']['total'],
					unitCost1: roundStats['wash-and-sterilization']['unitcost'],

					materialUCost2: roundStats['formulation-and-stabilization']['material-cost-per-unit'],
					materialCost2: roundStats['formulation-and-stabilization']['materials'],
					operatorTeams2: roundStats['formulation-and-stabilization']['operator-teams'],
					operatorCost2: roundStats['formulation-and-stabilization']['operators'],
					operatorEfficiency2: roundStats['formulation-and-stabilization']['operator-efficiency'],
					productionTeams2: roundStats['formulation-and-stabilization']['support-teams'],
					productionCost2: roundStats['formulation-and-stabilization']['production-support'],
					productionEfficiency2: roundStats['formulation-and-stabilization']['support-efficiency'],
					machineLevel2: roundStats['formulation-and-stabilization']['machine-level'],
					machineCapacity2: roundStats['formulation-and-stabilization']['equipment-capacity'],
					machineRampUp2: rampUpEffect2,
					maschineEffCapacity2: roundStats['formulation-and-stabilization']['effective-capacity'],
					machineWriteOffs2: roundStats['formulation-and-stabilization']['machines'],
					otherExpenses2: roundStats['formulation-and-stabilization']['expenses'],
					waste2: roundStats['formulation-and-stabilization']['waste'],
					throughput2: roundStats['formulation-and-stabilization']['throughput'],
					totalCost2: roundStats['formulation-and-stabilization']['total'],
					unitCost2: roundStats['formulation-and-stabilization']['unitcost'],

					materialUCost3: roundStats['filling-and-inspection']['material-cost-per-unit'],
					materialCost3: roundStats['filling-and-inspection']['materials'],
					operatorTeams3: roundStats['filling-and-inspection']['operator-teams'],
					operatorCost3: roundStats['filling-and-inspection']['operators'],
					operatorEfficiency3: roundStats['filling-and-inspection']['operator-efficiency'],
					productionTeams3: roundStats['filling-and-inspection']['support-teams'],
					productionCost3: roundStats['filling-and-inspection']['production-support'],
					productionEfficiency3: roundStats['filling-and-inspection']['support-efficiency'],
					machineLevel3: roundStats['filling-and-inspection']['machine-level'],
					machineCapacity3: roundStats['filling-and-inspection']['equipment-capacity'],
					machineRampUp3: rampUpEffect3,
					maschineEffCapacity3: roundStats['filling-and-inspection']['effective-capacity'],
					machineWriteOffs3: roundStats['filling-and-inspection']['machines'],
					otherExpenses3: roundStats['filling-and-inspection']['expenses'],
					waste3: roundStats['filling-and-inspection']['waste'],
					throughput3: roundStats['filling-and-inspection']['throughput'],
					totalCost3: roundStats['filling-and-inspection']['total'],
					unitCost3: roundStats['filling-and-inspection']['unitcost'],

					officeTeams4: roundStats['office-and-storage']['office-teams'],
					officeCost4: roundStats['office-and-storage']['office-staff'],
					storageTeams4: roundStats['office-and-storage']['storage-teams'],
					storageCost4: roundStats['office-and-storage']['storage-staff'],
					machineWriteOffs4: roundStats['office-and-storage']['machines'],
					otherExpenses4: roundStats['office-and-storage']['expenses'],
					waste4: roundStats['office-and-storage']['waste'],
					throughput4: roundStats['office-and-storage']['throughput'],
					totalCost4: roundStats['office-and-storage']['total'],
					unitCost4: roundStats['office-and-storage']['unitcost'],
				});
			}
		});
	});

	return groupSheet;
};


export {
	getSheetTemplate,
	getDataSheet
};