import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {initialData} from 'data/initial-data';
import {popupsData} from 'data/popups-game-data';
import {roundsData} from 'data/rounds-data';
import {tendencyData} from 'data/tendency-data';
import {areasData} from 'data/areas-data';
import {workEnvironmentData} from 'data/work-environment-data';
import {renderMarkdown} from 'helpers/text-helper';
import {replaceDynamicContent, getTextSnippets} from 'helpers/report-helper';
import {formatNumber} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Graph from 'components/graph/graph';
import './report-popup.scss';
import { generalUiTexts } from 'data/ui-texts';

const ReportPopup = (props) => {
	const {
		showReportPopup, 
		languageId,
		scenarioId,
		group, 
		roundStats, 
		graphDataIds, 
		handleToggleGraphData, 
		handleToggleReportPopup
	} = props;

	// Scroll to top when closing popup
	useEffect(() => {
		if (!showReportPopup) {
			const reportTextDiv = document.getElementById('ReportPopupText');
			if (reportTextDiv) reportTextDiv.scrollTop = 0;
		}
	}, [showReportPopup]);	

	// Texts
	let reportPopupTextClass = 'ReportPopup-text';
	let prevGroupRound = null;
	let dynamicText = null;
	let internalReport = '';
	let prevRoundUnitCost = null;
	let prevRoundExtraUnits = null;
	let expectations = null;
	let currentRoundIndex = roundsData.findIndex((round) => {return group.roundId === round.id;});
	if (currentRoundIndex >= 0) {
		// Default texts 
		const currentRoundData = roundsData[currentRoundIndex];
		internalReport = JSON.parse(JSON.stringify(getText(currentRoundData.report.internal, languageId)));
		if (currentRoundData.report.expectations) {
			expectations = JSON.parse(JSON.stringify(getText(currentRoundData.report.expectations, languageId)));
		}
		
		if (currentRoundIndex === 0) {
			internalReport = internalReport.replace(/%production%/g, 
				formatNumber(languageId, initialData.production[scenarioId]));
			internalReport = internalReport.replace(/%unit-cost%/g, 
				formatNumber(languageId, (initialData.cost[scenarioId] / initialData.production[scenarioId]), 1));
			internalReport = internalReport.replace(/%inventory%/g, 
				formatNumber(languageId, initialData.inventory[scenarioId]));

			let expectedDemand = roundsData[0]['expected-demand'][scenarioId];
			expectations = expectations.replace(/%expected-demand%/g, 
				formatNumber(languageId, expectedDemand, 0, true));

		} else {
			const prevRoundData = roundsData[currentRoundIndex - 1];
			prevGroupRound = group.rounds.find((round) => {return round.id === prevRoundData.id;});
			if (prevGroupRound && prevGroupRound.results) {
				// Dynamic text snippets (previous round)
				let textSnippetsArray = 
					getTextSnippets(languageId, scenarioId, group.roundId, group.rounds, roundStats);
				if (textSnippetsArray && textSnippetsArray.length > 0) {
					dynamicText = '';
					textSnippetsArray.forEach((snippet) => {
						dynamicText = dynamicText + '<p>' + snippet + '</p>';
					});
				}

				// Internal report (previous round)
				if (prevGroupRound.results.extraProduction > 0) {
					reportPopupTextClass = reportPopupTextClass + ' demandNotMet';
				}
				internalReport = replaceDynamicContent(
					languageId, 
					scenarioId,
					internalReport, 
					prevGroupRound.results, 
					null, 
					null, 
					null
				);

				// Prev round final unit cost
				if (prevGroupRound.results.unitCost) {
					prevRoundUnitCost = JSON.parse(JSON.stringify(getText(generalUiTexts.roundUnitCost, languageId)));
					prevRoundUnitCost = prevRoundUnitCost.replace(
						/%unitcost%/g, formatNumber(languageId, prevGroupRound.results.unitCost, 1, false)
					);
				}

				// Prev round bought units 
				if (prevGroupRound.results.extraProduction > 0) {
					prevRoundExtraUnits = 
						JSON.parse(JSON.stringify(getText(generalUiTexts.boughtUnitsInfo, languageId)));
					prevRoundExtraUnits = prevRoundExtraUnits.replace(/%original-unitcost%/g, 
						formatNumber(languageId, prevGroupRound.results.originalUnitCost, 1, false)
					);
				}
				
				// Expectations (current round)
				if (expectations) {
					let wasteInfo = 
						'<table><thead><tr>' + 
						'<td>' + getText(generalUiTexts.department, languageId) + '</td>' + 
						'<td>' + getText(generalUiTexts.waste, languageId) + '</td>' +
						'</tr></thead><tbody>';
					areasData.forEach((area) => {
						wasteInfo += '<tr><td>' + getText(area.title, languageId) + '</td><td>' + 
					formatNumber(languageId, roundStats[area.id]['waste'], 2, false) + '%</td></tr>';
					});
					wasteInfo += '</tbody></table>';
					expectations = expectations.replace(/%waste-info%/g, wasteInfo);

					let expectedDemand = formatNumber(
						languageId, roundStats['office-and-storage']['expected-demand'], 0, true
					);
					expectations = expectations.replace(/%expected-demand%/g, expectedDemand);

					let workEnvironment = roundStats['work-environment'];
					let weData = workEnvironmentData.find((we) => {
						return workEnvironment >= we.minVal && workEnvironment <= we.maxVal;
					});
					if (weData) {
						expectations = 
							expectations.replace(/%workEnvironmentTitle%/g, getText(weData.title, languageId));
						expectations = 
							expectations.replace(/%workEnvironmentBonus%/g, getText(weData.bonusText, languageId));
					}

					if (group.upgrades.some((upgrade) => {return upgrade.type === 'gmp-demands-implemented';})) {
						reportPopupTextClass = reportPopupTextClass + ' GMPActionTaken';
					} else {
						reportPopupTextClass = reportPopupTextClass + ' GMPActionNotTaken';
					}

					let oldMachineUpgrade = group.upgrades.find((upgrade) => {
						return (upgrade.type === 'support-efficiency-modifier' && 
							upgrade.modifierType === 'old-machine'
						);
					});
					if (oldMachineUpgrade) {
						let areaId = oldMachineUpgrade.areaId;
						let areaData = areasData.find((area) => {return area.id === areaId;});
						if (areaData) {
							expectations = 
								expectations.replace(/%oldest-machine-area-title%/g, 
									getText(areaData.title, languageId));
							reportPopupTextClass = reportPopupTextClass + ' oldMachines';	
						} else {
							reportPopupTextClass = reportPopupTextClass + ' noOldMachines';	
						}
					} else {
						reportPopupTextClass = reportPopupTextClass + ' noOldMachines';
					}

					if (prevGroupRound.results && prevGroupRound.results.gameScore) {
						let gameScore = formatNumber(languageId, prevGroupRound.results.gameScore, 2, false);
						expectations = expectations.replace(/%gameScore%/g, gameScore);
					}
				}
			}
		}
	}

	// Initial data
	let data = [
		{id: 'production', values: [{round: 0, value: initialData.production[scenarioId]}]},	
		{id: 'costs', values: [{round: 0, value: initialData.cost[scenarioId]}]},
		{
			id: 'unitcost', 
			values: [{round: 0, value: (initialData.cost[scenarioId] / initialData.production[scenarioId])}]
		},
		{
			id: 'unitcost2', 
			values: [{round: 0, value: (initialData.cost[scenarioId] / initialData.production[scenarioId])}]
		},
		{
			id: 'demand', 
			values: [{round: 0, value: initialData.demand[scenarioId]}]
		},
		{
			id: 'demand2', 
			values: [{round: 0, value: initialData.demand[scenarioId], value2: initialData.demand[scenarioId]}]
		},
	];

	// Track max val on y axis
	let yMax1 = initialData.cost[scenarioId] / initialData.production[scenarioId];
	let yMax2 = initialData.cost[scenarioId];

	// Production, costs & unitcost 
	group.rounds.forEach((round) => {
		if (round.results) {
			data[0].values.push({round: round.id, value: round.results.production});
			data[1].values.push({round: round.id, value: round.results.cost});
			data[2].values.push({round: round.id, value: round.results.unitCost});
			data[3].values.push({round: round.id, value: round.results.originalUnitCost});
			if (yMax2 < round.results.production) yMax2 = round.results.production;
			if (yMax2 < round.results.cost) yMax2 = round.results.cost;
			if (yMax1 < round.results.unitCost) yMax1 = round.results.unitCost;
			if (yMax1 < round.results.originalUnitCost) yMax1 = round.results.originalUnitCost;
		} 
	});

	// Demand
	let indexOfCurrentRound = roundsData.findIndex((round) => {return round.id === group.roundId;});
	let reducedDemandUncertainty = group.upgrades.some((upgrade) => {
		return (
			(
				!upgrade.hasOwnProperty('rounds') || 
				(upgrade.rounds[0] <= group.roundId && group.roundId <= upgrade.rounds[1])
			) && upgrade.type === 'reduce-demand-uncertainty'
		);
	});

	let indexOfMaxFutureRound = Math.min(indexOfCurrentRound + appConfig.graphFutureRoundsMax, tendencyData.length - 1);
	let tendencyNumber = tendencyData[indexOfMaxFutureRound][scenarioId];
	
	let expectedDemandAvr = (
		roundsData[indexOfCurrentRound]['expected-demand'][scenarioId][1] + 
		roundsData[indexOfCurrentRound]['expected-demand'][scenarioId][0]
	) / 2.;
	let currentRoundSpan = roundsData[indexOfCurrentRound]['expected-demand'][scenarioId][1] - 
		roundsData[indexOfCurrentRound]['expected-demand'][scenarioId][0];
	let lastVisibleRoundSpan = (reducedDemandUncertainty ? appConfig.futureRoundSpan / 2. : appConfig.futureRoundSpan);

	roundsData.forEach((round, index) => {
		if (round.hide === true) return;
		// Only show up to 3 future rounds 
		if (index > indexOfCurrentRound + appConfig.graphFutureRoundsMax) return;

		// Past round
		if (index < indexOfCurrentRound) {
			// Line plot
			data[4].values.push({round: round.id, value: round['actual-demand'][scenarioId]});

			// Threshold plot
			data[5].values.push({
				round: round.id, 
				value: round['actual-demand'][scenarioId], 
				value2: round['actual-demand'][scenarioId]
			});
		}
		if (index === indexOfCurrentRound - 1) {
			// Threshold plot
			data[5].values.push({
				round: round.id, 
				value: round['actual-demand'][scenarioId], 
				value2: round['actual-demand'][scenarioId]
			});
		}
		// Current round
		if (index === indexOfCurrentRound) {
			// Threshold plot
			if (reducedDemandUncertainty) {
				data[4].values.push({
					round: round.id, 
					value: round['actual-demand'][scenarioId]
				});
				data[5].values.push({
					round: round.id, 
					value: round['actual-demand'][scenarioId], 
					value2: round['actual-demand'][scenarioId]
				});
			} else {
				data[5].values.push(
					{
						round: round.id, 
						value: round['expected-demand'][scenarioId][0], 
						value2: round['expected-demand'][scenarioId][1]
					}
				);
			}
		}
		// Future round
		if (index > indexOfCurrentRound) {
			let value = expectedDemandAvr + (tendencyNumber - expectedDemandAvr);
			// let value = ((tendencyNumber - expectedDemandAvr) / appConfig.graphFutureRoundsMax) * 
			// 	(index - indexOfCurrentRound) + expectedDemandAvr;

			let value1 = value - (lastVisibleRoundSpan / 2.);
			let value2 = value + (lastVisibleRoundSpan / 2.);

			if (index === indexOfCurrentRound + 1) {
				value = expectedDemandAvr + (tendencyNumber - expectedDemandAvr) * 0.1;
				value1 = value - (currentRoundSpan + ((lastVisibleRoundSpan - currentRoundSpan) * 0.2)) / 2;
				value2 = value + (currentRoundSpan + ((lastVisibleRoundSpan - currentRoundSpan) * 0.2)) / 2;
			}
			if (index === indexOfCurrentRound + 2) {
				value = expectedDemandAvr + (tendencyNumber - expectedDemandAvr) * 0.45;
				value1 = value - (currentRoundSpan + ((lastVisibleRoundSpan - currentRoundSpan) * 0.5)) / 2;
				value2 = value + (currentRoundSpan + ((lastVisibleRoundSpan - currentRoundSpan) * 0.5)) / 2;
			}

			data[5].values.push({round: round.id, value: value1, value2: value2});

			if (yMax2 < value1) yMax2 = value1;
			if (yMax2 < value2) yMax2 = value2;
		}
	});
	
	return (
		<div className={'ReportPopup' + (showReportPopup ? ' ReportPopup--show' : '')}
			onClick={() => {handleToggleReportPopup();}} >
			<div className="ReportPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="ReportPopup-header">
					<div onClick={() => {handleToggleReportPopup();}} className="ReportPopup-closeBtn" />
					<div className="ReportPopup-title">{getText(popupsData.report.title, languageId)}</div>
				</div>
				<div className="ReportPopup-body">
					{/* Text */}
					<div id="ReportPopupText" className={reportPopupTextClass}>
						{/* Internal report */}
						<strong>{getText(popupsData.report.section1.title, languageId)}</strong><br />
						{renderMarkdown(internalReport)}	
						{dynamicText ? renderMarkdown(dynamicText) : null}
						{prevRoundUnitCost && <p><strong>{prevRoundUnitCost}</strong></p>}
						{prevRoundExtraUnits && <p>{prevRoundExtraUnits}</p>}
						{/* Expectations */}
						{expectations && 
							<div><br /><strong>{(group.roundId < 9 
								? getText(popupsData.report.section2.title, languageId)
								: getText(popupsData.report.section2.title2, languageId)
							)}</strong><br /></div>
						}
						{expectations && renderMarkdown(expectations)}
					</div>
					{/* Graph */}
					<div className="ReportPopup-graph">
						<ParentSize>{({ width, height }) => {
							return (
								<Graph 
									showDemand={(graphDataIds.indexOf('demand') >= 0)}
									showProduction={(graphDataIds.indexOf('production') >= 0)}
									showCost={(graphDataIds.indexOf('costs') >= 0)}
									showUnitCost={(graphDataIds.indexOf('unit-cost') >= 0)}
									width={width} 
									height={height}
									yMaxVal1={parseFloat(yMax1)}
									yMaxVal2={parseFloat(yMax2)}
									languageId={languageId}
									data={data}
								/>
							);
						}}</ParentSize>
					</div>
					{/* Labels */}
					<div className="ReportPopup-buttons">
						{popupsData.report.labels.map((label, index) => {
							if (!label.title) return null;
							let isOff = (graphDataIds.indexOf(label.id) < 0);
							return (
								<div 
									key={index} 
									className={'ReportPopup-button ' + label.id + (isOff ? ' off' : '')}
									onClick={() => {handleToggleGraphData(label.id);}}
								>
									<span>{getText(label.title, languageId)}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

ReportPopup.propTypes = {
	showReportPopup: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	scenarioId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	roundStats: PropTypes.object.isRequired,
	graphDataIds: PropTypes.array.isRequired,
	handleToggleGraphData: PropTypes.func.isRequired,
	handleToggleReportPopup: PropTypes.func.isRequired,
};

export default ReportPopup;
