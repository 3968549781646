import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {areasData} from 'data/areas-data';
import {parametersData} from 'data/parameters-data'; 
import {formatNumber} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import './change-parameter.scss';

const ChangeParameter = (props) => {
	let {
		isOpen,
		isDisabled,
		isConfirmed,
		languageId,
		actionData, 
		areaStats, 
		group, 
		handleToggleAction,
	} = props;

	// Parameter data and value 
	let parameterData = parametersData[actionData.parameterId];
	let parameterValue = areaStats[actionData.parameterId];

	// Cost per unit
	let costPerUnit = '???';
	let areaData = areasData.find((area) => {return area.id === actionData.areaId;});
	if (parameterData.costPerUnitProperty && areaData.hasOwnProperty(parameterData.costPerUnitProperty)) {
		costPerUnit = areaData[parameterData.costPerUnitProperty];
	}

	// Increase / decrease parameter value
	let [parameterAdjustment, setParameterAdjustment] = useState(0);
	const adjustParameter = (value) => {
		// if (isConfirmed) return;
		if (value > 0) {
			// Increase value
			if (
				parameterAdjustment < 1 && 
				parameterData.maxVal && 
				(parameterValue + parameterAdjustment) < parameterData.maxVal
			) {
				let adjustment = parameterAdjustment + 1;
				setParameterAdjustment(adjustment);
				handleToggleAction(actionData.id, adjustment);
			} else {
				console.log('max value reached');
			}
		} else {
			// Decrease value
			if (
				parameterAdjustment > -1 && 
				parameterData.minVal && 
				(parameterValue + parameterAdjustment) > parameterData.minVal
			) {
				let adjustment = parameterAdjustment - 1;
				setParameterAdjustment(adjustment);
				handleToggleAction(actionData.id, adjustment);
			} else {
				console.log('min value reached');
			}
		}
	};

	// Check if action is confirmed, if so replace parameter adjustment with database value
	useEffect(() => {
		if (isConfirmed) {
			let selectedActionData = group.selectedActions.find((action) => {return action.id === actionData.id;});
			setParameterAdjustment(selectedActionData.parameterAdjustment);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConfirmed]);

	// If action is closed and not confirmed, reset value
	useEffect(() => {
		if (!isOpen && !isConfirmed) {
			setParameterAdjustment(0);
		}

	}, [isOpen, isConfirmed]);

	let canIncreaseValue = (
		!isDisabled &&
		parameterAdjustment < 1 && 
		(parameterValue + parameterAdjustment) < parameterData.maxVal
	);
	let canDecreaseValue = (
		!isDisabled &&
		parameterAdjustment > -1 && 
		(parameterValue + parameterAdjustment) > parameterData.minVal
	);
	
	return (
		<div className={'ChangeParameter' + (isConfirmed ? ' confirmed' : '')}>
			<div className="ChangeParameter-costPerUnit">
				{costPerUnit && <span>
					{formatNumber(languageId, costPerUnit, 0, false)} {getText(generalUiTexts.nb, languageId)}
				</span>}
			</div>
			<div className="ChangeParameter-controls">
				<div 
					className={'ChangeParameter-button decrease' + (!canDecreaseValue ? ' disabled' : '')} 
					onClick={() => {adjustParameter(-1);}}
				/>
				<div className="ChangeParameter-value"><span>{parameterValue + parameterAdjustment}</span></div>
				<div 
					className={'ChangeParameter-button increase' + (!canIncreaseValue ? ' disabled' : '')} 
					onClick={() => {adjustParameter(1);}} 
				/>
			</div>
		</div>
	);
};

ChangeParameter.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isConfirmed: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	actionData: PropTypes.object.isRequired,
	areaStats: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleToggleAction: PropTypes.func.isRequired
};

export default ChangeParameter;
