import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';

const CreateUser = (props) => {
	const {
		isLoading,
		userCreated,
		languageId,
		email,
		password,
		passwordRepeat,
		feedback,
		handleInput,
		handleCreateUser,
		setFacilitatorBoxType
	} = props;

	if (userCreated) {
		return (
			<div className="LoginFacilitator">
				<div className="Login-info">{getText(loginUiTexts.createUserFeedback, languageId)}</div>
				<div 
					className="Login-resetPasswordBtn"
					onClick={()=>{setFacilitatorBoxType('login');}}
				>{getText(loginUiTexts.goToLoginBtn, languageId)}</div>
			</div>
		);
	}
	
	return (
		<div className="LoginFacilitator">
			<form className="Login-form" onSubmit={handleCreateUser}>
				<input
					name="email"
					type="text"
					placeholder={getText(loginUiTexts.email, languageId)}
					autoComplete="section-createUser email"
					value={email ? email : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="password"
					type="password"
					placeholder={getText(loginUiTexts.password, languageId)}
					autoComplete="section-createUser password"
					value={password ? password : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="passwordRepeat"
					type="password"
					placeholder={getText(loginUiTexts.passwordRepeat, languageId)}
					autoComplete="section-createUser repeatpassword"
					value={passwordRepeat ? passwordRepeat : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<Button
					isLoading={isLoading}
					text={getText(loginUiTexts.createUserBtn, languageId)}
					onClick={(event) => {handleCreateUser(event);}}
				/>
				<p className="Login-errorMessage">{feedback}</p>
			</form>
			<div 
				className="Login-resetPasswordBtn"
				onClick={()=>{setFacilitatorBoxType('login');}}
			>{getText(loginUiTexts.goToLoginBtn, languageId)}</div>

		</div>
			
	);	
};

CreateUser.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	userCreated: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleCreateUser: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired
};

export default CreateUser;