const reportData = {
	groups: [
		{id: 'work-environment', priority: 1},
		{id: 'clean', priority: 1},
		{id: 'bottleneck', priority: 1},
		{id: 'materials', priority: 1},
		{id: 'machines', priority: 1},
		{id: 'production', priority: 2},
		{id: 'waste', priority: 2},
		{id: 'extra', priority: 4},
	],
	snippets: [
		{
			id: 1,
			groupId: 'work-environment',
			priority: 1,
			order: 3,
			conditions: [
				{type: 'not-round-id', roundId: 4},
				{type: 'result-property', property: 'workEnvironmentReduced', value: true}
			],
			text: {
				da: 'De mange nedjusteringer af bemanding har haft en negativ effekt på arbejdsmiljøet. Vores arbejdmiljø er nu %workEnvironmentTitle%. Arbejdsmiljø påvirker effekten af produktionslinjen med op til +/- 3%-point.',
				en: 'Cutting down on staff has had a negative effect on the work environment. Our work environment is %workEnvironmentTitle% now. The work environment affects the production line with up to +/- 3% points.'
			}
		},
		{
			id: 2,
			groupId: 'work-environment',
			priority: 2,
			order: 1,
			conditions: [
				{type: 'not-round-id', value: 4},
				{type: 'not-round-id', value: 5},
				{type: 'result-property-streak', property: 'workEnvironment', minStreak: 2, maxVal: 3}
			],
			text: {
				da: 'Vores arbejdsmiljø har været %workEnvironmentTitle% i %numberOfRounds% ture. Arbejdsmiljø påvirker effekten af produktionslinjen med op til +/- 3%-point.',
				en: 'Our work environment has been %workEnvironmentTitle% for %numberOfRounds% rounds. The work environment affects the production line with up to +/- 3% points.'
			}
		},
		{
			id: 3,
			groupId: 'clean',
			priority: 1,
			order: 2,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'count-actions', actionIds: [18], minNumber: 1, maxNumber: 1, rounds: 1}
			],
			text: {
				da: 'Ved at tage cLEAN handlingen har vi fået nye handlinger tilgængelige i alle produktionsafsnit.',
				en: 'By performing the cLEAN action, we have new actions available in all production sections.'
			}
		},
		{
			id: 4,
			groupId: 'bottleneck',
			priority: 1,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: false},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'low', increase: true},
				
			],
			text: {
				da: 'Selv om vi justerede mandskab op i %lowCapacityAreaTitle% var der stadigvæk en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units).',
				en: 'Even though we hired more staff in %lowCapacityAreaTitle%, there was still a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity% units) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity% units).'
			}
		},
		{
			id: 5,
			groupId: 'bottleneck',
			priority: 2,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'low', increase: true},
			],
			text: {
				da: 'Ved at justere mandskabet op i %lowCapacityAreaTitle% mindskede vi en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units). Der er dog stadigvæk en flaskehals, som påvirker vores Unit Cost negativt.',
				en: 'By hiring more staff in %lowCapacityAreaTitle%, we reduced a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity% units) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity% units). Unfornuately there is still a bottle neck affecting our Unit Cost negatively.'
			}
		},
		{
			id: 6,
			groupId: 'bottleneck',
			priority: 3,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'high', decrease: true}
			],
			text: {
				da: 'Ved at justere mandskabet ned i %highCapacityAreaTitle% mindskede vi en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units). Der er dog stadigvæk en flaskehals, som påvirker vores Unit Cost negativt.',
				en: 'By cutting down on staff in %lowCapacityAreaTitle%, we reduced a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity% units) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity% units). Unfornuately there is still a bottle neck affecting our Unit Cost negatively.'
			}
		},
		{
			id: 7,
			groupId: 'bottleneck',
			priority: 3,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: false},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'high', decrease: true}
			],
			text: {
				da: 'Ved at justere mandskabet ned i %highCapacityAreaTitle% mindskede vi en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units). Vi nåede dog ikke vores demand, så bemandingen bør formentlig tilpasses næste tur.',
				en: 'By cutting down on staff in %lowCapacityAreaTitle% we reduced a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity% units) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity% units). Unfornuately we didn\'t reach our demand so the staff should probably be adjusted next round.'
			}
		},
		{
			id: 8,
			groupId: 'bottleneck',
			priority: 1,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'low', increase: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'high', decrease: true}
			],
			text: {
				da: 'Ved at nedjustere bemandingen i %highCapacityAreaTitle% og opjustere i %lowCapacityAreaTitle%, lykkedes det jer at formindske, men ikke fjerne flaskehalsen mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units).',
				en: 'Cutting down on staff in %highCapacityAreaTitle% and hiring more in %lowCapacityAreaTitle%, you succeed to reduce, but not remove the bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity%) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity%).'
			}
		},
		{
			id: 9,
			groupId: 'bottleneck',
			priority: 1,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: false},
				{type: 'result-property', property: 'demandWasMet', value: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'low', increase: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'high', decrease: true}
			],
			text: {
				da: 'Ved at nedjustere bemandingen i %highCapacityAreaTitle% og opjustere i %lowCapacityAreaTitle%, lykkedes det jer at fjerne flaskehalsen mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units).',
				en: 'Cutting down on staff in %highCapacityAreaTitle% and hiring more in %lowCapacityAreaTitle%, you succeed to remove the bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity%) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity%).'
			}
		},
		{
			id: 10,
			groupId: 'bottleneck',
			priority: 2,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: false},
				{type: 'result-property', property: 'demandWasMet', value: false},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'low', increase: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'high', decrease: false}
			],
			text: {
				da: 'Ved at justere mandskab i %lowCapacityAreaTitle% op fik vi fjernet en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units). Vi nåede dog ikke vores demand, så bemandingen bør formentlig tilpasses næste tur.',
				en: 'By hiring more staff in %lowCapacityAreaTitle%, we removed a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity% units) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity% units). Unfornuately we didn\'t reach our demand so the staff should probably be adjusted next round.'
			}
		},
		{
			id: 11,
			groupId: 'bottleneck',
			priority: 2,
			order: 1,
			conditions: [
				{type: 'bottleneck-before-actions', value: true},
				{type: 'bottleneck', value: false},
				{type: 'result-property', property: 'demandWasMet', value: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'low', increase: true},
				{type: 'staff-change-in-area', areaEffectiveCapacity: 'high', decrease: false}
			],
			text: {
				da: 'Ved at justere mandskabet op i %lowCapacityAreaTitle% fjernede vi en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units).',
				en: 'By hiring more people in %lowCapacityAreaTitle%, we removed a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity% units) and %lowCapacityAreaTitle% (effective capacityt: %lowCapacityAreaEffectiveCapacity% units).'
			}
		},
		{
			id: 12,
			groupId: 'machines',
			priority: 1,
			order: 2,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'count-actions', actionIds: [7, 13, 15], minNumber: 1, maxNumber: 1, rounds: 1},
			],
			type: 'new-machines',
			text: {
				da: 'Den nye maskine i %areaTitles% har givet et boost i kapaciteten. Se på om bemandingen bør tilpasses, når maskinen er ramped helt op.',
				en: 'The new machine in %areaTitles% has boosted your capacity. Check if the staff needs to be adjusted when the machine is all ramped up.'
			},
			text2: {
				da: 'De nye maskiner i %areaTitles%  har givet et boost i kapaciteten. Se på om bemandingen bør tilpasses, når maskinerne er ramped helt op.',
				en: 'The new machines in %areaTitles% have boosted your capacity. Check if the staff needs to be adjusted when the machines are all ramped up.'
			}
		},
		{
			id: 13,
			groupId: 'production',
			priority: 1,
			order: 3,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'result-property-streak', property: 'production', minStreak: 3, compareToProperty: 'actualDemand', lessThan: true, fraction: 0.15},
			],
			text: {
				da: 'Vores produktion har ligget bagefter demand i %numberOfRounds% ture. Koncernhovedkontoret er tydeligvis ikke tilfredse.',
				en: 'Our production has been falling behind the demand for %numberOfRounds% turns. The group headquarters are not satisfied.'
			},
		},
		{
			id: 14,
			groupId: 'production',
			priority: 1,
			order: 3,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'result-property-streak', property: 'production', minStreak: 2, compareToProperty: 'actualDemand', lessThan: false, fraction: 0.3}
			],
			text: {
				da: 'Vores produktion har ligget betydeligt over demand i %numberOfRounds% ture. Det er vigtigt med et tilpas stort lager, men husk også at der er spild på lager.',
				en: 'Our production has been exceeding demand for %numberOfRounds% turns. It is important to have a sufficient level of safety stock, but we must remember there will be inventory scrap.'
			}
		},
		{
			id: 15,
			groupId: 'materials',
			priority: 1,
			order: 3,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'count-actions', actionIds: [8, 16, 31], minNumber: 1, maxRounds: 1},
			],
			type: 'search-materials-market',
			text: {
				da: 'Ved at tage en "Afsøg råvaremarkedet" handling har vi nu fået nye handlinger i %materialsAreaTitles%.',
				en: 'By performing a "search commodity market" action, we have new actions available in %materialsAreaTitles%.'
			},
		},
		{
			id: 16,
			groupId: 'extra',
			priority: 1,
			order: 4,
			conditions: [
				{type: 'not-round-id', roundId: 2},
				{type: 'count-actions', actionIds: [22, 24, 25, 26, 9, 29, 19, 20, 33, 10, 21], maxNumber: 2, maxRounds: 4}
			],
			text: {
				da: 'Ved at begrænse spild kan vi forbedre vores Unit Cost betydeligt. Vores nuværende spildprocenter er: %waste-info%',
				en: 'By reducing scrap, we can improve our Unit Cost significantly. Our current scrap percentages are: %waste-info%'
			}
		},
		{
			id: 17,
			groupId: 'extra',
			priority: 2,
			order: 4,
			conditions: [
				{type: 'count-actions', actionIds: [16, 17, 24, 8, 9, 28, 31, 32, 33], maxNumber: 0, maxRounds: 8}
			],
			text: {
				da: 'Vores nuværende sammenlagte råvareomkostninger er %totalMaterialCostPrimo% NB. Ved at nedbringe råvareomkostninger kan vi forbedre vores Unit Cost.',
				en: 'Our current total costs for raw materials are %totalMaterialCostPrimo%. By reducing the costs of raw materials, we can improve our Unit Cost.'
			}
		},
		{
			id: 18,
			groupId: 'extra',
			priority: 3,
			order: 4,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'round-id', minRoundId: 4},
				{type: 'count-actions', actionIds: [18, 31, 8, 16], maxNumber: 0, maxRounds: 8}
			],
			text: {
				da: 'Ved at tage "cLEAN"-handlingen i Kontor & Lager eller "Afsøg råvaremarkedet"-handlingen i de enkelte afsnit, kan vi åbne op for flere handlinger og få flere muligheder for at nedbringe Unit Cost.',
				en: 'The "cLEAN" action in the Office & warehouse section and the "Search commodity market" actions in the other sections unlock multiple actions that can provide you with opportunities to bring down your Unit Cost.'
			}
		},
		{
			id: 19,
			groupId: 'extra',
			priority: 4,
			order: 4,
			conditions: [
				{type: 'not-round-id', roundId: 5},
				{type: 'not-round-id', roundId: 9},
				{type: 'round-id', minRoundId: 4},
			],
			text: {
				da: 'Vi har indtil videre %unitCostDirection% vores Unit Cost med %unitCostDifference%. Vi har %roundsLeft% til at nedbringe Unit Cost så meget som muligt.',
				en: 'So far we have %unitCostDirection% our Unit Cost by %unitCostDifference%. We have %roundsLeft% to bring down the Unit Cost as much as possible.'
			}
		},
		{
			id: 20,
			groupId: 'extra',
			priority: 5,
			order: 4,
			conditions: [
				{type: 'not-round-id', roundId: 9},
				{type: 'round-id', minRoundId: 4},
				{type: 'count-actions', actionIds: [14], maxNumber: 0, maxRounds: 8}
			],
			text: {
				da: 'Vores samlede faste udgifter er %totalFixedCosts% NB. Vores elforbrug er en stor del af det. Vi kan nedbringe elforbruget ved at opgradere vores udluftningsanlæg i Kontor & Lager.',
				en: 'Our total costs of the fixed cost are %totalFixedCosts% NB. Our electricity consumption is a big part of it. We can bring down the electricity bill by upgrading our ventilation system in Office & Warehouse.'
			}
		},
		{
			id: 22,
			groupId: 'waste',
			priority: 2,
			order: 2,
			conditions: [
				{type: 'not-round-id', roundId: 2},
				{type: 'count-actions', actionIds: [22, 24, 25, 26, 9, 29, 19, 20, 33, 10, 21], minNumber: 3, maxRounds: 4}
			],
			text: {
				da: 'Vores fokus på at nedbringe spild har sikret os bedre spildprocenter. Vores seneste spildprocenter er: %waste-info%',
				en: 'Our focus on reducing scrap has given us improved scrap percentages. Our lastest scrap percentages are: %waste-info%'
			}
		},
		{
			id: 23,
			groupId: 'bottleneck',
			priority: 4,
			order: 1,
			conditions: [
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: true}
			],
			text: {
				da: 'Vi har en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units).',
				en: 'We have a bottle neck between %highCapacityAreaTitle% (effective capacity %highCapacityAreaEffectiveCapacity%) and %lowCapacityAreaTitle% (effective capacity: %lowCapacityAreaEffectiveCapacity%).'
			}
		},
		{
			id: 24,
			groupId: 'bottleneck',
			priority: 4,
			order: 1,
			conditions: [
				{type: 'bottleneck', value: true},
				{type: 'result-property', property: 'demandWasMet', value: false}
			],
			text: {
				da: 'Vi har en flaskehals mellem %highCapacityAreaTitle% (effektiv kapacitet %highCapacityAreaEffectiveCapacity% units) og %lowCapacityAreaTitle% (effektiv kapacitet: %lowCapacityAreaEffectiveCapacity% units).',
				en: 'We have a bottle neck between %highCapacityAreaTitle% (efficient capacity %highCapacityAreaEffectiveCapacity%) and %lowCapacityAreaTitle% (efficient capacity: %lowCapacityAreaEffectiveCapacity%).'
			}
		}
	]
};

export {reportData};