const loginUiTexts = {
	login: {
		da: 'Log ind',
		en: 'Log in'
	},
	logout: {
		da: 'Log ud',
		en: 'Log out'
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator'
	},
	group: {
		da: 'Spiller',
		en: 'Player'
	},
	email: {
		da: 'e-mail',
		en: 'e-mail'
	},
	password: {
		da: 'password',
		en: 'password'
	},
	passwordRepeat: {
		da: 'gentag password',
		en: 'repeat password'
	},
	gameCode: {
		da: 'spilkode',
		en: 'game code',
	},
	newPlayer: {
		da: 'Ny gruppe',
		en: 'New group'
	},
	createNewUser: {
		da: 'Opret ny bruger',
		en: 'Create new user'
	},
	forgotPassword: {
		da: 'Glemt dit password?',
		en: 'Forgot your password?'
	},
	goToLoginBtn: {
		da: 'Tilbage til login',
		en: 'Back to login'
	},
	resetPassword: {
		da: 'Indtast din e-mail for at nulstille dit password:',
		en: 'Enter your email to reset your password:'
	},
	createUserBtn: {
		da: 'Opret',
		en: 'Create'
	},
	groupName: {
		da: 'Gruppenavn',
		en: 'Group name'
	},
	create: {
		da: 'Opret',
		en: 'Create'
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset'
	},
	cancel: {
		da: 'Afbryd',
		en: 'Cancel'
	},
	cancel2: {
		da: 'Annuller',
		en: 'Cancel'
	},
	createUserFeedback: {
		da: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
		en: 'Your new user has been created. Go back to log in to play.'
	},
	passwordResetFeedback: {
		da: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
		en: 'You have received a link to reset your password in your inbox.'
	},
	unknownError: {
		da: 'Fejl: ukendt fejl',
		en: 'Error: unknown error'
	},
	error: {
		da: 'Fejl',
		en: 'Error'
	},
	cookiesPopup: {
		text: {
			da: 'Vi bruger cookies til at forbedre din spiloplevelse. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
			en: 'We use cookies to enhance your game experience. By clicking \'OK\' or logging in you accept our use of cookies.'
		},
		more: {
			da: 'Læse mere',
			en: 'More info'
		},
		btn: {
			da: 'Ok',
			en: 'Ok'
		}
	}
};

const settingsUiTexts = {
	loadingGame: {
		da: 'Henter spil',
		en: 'Loading game'
	},
	createGame: {
		da: 'Opret nyt spil',
		en: 'Create new game'
	},
	start: {
		da: 'Start',
		en: 'Start'
	},
	play: {
		da: 'Spil',
		en: 'Play'
	},
	game: {
		da: 'Spil',
		en: 'Game'
	},
	gameLanguage: {
		da: 'Sprog',
		en: 'Language'
	},
	gameScenario: {
		da: 'Scenarie',
		en: 'Scenario'
	},
	gameCode: {
		da: 'Spilkode',
		en: 'Game code'
	},
	gameTitle: {
		da: 'Unit Cost Game',
		en: 'Unit Cost Game'
	},
	created: {
		da: 'Spildato',
		en: 'Game date'
	},
	round: {
		da: 'Runde',
		en: 'Round'
	},
	openRound: {
		da: 'Åbn runde',
		en: 'Open round'
	},
	pauseGame: {
		da: 'Sæt spil på pause',
		en: 'Pause game'
	},
	unpauseGame: {
		da: 'Genoptag spil',
		en: 'Unpause game'
	},
	unitCostScore: {
		da: 'Unit Cost Score',
		en: 'Unit Cost Score'
	},
	downloadData: {
		da: 'Download data',
		en: 'Download data'
	}
};

const statsUiTexts = {
	statistics: {
		da: 'Statistik',
		en: 'Statistics'
	},
	loadingStats: {
		da: 'Henter statistik ...',
		en: 'Loading stats ...'
	},
	facilitators: {
		da: 'Facilitatorer',
		en: 'Facilitators'
	},
	games: {
		da: 'Spil',
		en: 'Games'
	},
	downloadEmails: {
		da: 'Download e-mails (emails.txt)',
		en: 'Download e-mails (emails.txt)'
	},
	email: {
		da: 'E-mail',
		en: 'Email'
	},
	created: {
		da: 'Oprettet',
		en: 'Created'
	},
	numberOfGames: {
		da: 'Antal spil',
		en: 'Number of games'
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator'
	},
	scenario: {
		da: 'Scenarie',
		en:' Scenario'
	},
	round: {
		da: 'Runde',
		en: 'Round'
	},
	groups: {
		da: 'Grupper',
		en: 'Groups'
	}
};


const generalUiTexts = {
	title: {
		da: 'Unit Cost Game',
		en: 'Unit Cost Game'
	},
	overview: {
		da: 'Overblik',
		en: 'Overview'
	},
	washAndSterilization: {
		da: 'Vask & sterilisation',
		en: 'Wash & sterilisation'
	},
	formulationAndStabilization: {
		da: 'Formulering & stabilisering',
		en: 'Formulation & stabilisation'
	},
	fillingAndInspection: {
		da: 'Fyldning & inspektion',
		en: 'Filling & inspection'
	},
	officeAndStorage: {
		da: 'Kontor & lager',
		en: 'Office & warehouse'
	},
	department: {
		da: 'Afdeling',
		en: 'Department'
	},
	unitCost: {
		da: 'Unit Cost',
		en: 'Unit Cost'
	},
	units: {
		da: 'Units',
		en: 'Units'
	},
	costPerUnit: {
		da: 'NB/unit',
		en: 'NB/unit'
	},
	costPerTurn: {
		da: 'NB/tur',
		en: 'NB/turn'
	},
	unitsOrCost: {
		da: 'Units eller Cost',
		en: 'Units or Cost'
	},
	nb: {
		da: 'NB',
		en: 'NB'
	},
	percent: {
		da: '%',
		en: '%'
	},
	outOf5: {
		da: '/5', 
		en: '/5'
	},
	outOf3: {
		da: '/3', 
		en: '/3'
	},
	cost: {
		da: 'Cost',
		en: 'Cost'
	},
	costs: {
		da: 'Omkostninger',
		en: 'Costs'
	},
	capacityCosts: {
		da: 'Kapacitetsomkostninger',
		en: 'Capacity costs'
	},
	production: {
		da: 'Produktion',
		en: 'Production'
	},
	inventoryBalance: {
		da: 'Lagerbalance',
		en: 'Inventory'
	},
	waste: {
		da: 'Spild',
		en: 'Scrap'
	},
	start: {
		da: 'Start',
		en: 'Start'
	},
	roundActions: {
		da: 'Turens handlinger',
		en: 'Game turn actions'
	},
	selectedAction: {
		da: 'Vælg handling',
		en: 'Select action'
	},
	execute: {
		da: 'Eksekver',
		en: 'Execute'
	},
	writtenOffIn: {
		da: 'afskrevet om',
		en: 'fully depreciated in'
	},
	rampup: {
		da: 'ramp up',
		en: 'ramp up'
	},
	effectNowAndWhenImplemented: {
		da: '%currentCapacity% units (%percent% ramp up), og %finalCapacity% units når den er kørt ind.',
		en: '%currentCapacity% units (%percent% ramp up), and %finalCapacity% units when implemented.'
	},
	round: {
		da: 'tur',
		en: 'turn'
	},
	rounds: {
		da: 'ture',	
		en: 'turns'
	},
	rounds2: {
		da: 'Ture',
		en: 'Turns'
	},
	roundLeft: {
		da: 'tur tilbage',
		en: 'turn left'
	},
	roundsLeft: {
		da: 'ture tilbage',
		en: 'turns left'
	},
	perRound: {
		da: 'per tur',
		en: 'per turn'
	},
	noMachineWriteoffs: {
		da: 'Ingen aktive maskinafskrivninger',
		en: 'No active depreciations'
	},
	oldWashingMachine: {
		da: 'Gamle vaskemaskine',
		en: 'Old washing machine'
	},
	oldFormulationMachine: {
		da: 'Gamle formuleringsmaskine',
		en: 'Old formulation machine'
	},
	oldIlluminationsMachine: {
		da: 'Gamle gennemlysningsmaskine',
		en: 'Old transillumination machine'
	},
	oldWarehouseMachine: {
		da: 'Digitalt lagerstyringsssystem',
		en: 'Digital warehouse management system'
	},
	roundUnitCost: {
		da: 'Turens Unit Cost ender på %unitcost% NB/Unit.',
		en: 'This turn\'s Unit Cost ends at %unitcost% NB/Unit.'
	},
	boughtUnitsInfo: {
		da: 'Uden indkøbte units ville Unit Cost være %original-unitcost% NB/Unit. Dette kan ses på den stiplede linje på grafen.',
		en: 'Your Unit Cost without buying extra units would be %original-unitcost% NB/Unit. This is shown by the dashed line on the graph.'
	},
	newRoundText: {
		da: '<p>Tiden går og der arbejdes med at producere og implementere jeres handlinger.</p><p>En tur i spillet svarer ca til et år.</p>',
		en: '<p>Time passes. We\'re working on producing and implementing your actions.</p><p>A turn in the game is approx. a year.</p>'
	},
	total: {
		da: 'Samlet',
		en: 'Total'
	},
	increased: {
		da: 'hævet',
		en: 'increased'
	},
	decreased: {
		da: 'sænket',
		en: 'decreased'
	},
	listAnd: {
		da: 'og',
		en: 'and'
	},
	listAndIn: {
		da: 'og i',
		en: 'and in'
	}
};


module.exports = {
	loginUiTexts,
	settingsUiTexts,
	statsUiTexts,
	generalUiTexts
};
