const teamsData = {
	'operator-teams': {
		efficiency: [0, 0, 0.45, 0.65, 0.80, 0.90],
		modifiers: [
			{type: 'work-environment', minVal: 1, maxVal: 3, effect: -0.03},
			{type: 'work-environment', minVal: 7, maxVal: 9, effect: 0.02},
		]
	},
	'support-teams': {
		'wash-and-sterilization': {
			'level-1': {
				capacity: 100000,
				efficiency: [0, 0.5, 0.7, 0.8]
			},
			'level-2': {
				capacity: 300000,
				efficiency: [0, 0.7, 0.8, 0.9]
			}
		},
		'formulation-and-stabilization': {
			'level-1': {
				capacity: 175000,
				efficiency: [0, 0.65, 0.75, 0.8]
			},
			'level-2': {
				capacity: 400000,
				efficiency: [0, 0.7, 0.8, 0.9]
			}
		},
		'filling-and-inspection': {
			'level-1': {
				capacity: 150000,
				efficiency: [0, 0.6, 0.7, 0.8]
			},
			'level-2': {
				capacity: 380000,
				efficiency: [0, 0.65, 0.75, 0.85]
			}
		}
	}


};

export {teamsData};