import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import Loading from 'components/loading/loading';
import Stats from './stats';
import { getText } from 'helpers/language-helper';
import { statsUiTexts } from 'data/ui-texts';

class StatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isDownloading: false,
			statsType: 'users',
			users: [],
			games: [],
			sortBy: 'created',
			sortGamesBy: 'created',
			sortDirection: 'DESC',
			sortGamesDirection: 'DESC'
		};
	};

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		let users = [];
		let games = [];

		/* Get users */
		const db = firebase.firestore();
		db.collection('users').get().then((docs) => {
			docs.forEach((doc) => {users.push({id: doc.id, games: [], ...doc.data()});});
			/* Get groups */
			db.collection(appConfig.groupsDbName).get().then((docs) => {
				let groups = [];
				docs.forEach((doc) => {groups.push(doc.data());});
				/* Get games & connect to groups */
				db.collection(appConfig.gamesDbName).get().then((docs) => {
					docs.forEach((doc) => {
						let game = {id: doc.id, groups: [], ...doc.data()};
						let facilitator = users.find((u) => {return u.id === game.facilitatorId;});
						game.groups = groups.filter((group) => {return group.gameId === game.id;});
						game.facilitatorEmail = (facilitator ? facilitator.email : '-');
						games.push(game);
					});
					/* Connect games to users */
					users.forEach((user) => {
						user.games = games.filter((game) => {return user.id === game.facilitatorId;});
						if (!user.created) user.created = 0;
					});
					/* Sort users and games by creation date */
					users = sortArrayByProperty(users, 'created', 'DESC');
					games = sortArrayByProperty(games, 'created', 'DESC');
					this.setState({
						isLoading: false,
						users: users,
						games: games
					});			
				}).catch((error) => {
					/* Error getting games */
					console.error(error);
					this.setState({isLoading: false});
				});
			}).catch((error) => {
				/* Error gettings groups */
				console.error(error);
				this.setState({isLoading: false});
			});
			
		}).catch((error) => {
			/* Error gettings users */
			console.error(error);
			this.setState({isLoading: false});
		});
	}

	/**
	 * Toggle between user / game stats
	 * @param {string} type 
	 */
	showStatsOfType = (type) => {
		this.setState({statsType: type});
	}

	/**
	 * Download emails
	 */
	downloadEmails = () => {
		let emails = '';
		this.state.users.forEach((user) => {
			if (user.hasOwnProperty('email') && user.email.length > 0) {
				if (emails.length > 0)  emails = emails + ', ';
				emails = emails + user.email;
			}
		});
		const blob = new Blob([emails], {type: 'text/json'});
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a'); 
		link.href = url;
		link.setAttribute('download', 'emails.txt');
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}

	/**
	 * Sort users by property
	 * @param {string} sortBy 
	 */
	sortUsersBy = (sortBy) => {
		let users = JSON.parse(JSON.stringify(this.state.users));
		let direction = 'DESC';
		if (sortBy === this.state.sortBy) {
			direction = (this.state.sortDirection === 'ASC' ? 'DESC' : 'ASC');
			users = sortArrayByProperty(users, sortBy, direction);
		} else {
			users = sortArrayByProperty(users, sortBy, 'DESC');
		}
		this.setState({
			users: users,
			sortBy: sortBy,
			sortDirection: direction
		});
	}

	/**
	 * Sort games by property
	 * @param {string} sortBy 
	 */
	sortGamesBy = (sortBy) => {
		let games = JSON.parse(JSON.stringify(this.state.games));
		let direction = 'DESC';
		if (sortBy === this.state.sortGamesBy) {
			direction = (this.state.sortGamesDirection === 'ASC' ? 'DESC' : 'ASC');
			games = sortArrayByProperty(games, sortBy, direction);
		} else {
			games = sortArrayByProperty(games, sortBy, 'DESC');
		}
		this.setState({
			games: games,
			sortGamesBy: sortBy,
			sortGamesDirection: direction
		});
	}


	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading 
					languageId={this.props.userData.languageId}
					loadErrMsg={getText(statsUiTexts.loadingStats, this.props.userData.languageId)}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		/* Stats */
		return (
			<Stats 
				statsType={this.state.statsType}
				languageId={this.props.userData.languageId}
				users={this.state.users}
				games={this.state.games}
				showStatsOfType={this.showStatsOfType}
				downloadEmails={this.downloadEmails}
				sortUsersBy={this.sortUsersBy}
				sortGamesBy={this.sortGamesBy}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

StatsController.propTypes = {
	userData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default StatsController;