import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';

const ResetPassword = (props) => {
	let {
		isLoading,
		passwordIsReset,
		languageId,
		email,
		feedback,
		handleInput,
		handleSubmit,
		setFacilitatorBoxType,
		invalidEmail
	} = props;

	if (passwordIsReset) {
		return (
			<div className="LoginFacilitator">
				<div className="Login-info">{getText(loginUiTexts.passwordResetFeedback, languageId)}</div>
				<div 
					className="Login-resetPasswordBtn"
					onClick={()=>{setFacilitatorBoxType('login');}}
				>{getText(loginUiTexts.goToLoginBtn, languageId)}</div>
			</div>
		);
	}

	return (
		<div className="LoginFacilitator">
			<div className="Login-info">{getText(loginUiTexts.resetPassword, languageId)}</div>
			<form className="Login-form" onSubmit={handleSubmit}>
				<input
					className={invalidEmail ? ' invalid' : ''}
					name="email"
					type="text"
					placeholder={getText(loginUiTexts.email, languageId)}
					autoComplete="section-resetpasswrd email"
					value={email ? email : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<Button
					isLoading={isLoading}
					text={getText(loginUiTexts.reset, languageId)}
					onClick={() =>{handleSubmit();}}
				/>
				<p className="Login-errorMessage">{feedback}</p>
			</form>
			<div 
				className="Login-resetPasswordBtn"
				onClick={()=>{setFacilitatorBoxType('login');}}
			>{getText(loginUiTexts.goToLoginBtn, languageId)}</div>
		</div>
	);
};

ResetPassword.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	passwordIsReset: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	email: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
	invalidEmail: PropTypes.bool.isRequired
};

export default ResetPassword;