import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';
import './cookie-consent.scss';

const CookieConsent = ({languageId, handleClosePopup}) => {
	return (
		<div className="CookieConsent">
			<div className="CookieConsent-text">
				<span>{getText(loginUiTexts.cookiesPopup.text, languageId)  + ' '}</span>
				<a href="https://cphgamelab.dk/privatlivspolitik" target="_blank" rel="noreferrer">
					{getText(loginUiTexts.cookiesPopup.more, languageId)}
				</a>
			</div>
			<div className="CookieConsent-button">
				<Button 
					classes={['cookies']} 
					text={getText(loginUiTexts.cookiesPopup.btn, languageId)} 
					onClick={handleClosePopup}
				/>
			</div>
		</div>
	);	
};

CookieConsent.propTypes = {
	languageId: PropTypes.string.isRequired,
	handleClosePopup: PropTypes.func.isRequired
};

export default CookieConsent;