import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {generalUiTexts, settingsUiTexts, statsUiTexts} from 'data/ui-texts';
import {languagesData} from 'data/languages-data';
import {scenariosData} from 'data/scenarios-data';
import {getText} from 'helpers/language-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import Background from 'components/background/background';
import Select from 'components/select/select';
import CreateGamePopup from './create-game-popup';
import DeleteGamePopup from './delete-game-popup';
import './settings.scss';

const Settings = (props) => {
	let {
		isAdmin,
		showCreateGamePopup,
		showDeleteGamePopup,
		isCreatingGame,
		isDeletingGame,
		userData,
		gameId,
		games,
		goToPage,
		toggleCreateGamePopup,
		toggleDeleteGamePopup,
		handleCreateGame,
		handleDeleteGame,
		updateUser,
		updateGame,
		handleGoToGame,
		handleLogout,
	} = props;


	/* Sort games by play date and title, filter by facilitatorId */
	let sortedGames = sortArrayByProperty(games, 'playDate', 'DESC', 'title', 'ASC');

	/* Update user language */
	const handleUpdateUserLanguage = (languageId) => {
		updateUser({languageId});
	};

	const handleUpdateGameLanguage = (languageId, gameId) => {
		if (gameId && languageId) updateGame({languageId: languageId}, gameId);
	};

	return (
		<div className="Settings">
			<Background />
			<div className="Settings-panel">
				<div className="Settings-title">{getText(generalUiTexts.title, userData.languageId)}</div>
				<div className="Settings-logoutBtn" onClick={() => {handleLogout();}} />
				{isAdmin && 
					<div className="Settings-statsBtn" onClick={() => {goToPage('stats');}}>
						{getText(statsUiTexts.statistics, userData.languageId)}
					</div>}
				<div className="Settings-language">
					<Select 
						isDisabled={false}
						type="facilitator"
						languageId={userData.languageId}
						options={languagesData}
						defaultId={appConfig.defaultLanguage}
						selectedId={userData && userData.languageId ? userData.languageId : null}
						onSelect={handleUpdateUserLanguage}
					/>
				</div>
				<div className="Settings-createGameBtn" onClick={() => {toggleCreateGamePopup();}}>
					{getText(settingsUiTexts.createGame, userData.languageId)} 
				</div>

				<div className="Settings-games">
					{/* Loop over games */}
					{sortedGames.map((game) => {
						let isStarted = game?.isStarted;
						let gameScenario = scenariosData.find((sc) => {return sc.id === game.scenarioId;});
						// let gameLanguage = languagesData.find((l) => {return l.id === game.languageId;});
						return (
							<div key={game.id} className={'Settings-game' + (isStarted ? ' started' : '')}>
								{/* Game header: title & play date */}
								<div className={'Settings-gameHeader'}>
									<div className="Settings-gameTitle">{game.title}</div>
									<div className="Settings-gameDate">{game.playDate}</div>
								</div>
								{/* Game body: play & delete buttons, game code */}
								<div className="Settings-gameBody">
									<div
										className={'Settings-playBtn' + (isStarted ? ' started' : '')}
										onClick={() => {handleGoToGame(game.id);}}
									>
										{isStarted 
											? getText(settingsUiTexts.play, userData.languageId) 
											: getText(settingsUiTexts.start, userData.languageId)
										}
									</div>
									<div className="Settings-gameLanguage">
										<span>{getText(settingsUiTexts.gameLanguage, userData.languageId)}: </span>
										{/* {getText(gameLanguage.title, userData.languageId)} */}
										<Select 
											isDisabled={false}
											type="game"
											languageId={userData.languageId}
											options={languagesData}
											defaultId={appConfig.defaultLanguage}
											selectedId={game.languageId ? game.languageId : null}
											extraParam={game.id}
											onSelect={handleUpdateGameLanguage}
										/>
									</div>
									{gameScenario && <div className="Settings-gameScenario">
										<span>{getText(settingsUiTexts.gameScenario, userData.languageId)}: </span>
										{getText(gameScenario.title, userData.languageId)}
									</div>}
									<div className="Settings-gameCode">
										<span>{getText(settingsUiTexts.gameCode, userData.languageId)}:</span>
										{game.id}
									</div>
									<div 
										className="Settings-deleteBtn" 
										onClick={() => {toggleDeleteGamePopup(game.id);}}
									/>
								</div>
							</div>
						);
					})}
				</div>

				{/* Create game popup */}
				{showCreateGamePopup &&
				<CreateGamePopup 
					isCreatingGame={isCreatingGame}
					languageId={userData.languageId}
					handleCreateGame={handleCreateGame}
					toggleCreateGamePopup={toggleCreateGamePopup}
				/>}
				{/* Delete game popup */}
				{(showDeleteGamePopup && gameId) && 
				<DeleteGamePopup 
					isDeletingGame={isDeletingGame}
					languageId={userData.languageId}
					gameId={gameId}
					toggleDeleteGamePopup={toggleDeleteGamePopup}
					handleDeleteGame={handleDeleteGame}
				/>
				}

			</div>
		</div>
	);
};

Settings.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	showCreateGamePopup: PropTypes.bool.isRequired,
	showDeleteGamePopup: PropTypes.bool.isRequired,
	isCreatingGame: PropTypes.bool.isRequired,
	isDeletingGame: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	gameId: PropTypes.string,
	games: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired,
	toggleCreateGamePopup: PropTypes.func.isRequired,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	handleCreateGame: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateGame: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Settings;
