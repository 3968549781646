/* Sort array of objects by specific object property */
export function sortArrayByProperty(array, propertyName, order = 'ASC',
	secondaryPropertyName = null, secondaryOrder = 'ASC',
	tertieryPropertyName = null, tertieryOrder = 'ASC'
) {
	let sortedArray = array.sort(function(a, b) {
		/* Sort by tertiery property */
		if (
			a[propertyName] === b[propertyName] && secondaryPropertyName &&
			a[secondaryPropertyName] === b[secondaryPropertyName] && tertieryPropertyName
		) {
			if (
				a.hasOwnProperty(tertieryPropertyName) && a[tertieryPropertyName] &&
				b.hasOwnProperty(tertieryPropertyName) && b[tertieryPropertyName] 
			) {
				if (tertieryOrder === 'ASC') {
					if (a[tertieryPropertyName] < b[tertieryPropertyName]) { return -1; }
					if (a[tertieryPropertyName] > b[tertieryPropertyName]) { return 1; }
					return 0;
		
				} else if (tertieryOrder === 'DESC') {
					if (a[tertieryPropertyName] > b[tertieryPropertyName]) { return -1; }
					if (a[tertieryPropertyName] < b[tertieryPropertyName]) { return 1; }
					return 0;
				}
				return 0;
			} 
			return 0;
		} 

		/* Sort by secondary property */
		if (a[propertyName] === b[propertyName] && secondaryPropertyName) {
			if (
				a.hasOwnProperty(secondaryPropertyName) && a[secondaryPropertyName] &&
				b.hasOwnProperty(secondaryPropertyName) && b[secondaryPropertyName] 
			) {
				if (secondaryOrder === 'ASC') {
					if (a[secondaryPropertyName] < b[secondaryPropertyName]) { return -1; }
					if (a[secondaryPropertyName] > b[secondaryPropertyName]) { return 1; }
					return 0;
		
				} else if (secondaryOrder === 'DESC') {
					if (a[secondaryPropertyName] > b[secondaryPropertyName]) { return -1; }
					if (a[secondaryPropertyName] < b[secondaryPropertyName]) { return 1; }
					return 0;
				}
				return 0;
			} 
			return 0;
		} 

		/* Sort by primary property */
		if (order === 'ASC') {
			if (a[propertyName] < b[propertyName]) { return -1; }
			if (a[propertyName] > b[propertyName]) { return 1; }
			return 0;
		} else if (order === 'DESC') {
			if (a[propertyName] > b[propertyName]) { return -1; }
			if (a[propertyName] < b[propertyName]) { return 1; }
			return 0;
		} 
		return 0;
	});
	return sortedArray;
};

// Randomly shuffle array
export function shuffleArray(array) {
	if (array.length < 2) {return array;}
	// https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
	return array.map((a) => { return [Math.random(), a];})
		.sort((a, b) => { return a[0] - b[0];})
		.map((a) => { return a[1];});
}