import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from '@visx/scale';
import { AxisLeft, AxisRight, AxisBottom } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { MarkerCircle } from '@visx/marker';
import { Threshold } from '@visx/threshold';
import { LinePath } from '@visx/shape';
import { Group } from '@visx/group';
import { useTooltip, Tooltip } from '@visx/tooltip';
import { generalUiTexts } from 'data/ui-texts';
import {popupsData} from 'data/popups-game-data';
import {formatNumber} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import './graph.scss';


/* 
https://airbnb.io/visx/axis 
https://airbnb.io/visx/threshold 
https://airbnb.io/visx/statsplot
*/
const Graph = (props)  => {
	const {
		showDemand, 
		showProduction, 
		showCost, 
		showUnitCost, 
		width, 
		height, 
		yMaxVal1, 
		yMaxVal2, 
		languageId,
		data
	} = props;

	/* Tooltip */
	const {
		tooltipOpen,
		tooltipLeft,
		tooltipTop,
		tooltipData,
		hideTooltip,
		showTooltip,
	} = useTooltip();

	/* Tooltip */
	const handleMouseLeave = () => {hideTooltip();};
	const handleMouseOver = (value, x, y, type, labelId) => {
		let labelData = popupsData.report.labels.find((label) => {return label.id === labelId;});
		let unit = 'NB/unit';
		if (type === 'production' || type === 'demand' || type === 'demand2') unit = 'units';
		if (type === 'cost') unit = 'NB'; 
		showTooltip({
			tooltipData: {
				title: (labelData.title2 ? getText(labelData.title2, languageId) : null),
				value: formatNumber(
					languageId, 
					value, 
					(type === 'unitcost' || type === 'unitcost2' ? 2 : 0), 
					(type === 'demand2' ? true : false)
				) + ' ' + unit,
				type: type
			},
			tooltipTop: y,
			tooltipLeft: x
		});
	};
	const tooltipStyles = {
		position: 'absolute',
	};
	


	/* Margin */
	const margin = { top: 40, right: 80, bottom: 50, left: 40 };

	/* X axis scale */
	let xMaxNumber = data[5].values[data[5].values.length - 1].round;

	/* Y axis 2 scale */
	let ticSizeY2 = 1e5; // 800.000+
	if (yMaxVal2 <= 1.5e5) {
		ticSizeY2 = 1e4; // < 150.000
	} else if (yMaxVal2 <= 2.8e5) {
		ticSizeY2 = 2e4; // < 280.000
	} else if (yMaxVal2 <= 3.8e5) {
		ticSizeY2 = 2.5e4; // < 380.000
	} else if (yMaxVal2 <= 7e5) {
		ticSizeY2 = 5e4; // < 700.000
	}
	let yMaxNumber2 = Math.ceil(yMaxVal2 / ticSizeY2) * ticSizeY2; 
	let numberOfTicsY2 = yMaxNumber2 / ticSizeY2;

	/* Y axis 1 scale */
	let yMaxNumber1 = Math.ceil(yMaxVal1);
	let ticSizeY1 = 0.5;
	let numberOfTicsY1 = yMaxNumber1 / ticSizeY1;
	
	let ticMod = numberOfTicsY2 % numberOfTicsY1;
	if (ticMod > 0) {
		yMaxNumber1 = yMaxNumber1 + (ticMod / 2.);
		numberOfTicsY1 = numberOfTicsY1 + ticMod;
	}

	const xScale = scaleLinear({domain: [0, xMaxNumber]});
	const yScale1 = scaleLinear({domain: [0, yMaxNumber1]});
	const yScale2 = scaleLinear({domain: [0, yMaxNumber2]});

	/* Axis formats */
	const roundFormat = (value) => {return value.toFixed(0);};
	const unitCostFormat = (value) => {return value.toFixed(0);};
	const costFormat = (value) => {
		let valueString = value.toString();
		if (value >= 1e6) {
			valueString = (value / 1e6).toString() + ' mio';
		} else {
			if (value >= 1000) {
				valueString = 
					valueString.substring(0, valueString.length - 3) + 
					'.' + 
					valueString.substring(valueString.length - 3);
			}
		}
		return valueString;
	};

	/* Get data values */
	const getX = (d) => {return d.round;};
	const getY1 = (d) => {return d.value;};
	const getY2 = (d) => {return d.value2;};

	/* Bounds */
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	xScale.range([0, xMax]);
	yScale1.range([yMax, 0]);
	yScale2.range([yMax, 0]);

	/* Graph too small */
	if (width < 10) return null;

	return (
		<div className="Graph">
			<svg width={width} height={height}>
				{/* Grid, axes and labels */}
				<Group left={margin.left} top={margin.top}>
					<GridRows 
						className="Graph-grid rows" 
						width={xMax} 
						height={yMax} 
						numTicks={numberOfTicsY2}  // updated
						scale={yScale2}
					/>
					<AxisBottom 
						axisClassName="Graph-axis bottom"
						tickClassName="Graph-tic bottom"
						top={yMax} 
						scale={xScale} 
						numTicks={xMaxNumber} 
						// hideZero={true}
						tickLength={16}
						tickFormat={roundFormat}
					/>
					<text x={width / 2. - 75} y={yMax + 40} fontSize={12} fill="#3E57AA">
						{getText(generalUiTexts.rounds2, languageId)}
					</text>
					<AxisLeft 
						axisClassName={'Graph-axis left' + (showUnitCost ? '' : ' hidden')}
						tickClassName={'Graph-tic left' + (showUnitCost ? '' : ' hidden')}
						scale={yScale1} 
						numTicks={numberOfTicsY1 / 2} 
						hideZero={true}
						hideTicks={true}
						tickFormat={unitCostFormat}
					/>
					{showUnitCost && 
						<text x="-35" y="-15" fontSize={12} fill="#3E57AA">
							{getText(generalUiTexts.costPerUnit, languageId)}
						</text>
					}
					<AxisRight 
						axisClassName={'Graph-axis right' + (showCost || showProduction || showDemand ? '' : ' hidden')}
						tickClassName={'Graph-tic right' + (showCost || showProduction || showDemand ? '' : ' hidden')}
						top={0} 
						right={0} 
						left={xMax} 
						height={yMax} 
						scale={yScale2} 
						hideZero={true}
						hideTicks={true}
						numTicks={numberOfTicsY2} 
						tickFormat={costFormat}
					/>
					{(showCost && !showProduction && !showDemand) &&
						<text x={xMax + 15} y="-15" fontSize={12} fill="#3E57AA">
							{getText(generalUiTexts.cost, languageId)}
						</text>
					}
					{(!showCost && (showProduction || showDemand)) &&
						<text x={xMax + 15} y="-15" fontSize={12} fill="#3E57AA">
							{getText(generalUiTexts.units, languageId)}
						</text>
					}
					{(showCost && (showProduction || showDemand)) &&
						<text x={xMax - 10} y="-15" fontSize={12} fill="#3E57AA">
							{getText(generalUiTexts.unitsOrCost, languageId)}
						</text>
					}
				</Group>

				{/* Data */}
				<Group left={margin.left} top={margin.top} height={yMax}>
					{/* Markers */}
					<MarkerCircle id="marker-production" className="Graph-marker production" size={1.5} refX={2}/>
					<MarkerCircle id="marker-cost" className="Graph-marker cost" size={1.5} refX={2} />
					<MarkerCircle id="marker-unitcost" className="Graph-marker unitcost" size={1.5} refX={2} />
					<MarkerCircle id="marker-unitcost2" className="Graph-marker unitcost2" size={1.5} refX={2} />
					<MarkerCircle id="marker-demand" className="Graph-marker demand" size={1.5} refX={2} />

					{/* Demand */}
					{showDemand && <Group>
						<Threshold 
							className="Graph-thresh demand"
							data={data[5].values}
							id="thresh"
							clipAboveTo={0}
							clipBelowTo={yMax}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y0={(d) => {return yScale2(getY1(d)) ?? 0;}}
							y1={(d) => {return yScale2(getY2(d)) ?? 0;}}
							belowAreaProps={{
								fill: '#D8E9FF',
								fillOpacity: 1,
							}}
						/>
						{data[5].values.map((line, index) => {
							return (
								<line 
									key={index}
									className="Graph-line vertical"
									x1={xScale(line.round)} 
									y1={yScale2(line.value)} 
									x2={xScale(line.round)} 
									y2={yScale2(line.value2)} 
								/>
							);
						})}
						<LinePath
							className="Graph-line demand2"
							data={data[5].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale2(getY1(d)) ?? 0;}}
						/>
						<LinePath
							className="Graph-line demand2"
							data={data[5].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale2(getY2(d)) ?? 0;}}
						/>
						<LinePath
							className="Graph-line demand"
							data={data[4].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale2(getY1(d)) ?? 0;}}
							markerStart="url(#marker-demand)"		
							markerMid="url(#marker-demand)"		
							markerEnd="url(#marker-demand)"	
						/>
						
					</Group>}

					{/* Production */}
					{showProduction && <Group>
						<LinePath
							className="Graph-line production"
							data={data[0].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale2(getY1(d)) ?? 0;}}
							markerStart="url(#marker-production)"
							markerMid="url(#marker-production)"
							markerEnd="url(#marker-production)"
						/>
					</Group>}

					{/* Cost */}
					{showCost && <Group>
						<LinePath
							className="Graph-line cost"
							data={data[1].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale2(getY1(d)) ?? 0;}}
							markerStart="url(#marker-cost)"
							markerMid="url(#marker-cost)"
							markerEnd="url(#marker-cost)"
						/>
					</Group>}

					{/* UnitCost */}
					{showUnitCost && <Group>
						<LinePath
							className="Graph-line unitcost2"
							data={data[3].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale1(getY1(d)) ?? 0;}}	
							markerStart="url(#marker-unitcost2)"		
							markerMid="url(#marker-unitcost2)"		
							markerEnd="url(#marker-unitcost2)"			
						/>			
						<LinePath
							className="Graph-line unitcost"
							data={data[2].values}
							x={(d) => {return xScale(getX(d)) ?? 0;}}
							y={(d) => {return yScale1(getY1(d)) ?? 0;}}	
							markerStart="url(#marker-unitcost)"		
							markerMid="url(#marker-unitcost)"		
							markerEnd="url(#marker-unitcost)"			
						/>
					</Group>}

					{/* Tooltip markers */}
					<Group>
						{showDemand && data[5].values.map((line, index) => {
							if (line.value === line.value2) return null;
							let x = xScale(line.round) - xScale(0.1);
							let y = yScale2(line.value2);
							return (
								<rect 
									key={index}
									className="Graph-rect tooltip"
									x={x} 
									y={y} 
									width={xScale(0.2)} 
									height={Math.abs(yScale2(line.value2) - yScale2(line.value))} 
									onMouseLeave={() => {handleMouseLeave();}}
									onMouseOver={() => {
										handleMouseOver([line.value, line.value2], x, y, 'demand2', 'demand2');
									}}
								/>
							);
						})}
						{showDemand &&  data[4].values.map((value, index) => {
							let x = (xScale(getX(value)) ?? 0);
							let y = (yScale2(getY1(value)) ?? 0);
							return <circle
								key={index}
								cx={x}
								cy={y}
								r={6}
								fill="transparent"
								fillOpacity={1}
								onMouseLeave={() => {handleMouseLeave();}}
								onMouseOver={() => {handleMouseOver(value.value, x, y, 'demand', 'demand');}}
							/>;
						})}
						{showProduction && data[0].values.map((value, index) => {
							let x = (xScale(getX(value)) ?? 0);
							let y = (yScale2(getY1(value)) ?? 0);
							return <circle
								key={index}
								cx={x}
								cy={y}
								r={6}
								fill="transparent"
								fillOpacity={1}
								onMouseLeave={() => {handleMouseLeave();}}
								onMouseOver={() => {handleMouseOver(value.value, x, y, 'production', 'production');}}
							/>;
						})}
						{showCost && data[1].values.map((value, index) => {
							let x = (xScale(getX(value)) ?? 0);
							let y = (yScale2(getY1(value)) ?? 0);
							return <circle
								key={index}
								cx={x}
								cy={y}
								r={6}
								fill="transparent"
								fillOpacity={1}
								onMouseLeave={() => {handleMouseLeave();}}
								onMouseOver={() => {handleMouseOver(value.value, x, y, 'cost', 'costs');}}
							/>;
						})}
						{showUnitCost && data[3].values.map((value, index) => {
							if (value === data[2][index]) return null;
							let x = (xScale(getX(value)) ?? 0);
							let y = (yScale1(getY1(value)) ?? 0);
							return <circle
								key={index}
								cx={x}
								cy={y}
								r={6}
								fill="transparent"
								fillOpacity={1}
								onMouseLeave={() => {handleMouseLeave();}}
								onMouseOver={() => {handleMouseOver(value.value, x, y, 'unitcost2', 'unit-cost2');}}
							/>;
						})}							
						{showUnitCost && data[2].values.map((value, index) => {
							let x = (xScale(getX(value)) ?? 0);
							let y = (yScale1(getY1(value)) ?? 0);
							return <circle
								key={index}
								cx={x}
								cy={y}
								r={6}
								fill="transparent"
								fillOpacity={1}
								onMouseLeave={() => {handleMouseLeave();}}
								onMouseOver={() => {handleMouseOver(value.value, x, y, 'unitcost', 'unit-cost');}}
							/>;
						})}
					</Group>
				</Group>
			</svg>
			{tooltipOpen && tooltipData && (
				<Tooltip 
					className={'Graph-toolTip ' + tooltipData.type}
					top={tooltipTop + margin.top} 
					left={tooltipLeft + margin.left} 
					style={tooltipStyles} 
					offsetLeft={0}
					offsetTop={0}
				>
					<div>
						<span>{tooltipData.title}</span><span>{tooltipData.value}</span>
					</div>
				</Tooltip>
			)}	
		</div>
	);
};

Graph.propTypes = {
	showDemand: PropTypes.bool.isRequired,
	showProduction: PropTypes.bool.isRequired,
	showCost: PropTypes.bool.isRequired,
	showUnitCost: PropTypes.bool.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	yMaxVal1: PropTypes.number.isRequired,
	yMaxVal2: PropTypes.number.isRequired,
	languageId: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired
};
export default Graph;