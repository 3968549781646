import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import errorTexts from 'data/error-texts';
import {getText} from 'helpers/language-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import LoginFacilitator from './login-facilitator';

class LoginFacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			feedback: null,
			isLoading: false,
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	}
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		event.preventDefault();
		this.setState({isLoading: true});
		let email = this.state.email;
		let password = this.state.password;
		
		let error = null;
		if (!email || !password) error = 'empty-fields';
		if (!error) {
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					let errorStatus = errorFeedbackHelper(error.code, true);
					let errorMsg = (errorTexts.hasOwnProperty(errorStatus)
						? getText(errorTexts[errorStatus], this.props.languageId)
						: errorStatus
					);
					this.setState({
						feedback: errorMsg,
						isLoading: false
					});
				});
		} else {
			const msg = getText(errorTexts[errorFeedbackHelper(error)], this.props.languageId);
			this.setState({
				feedback: msg,
				isLoading: false
			});
		}
	}


	render() {
		return (
			<LoginFacilitator
				languageId={this.props.languageId}
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
				email={this.state.email}
				password={this.state.password}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
			/>
		);
	}
}

LoginFacilitatorController.propTypes = {
	languageId: PropTypes.string.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
};

export default LoginFacilitatorController;
