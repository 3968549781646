import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {formatNumber} from 'helpers/game-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {settingsUiTexts} from 'data/ui-texts';
import './unit-cost-score-popup.scss';

const UnitCostScorePopup = ({languageId, groups, toggleScorePopup}) => {
	let maxRound = 0;
	let groupScores = groups.map((group) => {
		if (group.roundId > maxRound) maxRound = group.roundId;
		let unitCostScore = 0;
		const rounds = group.rounds.filter((round) => {return (round.id >= 4 && round.id <= 8 && round.results);});
		if (rounds.length > 0) {
			const lastRound = rounds[rounds.length - 1];
			if (lastRound.results && lastRound.results.gameScore) {
				unitCostScore = lastRound.results.gameScore;
			}
		}
		return {id: group.id, title: group.title, roundId: group.roundId, unitCostScore};
	});
	groupScores = sortArrayByProperty(groupScores, 'unitCostScore', 'DESC');

	return (
		<div className="UnitCostScorePopup" onClick={() => {toggleScorePopup(null);}}>
			<div className="UnitCostScorePopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="UnitCostScorePopup-rankings">
					<div className="UnitCostScorePopup-rankingsHeader">
						<div className="UnitCostScorePopup-cell title"><span>Gruppe</span></div>
						<div className="UnitCostScorePopup-cell score">
							<span>{getText(settingsUiTexts.unitCostScore, languageId)}</span>
						</div>
					</div>
					<div className="UnitCostScorePopup-rankingsBody">
						{groupScores.map((group) => {
							return (
								<div key={group.id} className="UnitCostScorePopup-rankingsRow">
									<div className="UnitCostScorePopup-cell title">
										<span>{group.title}</span>
									</div>
									<div className="UnitCostScorePopup-cell score">
										<span>{
											group.unitCostScore 
												? formatNumber(languageId, group.unitCostScore, 2, false)
												: '-'
										}</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				
			</div>
		</div>
	);
};

UnitCostScorePopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	toggleScorePopup: PropTypes.func.isRequired,
};

export default UnitCostScorePopup;
