const popupsData = {
	createGame: {
		title: {
			da: 'Opret nyt spil',
			en: 'Create new game'
		},
		gameTitleLabel: {
			da: 'Hvad vil du kalde spillet?',
			en: 'What would you like to call the game?'
		},
		gameTitlePlaceholder: {
			da: 'spilnavn',
			en: 'game name'
		},
		gamePlayDateLabel: {
			da: 'Hvornår skal spillet afvikles?',
			en: 'When would you like to play the game?'
		},
		gameLanguageLabel: {
			da: 'Hvilket sprog skal spillet være på?',
			en: 'Which language?'
		},
		gameScenarioLabel: {
			da: 'Hvilket scenarie skal spilles?',
			en: 'Which scenario do you want to play?'
		},
		create: {
			da: 'Opret',
			en: 'Create'
		},
		cancel: {
			da: 'Cancel',		
			en: 'Cancel'
		},
	},
	deleteGame: {
		title: {
			da: 'Slet spilforløb',
			en: 'Delete game instance'
		},
		text: {
			da: 'Er du sikker på, du vil slette spilforløbet %gameId%?',
			en: 'Are you sure you want to delete the game instance %gameId%?'
		},
		delete: {
			da: 'Slet',
			en: 'Delete'
		},
		cancel: {
			da: 'Cancel',
			en: 'Cancel'
		}
	},
};

export {popupsData};