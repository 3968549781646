import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import PropTypes from 'prop-types';
import ResetPassword from './reset-password';
import {getText} from 'helpers/language-helper';
import {errorFeedbackHelper} from 'helpers/error-feedback-helper';
import errorTexts from 'data/error-texts';

class ResetPasswordController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			passwordIsReset: false,
			email: null,
			invalidEmail: false,
			feedback: null
		};
		this.resetPasswordReset = this.resetPasswordReset.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInput = this.handleInput.bind(this);
	}

	/**
	 *  Update input fields 
	 */
	handleInput(event) {
		let value = event.target.value;
		this.setState({
			email: value,
			feedback: null,
			invalidEmail: false
		});
	}

	/** 
	 * Handle reset password request 
	 */
	handleSubmit() {
		if (!this.state.email) {
			let errorMsg = getText(errorTexts[errorFeedbackHelper('auth/invalid-email')], this.props.languageId);
			this.setState({
				feedback: errorMsg,
				invalidEmail: true
			});
			return;
		}
		this.resetPasswordReset(this.state.email);
	}

	/** 
	 * Reset password 
	 */
	resetPasswordReset(email) {
		this.setState({isLoading: true});
		const auth = firebase.auth();
		auth.sendPasswordResetEmail(email)
			.then(()=>{
				this.setState({isLoading: false, passwordIsReset: true});
			})
			.catch((error) =>{
				let errorCode = error.code;
				let errorMsg = getText(errorTexts[errorFeedbackHelper(errorCode)], this.props.languageId);
				this.setState({
					feedback: errorMsg,
					isLoading: false,
					invalidEmail: true
				});
			});
	}
	
	/**
	 * Render reset password component
	 */
	render() {
		return (
			<ResetPassword
				isLoading={this.state.isLoading}	
				passwordIsReset={this.state.passwordIsReset}
				languageId={this.props.languageId}
				email={this.state.email}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleSubmit={this.handleSubmit}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
				invalidEmail={this.state.invalidEmail}
			/>
		);
	}
}

ResetPasswordController.propTypes = {
	languageId: PropTypes.string.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired
};

export default ResetPasswordController;


