import Background1 from 'assets/images/areas/background-1.svg';
import Background2 from 'assets/images/areas/background-2.svg';
import Background3 from 'assets/images/areas/background-3.svg';
import Background4 from 'assets/images/areas/background-4.svg';
import BoxClosed from 'assets/images/areas/box-closed.svg';
import BoxOpen from 'assets/images/areas/box-open.svg';
import BoxOpen2 from 'assets/images/areas/box-open-2.svg';
import Cabinet1 from 'assets/images/areas/cabinet-1.svg';
import Cabinet2 from 'assets/images/areas/cabinet-2.svg';
import Clock from 'assets/images/areas/clock.svg';
import Console1 from 'assets/images/areas/console-1.svg';
import Console2 from 'assets/images/areas/console-2.svg';
import Desks1 from 'assets/images/areas/desks-1.svg';
import Desks2 from 'assets/images/areas/desks-2.svg';
import Interface1 from 'assets/images/areas/interface-1.svg';
import Interface2 from 'assets/images/areas/interface-2.svg';
import Interface3 from 'assets/images/areas/interface-3.svg';
import Interface4 from 'assets/images/areas/interface-4.svg';
import Machine1 from 'assets/images/areas/machine-1.svg';
import Machine2 from 'assets/images/areas/machine-2.svg';
import Machine3 from 'assets/images/areas/machine-3.svg';
import Machine4 from 'assets/images/areas/machine-4.svg';
import Machine4b from 'assets/images/areas/machine-4-b.svg';
import MachineBelt1 from 'assets/images/areas/machine-belt-1.svg';
import MachineBelt2 from 'assets/images/areas/machine-belt-2.svg';
import MachineBelt2b from 'assets/images/areas/machine-belt-2-b.svg';
import Monitor1 from 'assets/images/areas/monitor-1.svg';
import Monitor2 from 'assets/images/areas/monitor-2.svg';
import PalletLift from 'assets/images/areas/pallet-lift.svg';
import Pallets1 from 'assets/images/areas/pallets-1.svg';
import Pallets2 from 'assets/images/areas/pallets-2.svg';
import Poster from 'assets/images/areas/poster.svg';
import Staff1a from 'assets/images/staff/staff-1-a.svg';
import Staff1b from 'assets/images/staff/staff-1-b.svg';
import Staff1c from 'assets/images/staff/staff-1-c.svg';
import Staff1d from 'assets/images/staff/staff-1-d.svg';
import Staff1e from 'assets/images/staff/staff-1-e.svg';
import Staff2a from 'assets/images/staff/staff-2-a.svg';
import Staff2b from 'assets/images/staff/staff-2-b.svg';
import Staff2c from 'assets/images/staff/staff-2-c.svg';
import Staff2d from 'assets/images/staff/staff-2-d.svg';
import Staff2e from 'assets/images/staff/staff-2-e.svg';
import Staff2f from 'assets/images/staff/staff-2-f.svg';
import Staff3a from 'assets/images/staff/staff-3-a.svg';
import Staff3b from 'assets/images/staff/staff-3-b.svg';
import Staff3c from 'assets/images/staff/staff-3-c.svg';
import Staff4a from 'assets/images/staff/staff-4-a.svg';
import Staff4b from 'assets/images/staff/staff-4-b.svg';
import Staff4c from 'assets/images/staff/staff-4-c.svg';
import Staff4d from 'assets/images/staff/staff-4-d.svg';
import Staff4e from 'assets/images/staff/staff-4-e.svg';
import Staff4f from 'assets/images/staff/staff-4-f.svg';
import Staff5a from 'assets/images/staff/staff-5-a.svg';
import Staff6a from 'assets/images/staff/staff-6-a.svg';
import Staff6b from 'assets/images/staff/staff-6-b.svg';
import Table from 'assets/images/areas/table.svg';
import Wall1 from 'assets/images/areas/wall-1.svg';
import Wall2 from 'assets/images/areas/wall-2.svg';
import Wall3 from 'assets/images/areas/wall-3.svg';
import WashingMachine1 from 'assets/images/areas/washing-machine-1.svg';
import WashingMachine2 from 'assets/images/areas/washing-machine-2.svg';
import WashingMachine2b from 'assets/images/areas/washing-machine-2-b.svg';
import Windows from 'assets/images/areas/windows.svg';


const objectsData = [
	{id: 'background-1', width: '1536', height: '864', offsetX: 0, offsetY: 0, href: Background1, type: 'background'},
	{id: 'background-2', width: '1536', height: '864', offsetX: 0, offsetY: 0, href: Background2, type: 'background'},
	{id: 'background-3', width: '1536', height: '864', offsetX: 0, offsetY: 0, href: Background3, type: 'background'},
	{id: 'background-4', width: '1536', height: '864', offsetX: 0, offsetY: 0, href: Background4, type: 'background'},
	{id: 'box-closed', width: '87', height: '93', href: BoxClosed},
	{id: 'box-open', width: '120', height: '94', href: BoxOpen},
	{id: 'box-open-2', width: '120', height: '94', href: BoxOpen2},
	{id: 'cabinet-1', width: '262', height: '339', href: Cabinet1},
	{id: 'cabinet-2', width: '263', height: '325', href: Cabinet2},
	{id: 'clock', width: '38', height: '45', href: Clock},
	{id: 'console-1', width: '70', height: '149', href: Console1},
	{id: 'console-2', width: '70', height: '154', href: Console2},
	{id: 'desks-1', width: '617', height: '306', href: Desks1},
	{id: 'desks-2', width: '755', height: '403', href: Desks2},
	{id: 'interface-1', width: '70', height: '70', href: Interface1},
	{id: 'interface-2', width: '63', height: '82', href: Interface2},
	{id: 'interface-3', width: '33', height: '49', href: Interface3},
	{id: 'interface-4', width: '33', height: '54', href: Interface4},
	{id: 'machine-1', width: '573', height: '405', href: Machine1},
	{id: 'machine-2', width: '443', height: '390', href: Machine2},
	{id: 'machine-3', width: '445', height: '396', href: Machine3},
	{id: 'machine-4', width: '445', height: '390', href: Machine4},
	{id: 'machine-4-b', width: '445', height: '446', href: Machine4b},
	{id: 'machine-belt-1', width: '755', height: '356', href: MachineBelt1},
	{id: 'machine-belt-2', width: '598', height: '522', href: MachineBelt2},
	{id: 'machine-belt-2-b', width: '598', height: '537', href: MachineBelt2b},
	{id: 'monitor-1', width: '108', height: '101', href: Monitor1},
	{id: 'monitor-2', width: '108', height: '101', href: Monitor2},
	{id: 'pallet-lift', width: '336', height: '289', href: PalletLift},
	{id: 'pallets-1', width: '474', height: '340', href: Pallets1},
	{id: 'pallets-2', width: '627', height: '488', href: Pallets2},
	{id: 'poster', width: '37', height: '78', href: Poster},
	{id: 'staff-1-a', width: '63', height: '183', href: Staff1a, offsetY: 104.5},
	{id: 'staff-1-b', width: '51', height: '183', href: Staff1b},
	{id: 'staff-1-c', width: '51', height: '183', href: Staff1c},
	{id: 'staff-1-d', width: '56', height: '173', href: Staff1d},
	{id: 'staff-1-e', width: '56', height: '173', href: Staff1e},
	{id: 'staff-2-a', width: '70', height: '173', href: Staff2a},
	{id: 'staff-2-b', width: '70', height: '173', href: Staff2b},
	{id: 'staff-2-c', width: '56', height: '173', href: Staff2c},
	{id: 'staff-2-d', width: '62', height: '183', href: Staff2d},
	{id: 'staff-2-e', width: '51', height: '183', href: Staff2e},
	{id: 'staff-2-f', width: '63', height: '183', href: Staff2f},
	{id: 'staff-3-a', width: '65', height: '173', href: Staff3a},
	{id: 'staff-3-b', width: '64', height: '173', href: Staff3b},
	{id: 'staff-3-c', width: '64', height: '173', href: Staff3c},
	{id: 'staff-4-a', width: '75', height: '173', href: Staff4a},
	{id: 'staff-4-b', width: '81', height: '183', href: Staff4b},
	{id: 'staff-4-c', width: '81', height: '183', href: Staff4c},
	{id: 'staff-4-d', width: '58', height: '175', href: Staff4d},
	{id: 'staff-4-e', width: '58', height: '175', href: Staff4e},
	{id: 'staff-4-f', width: '92', height: '178', href: Staff4f},
	{id: 'staff-5-a', width: '87', height: '190', href: Staff5a},
	{id: 'staff-6-a', width: '100', height: '142', href: Staff6a},
	{id: 'staff-6-b', width: '105', height: '156', href: Staff6b},
	{id: 'table', width: '346', height: '221', href: Table},
	{id: 'wall-1', width: '511', height: '677', href: Wall1},
	{id: 'wall-2', width: '616', height: '775', href: Wall2},
	{id: 'wall-3', width: '1012', height: '880', href: Wall3},
	{id: 'washing-machine-1', width: '302', height: '359', href: WashingMachine1},
	{id: 'washing-machine-2', width: '336', height: '354', href: WashingMachine2},
	{id: 'washing-machine-2-b', width: '336', height: '359', href: WashingMachine2b},
	{id: 'windows', width: '399', height: '363', href: Windows},
];

export {objectsData};