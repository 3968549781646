import {generalUiTexts} from './ui-texts';

const parametersData = {
	'materials': {
		title: {
			da: 'Råvarer',
			en: 'Raw materials'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'material-cost-per-unit': {
		title: {
			da: 'Materialepris per unit',
			en: 'Material price per unit'
		},
		unit: generalUiTexts.costPerUnit,
		decimals: 0,
		unitSpace: true
	},
	'operators': {
		title: {
			da: 'Operatører',
			en: 'Operators'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'production-support': {
		title: {
			da: 'Produktionssupport',
			en: 'Production support'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'machines': {
		title: {
			da: 'Maskinafskrivninger',
			en: 'Depreciation'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'office-staff': {
		title: {
			da: 'Kontorpersonale',
			en: 'Office staff'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'storage-staff': {
		title: {
			da: 'Lagerpersonale',
			en: 'Warehouse staff'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'expenses': {
		title: {
			da: 'Øvrige løbende udgifter',
			en: 'Other running costs'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'equipment-capacity': {
		title: {
			da: 'Udstyrskapacitet',
			en: 'Equipment capacity'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'operator-teams': {
		title: {
			da: 'Operatørskiftehold',
			en: 'Operator shifts'
		},
		costPerUnitProperty: 'costPerOperator',
		unit: generalUiTexts.outOf5,
		decimals: 0,
		unitSpace: false,
		maxVal: 5,
		minVal: 2,
	},
	'support-teams': {
		title: {
			da: 'Prod.support-skiftehold',
			en: 'Production support shift'
		},
		costPerUnitProperty: 'costPerSupporter',
		unit: generalUiTexts.outOf3,
		decimals: 0,
		unitSpace: false,
		maxVal: 3,
		minVal: 1
	},
	'effective-capacity': {
		title: {
			da: 'Effektiv kapacitet',
			en: 'Effective capacity'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'input-from-prev-step': {
		title: {
			da: 'Input fra forrige trin',
			en: 'Input from previous step'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'waste': {
		title: {
			da: 'Spild',
			en: 'Scrap'
		},
		unit: generalUiTexts.percent,
		unitSpace: false,
		decimals: 2
	},
	'inventory-primo': {
		title: {
			da: 'Lagerbeholdning primo',
			en: 'Opening inventory'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'production-no-actions': {
		title: {
			da: 'Aktuel produktion',
			en: 'Current production'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'expected-demand': {
		title: {
			da: 'Forventet demand',
			en: 'Expected demand'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'expected-inventory-ultimo': {
		title: {
			da: 'Lagerbeholdning ultimo',
			en: 'End-of-turn inventory'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'expected-bought-units': {
		title: {
			da: 'Forventet indkøbte units (til 1,5 x pris)',
			en: 'Expected units to be bought (at 1.5 x price)'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'total': {
		title: {
			da: 'Samlet',
			en: 'Total'
		},
		unit: generalUiTexts.nb,
		decimals: 0,
		unitSpace: true
	},
	'throughput': {
		title: {
			da: 'Throughput',
			en: 'Throughput'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'prev-area-throughput': {
		title: {
			da: 'Input fra forrige afsnit',
			en: 'Input from previous step'
		},
		unit: generalUiTexts.units,
		decimals: 0,
		unitSpace: true
	},
	'unitcost': {
		title: {
			da: 'Unit Cost',
			en: 'Unit Cost'
		},
		unit: generalUiTexts.costPerUnit,
		unitSpace: true,
		decimals: 1
	}
};

export {parametersData}