const workEnvironmentData = [
	{
		level: 'bad', 
		minVal: 1, 
		maxVal: 3, 
		title: {
			da: 'dårligt', 
			en: 'bad'
		},
		bonusText: {
			da: 'ingen bonus',
			en: 'no bonus'
		}
	},
	{
		level: 'ok', 
		minVal: 4, 
		maxVal: 6, 
		title: {
			da: 'OK', 
			en: 'OK'
		},
		bonusText: {
			da: '10.000 NB til at dække faste udgifter',
			en: '10,000 NB to cover running costs'
		}
	},
	{
		level: 'good', 
		minVal: 7, 
		maxVal: 9, 
		title: {
			da: 'godt', 
			en: 'good'
		},
		bonusText: {
			da: '20.000 NB til at dække faste udgifter',
			en: '20,000 NB to cover running costs'
		}
	}
];

export {workEnvironmentData};