import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {popupsData} from 'data/popups-game-data';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './info-popup.scss';

const InfoPopup = ({isLoading, showInfoPopup, roundId, languageId, handleToggleInfoPopup}) => {
	return (
		<div 
			className={'InfoPopup round-' + roundId +
				(isLoading ? ' loading' : '') + 
				(showInfoPopup ? ' InfoPopup--show' : '')
			}
			onClick={() => {handleToggleInfoPopup();}} 
		>
			<div className="InfoPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="InfoPopup-header">
					{!isLoading && <div onClick={() => {handleToggleInfoPopup();}} className="InfoPopup-closeBtn" />}
					{isLoading && <div className="InfoPopup-loadingIcon" />}
					<div className="InfoPopup-title">{getText(popupsData.info.title, languageId)}</div>
				</div>
				<div className="InfoPopup-body">
					<div id="InfoPopupText" className="InfoPopup-text">
						{renderMarkdown(getText(popupsData.info.text, languageId))}
					</div>
					{!isLoading && 
						<div className="InfoPopup-startBtn" onClick={() => {handleToggleInfoPopup();}}>
							{getText(generalUiTexts.start, languageId)}
						</div>
					}
				</div>
			</div>
		</div>
	);
};

InfoPopup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	showInfoPopup: PropTypes.bool.isRequired,
	roundId: PropTypes.number.isRequired,
	languageId: PropTypes.string.isRequired,
	handleToggleInfoPopup: PropTypes.func.isRequired,
};

export default InfoPopup;
