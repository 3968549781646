const scenariosData = [
	{
		id: 'scenario-1',
		title: {
			da: 'Scenarie 1 - høj demand',
			en: 'Scenario 1 - high demand'
		}
	},
	{
		id: 'scenario-2',
		title: {
			da: 'Scenarie 2 - flad demand',
			en: 'Scenario 2 - flat demand'
		}
	},
	{
		id: 'scenario-3',
		title: {
			da: 'Scenarie 3 - lav demand',
			en: 'Scenario 3 - low demand'
		}
	}
];

export {scenariosData};