import {actionsData} from 'data/actions-data';

/**
 * Get status of object (visible / hidden)
 * @param {string} areaId
 * @param {object} areaStats
 * @param {object} groupRound
 * @param {object} object 
 * @param {array} selectedActions 
 */
const getStatusOfObject = (areaId, areaStats, groupRound, object, group) => {
	let status = 'visible';

	if (object.hasOwnProperty('conditionType')) {
		// Default status if object has a condition
		status = 'hidden';

		// Condition type: parameter 
		if (object.conditionType === 'parameter' && areaStats.hasOwnProperty([object.parameterId])) {
			let parameterValue = areaStats[object.parameterId];
			group.selectedActions.forEach((action) => {
				let actionData = actionsData.find((a) => {return a.id === action.id;});
				if (actionData.areaId === areaId && action.parameterId === object.parameterId) {
					parameterValue = parameterValue + action.parameterAdjustment;
				}
			});
			if (parameterValue >= object.minVal) status = 'visible';
		}

		// Condition type: machine level
		if (object.conditionType === 'machine-level' && areaStats['machine-level']) {
			let machineLevel = areaStats['machine-level'];
			group.selectedActions.forEach((action) => {
				let actionData = actionsData.find((a) => {return a.id === action.id;});				
				if (
					actionData.areaId === areaId && 
					actionData.effects &&
					actionData.effects.some((effect) => {return effect.type === 'machine-level';})
				) {
					let effect = actionData.effects.find((effect) => {return effect.type === 'machine-level';});
					machineLevel = effect.level;
				}
			});
			if (machineLevel === object.level) status = 'visible';
		}

		// Condition type: action
		if (object.conditionType === 'action-selected' || 'action-not-selected') {
			let allSelectedActions = JSON.parse(JSON.stringify(group.selectedActions));
			group.rounds.forEach((round) => {
				if (round.selectedActions && round.selectedActions.length > 0) {
					allSelectedActions.push(...round.selectedActions);
				}				
			});
			let actionIsSelected = allSelectedActions.some((action) => {return action.id === object.actionId;});
			if (
				(object.conditionType === 'action-selected' && actionIsSelected) ||
				(object.conditionType === 'action-not-selected' && !actionIsSelected)
			) status = 'visible';
		}
	}

	return status;
};




export {
	getStatusOfObject
};