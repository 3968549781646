import React from 'react';
import PropTypes from 'prop-types';
import {tooltipsData} from 'data/tooltips-data';
import {teamsData} from 'data/teams-data';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './tooltip.scss';

const Tooltip = ({roundId, languageId, areaId, tooltipId, areaStats}) => {
	let showTooltip = (tooltipId && tooltipsData.hasOwnProperty(tooltipId) ? true : false);
	let tooltipData = null, text = null, table = null, tableRows = null, machineWriteoffs = null;
	if (showTooltip) {
		tooltipData = JSON.parse(JSON.stringify(tooltipsData[tooltipId]));
		text = tooltipData.text ? getText(tooltipData.text, languageId) : null;
		table = tooltipData.table ? tooltipData.table : null;
		tableRows = tooltipData.table && tooltipData.table.rows ? tooltipData.table.rows : null;

		if (tooltipData.type === 'machine-write-offs') {
			machineWriteoffs = areaStats['machine-write-offs'].filter((wo) => {
				return (
					(!wo.hasOwnProperty('delay') || wo.delay === 0) && 
					wo.rounds[0] <= roundId && roundId <= wo.rounds[1]
				);
			});
		}
		if (tooltipId === 'operator-teams') {
			if (areaStats['operator-efficiency-modifier-types'].length > 0) {
				if (areaStats['operator-efficiency-modifier-types'].indexOf('work-environment') >= 0) {
					text = text + ' ' + getText(tooltipData.workEnvModifierText, languageId);
				}
			}
		}
		if (tooltipId === 'production-teams') {
			let isNewMachine = false;
			let isRepairedMachine = false;
			let isOldestMachine = false;
			if (areaStats['machine-level'] && areaStats['machine-level'] === 2) {
				// new machine
				if (table && table.rows2) tableRows = table.rows2;
				text = text + ' ' + getText(tooltipData.newMachineText, languageId);	
				isNewMachine = true;
			}
			if (areaStats['support-efficiency-modifier-types'].length > 0) {
				if (
					areaStats['support-efficiency-modifier-types'].indexOf('repair-implementation') >= 0 ||
					areaStats['support-efficiency-modifier-types'].indexOf('repair-bonus') >= 0
				) {
					// machine repair
					text = text + ' ' + getText(tooltipData.repairText, languageId);
					isRepairedMachine = true;
				}
				if (areaStats['support-efficiency-modifier-types'].indexOf('old-machine') >= 0) {
					// oldest machine (round 6 effect)
					text = text + ' ' + getText(tooltipData.oldMachineText2, languageId);
					isOldestMachine = true;
				}
			}
			if (!isNewMachine && !isRepairedMachine && !isOldestMachine) {
				text = text + ' ' + getText(tooltipData.oldMachineText, languageId);
			}
		}
	}
	return (
		<div className={'Tooltip ' + areaId + (showTooltip ? ' show' : '')}>
			{text && <div className="Tooltip-text">{renderMarkdown(text)}</div>}
			{table && <div className="Tooltip-table">
				<div className="Tooltip-tableRow header">
					{table.header.map((cell, index) => {
						return <div key={'cell-' + index} className="Tooltip-tableCell">
							{getText(cell.value, languageId)}
						</div>;
					})}
				</div>
				{tableRows.map((row, index) => {
					if (!row) return null;
					return (
						<div key={'row-' + index} className="Tooltip-tableRow">
							{row.map((cell, index) => {
								let value = cell.value;
								let bonus = null;
								let minus = null;
								if (tooltipId === 'operator-teams' && index === 1) {
									if (
										areaStats.hasOwnProperty('operator-efficiency-bonus') && 
										areaStats['operator-efficiency-bonus'] > 0
									) {
										bonus = '+' + areaStats['operator-efficiency-bonus'] * 100 + '%';
									}
									if (
										areaStats.hasOwnProperty('operator-efficiency-minus') && 
										areaStats['operator-efficiency-minus'] < 0
									) {
										minus = areaStats['operator-efficiency-minus'] * 100 + '%';
									}
								}
								if (value === '%support-team-efficiency%') {
									let machineLevelData = teamsData['support-teams'][areaId]['level-1'];
									value = machineLevelData.efficiency[row[index - 1].value] * 100 + '%';
									if (areaStats['machine-level'] === 2) {
										let machineLevel2Data = teamsData['support-teams'][areaId]['level-2'];
										let value1 = machineLevelData.efficiency[row[index - 1].value] * 100;
										let value2 = machineLevel2Data.efficiency[row[index - 1].value] * 100;
										bonus = '+' + (value2 - value1) + '%';
									}
									if (
										areaStats.hasOwnProperty('support-efficiency-bonus') && 
										areaStats['support-efficiency-bonus'] > 0
									) {
										bonus = '+' + areaStats['support-efficiency-bonus'] * 100 + '%';
									}
									if (
										areaStats.hasOwnProperty('support-efficiency-minus') && 
										areaStats['support-efficiency-minus'] < 0
									) {
										minus = areaStats['support-efficiency-minus'] * 100 + '%';
									}
								}
								let modifiers = null;
								if (bonus || minus) {
									modifiers = <span>&nbsp;(
										<span className="green">{bonus}</span>
										{bonus && minus ? ', ' : ''}
										<span className="red">{minus}</span>
									)</span>;
								}
								return (
									<div key={'cell-' + index} className="Tooltip-tableCell">
										{value}
										{modifiers ? modifiers : null}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>}
			{machineWriteoffs && <div className="Tooltip-machineWriteOffs">
				{machineWriteoffs.length === 0 && <p>{getText(generalUiTexts.noMachineWriteoffs, languageId)}</p>}
				{machineWriteoffs.map((wo, index) => {
					let roundsLeft = wo.rounds[1] - roundId + 1;
					return (
						<div key={index} className="Tooltip-machineWriteOff">
							{getText(wo.label, languageId) + ' '}
							({getText(generalUiTexts.writtenOffIn, languageId) + ' ' }
							{roundsLeft} {roundsLeft > 1 
								? getText(generalUiTexts.rounds, languageId) 
								: getText(generalUiTexts.round, languageId)
							})
						</div>
					);
				})}
			</div>}

		</div>
	);
};

Tooltip.propTypes = {
	roundId: PropTypes.number.isRequired,	
	languageId: PropTypes.string.isRequired,
	areaId: PropTypes.string.isRequired,
	tooltipId: PropTypes.string,
	areaStats: PropTypes.object.isRequired
};

export default Tooltip;
