import React, {Component} from 'react';
import PropTypes from 'prop-types';
import apiHelper from 'helpers/api-helper';
import Settings from './settings';
import StatsController from 'components/stats/stats-controller';

class SettingsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCreatingGame: false,
			isDeletingGame: false,
			showCreateGamePopup: false,
			showDeleteGamePopup: false,
			page: 'settings',
			gameId: null,
			errMsg: null
		};
	}

	/**
	 * Go to page
	 * @param {string} page 
	 */
	goToPage = (page) => {
		this.setState({page});
	}

	/**
	 * Toggle create game popup
	 */
	toggleCreateGamePopup = () => {
		let showCreateGamePopup = !this.state.showCreateGamePopup;
		this.setState({showCreateGamePopup});
	}

	/**
	 * Create game
	 */
	handleCreateGame = (gameTitle, gameDate, gameLanguage, gameScenario) => {
		if (this.state.isCreatingGame || this.state.isCreatingGame) return;
		this.setState({isCreatingGame: true}, () => {
			apiHelper('facilitator/create-game', {
				userId: this.props.userId, 
				gameTitle, 
				gameDate,
				gameLanguage,
				gameScenario
			}).then(
				() => {
					// Success
					this.setState({isCreatingGame: false, showCreateGamePopup: false});
				},
				(rejection) => {
					// Error
					console.error(rejection);
					this.setState({isCreatingGame: false});
				}
			);
		});
	}

	/**
	 * Toggle delete game popup
	 * @param {string} gameId 
	 */
	toggleDeleteGamePopup = (gameId) => {
		let showDeleteGamePopup = (gameId ? true : false);
		this.setState({showDeleteGamePopup, gameId});
	}

	/**
	 * Delete game
	 * @param {string} gameId 
	 */
	handleDeleteGame = (gameId) => {
		if (this.state.isCreatingGame || this.state.isDeletingGame) return;
		this.setState({isDeletingGame: true}, () => {
			apiHelper('facilitator/delete-game', {
				userId: this.props.userId,
				gameId: gameId,
			}).then(
				() => {
					// Success
					this.setState({isDeletingGame: false, showDeleteGamePopup: false});
				},
				(rejection) => {
					// Error
					console.log(rejection);
					this.setState({isDeletingGame: false});
				}
			);
		});
	}

	/**
	 * Render component
	 */
	render() {
		/* Stats page */
		if (this.props.userData.isAdmin && this.state.page === 'stats') {
			return (
				<StatsController 
					userData={this.props.userData}
					goToPage={this.goToPage}
					handleLogout={this.props.handleLogout} 
				/>
			);
		}

		/* Settings page */
		return (
			<Settings		
				isAdmin={(this.props.userData.isAdmin === true ? true : false)}	
				showCreateGamePopup={this.state.showCreateGamePopup}
				showDeleteGamePopup={this.state.showDeleteGamePopup}
				isCreatingGame={this.state.isCreatingGame}	
				isDeletingGame={this.state.isDeletingGame}
				userData={this.props.userData} 
				gameId={this.state.gameId}
				games={this.props.games}
				goToPage={this.goToPage}
				toggleCreateGamePopup={this.toggleCreateGamePopup}
				toggleDeleteGamePopup={this.toggleDeleteGamePopup}
				handleCreateGame={this.handleCreateGame}
				handleDeleteGame={this.handleDeleteGame}
				updateUser={this.props.updateUser}
				updateGame={this.props.updateGame}
				handleGoToGame={this.props.handleGoToGame}
				handleLogout={this.props.handleLogout} 
			/>
		);
	}
}

SettingsController.propTypes = {
	userId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	games: PropTypes.array.isRequired,	
	updateUser: PropTypes.func.isRequired,
	updateGame: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default SettingsController;
