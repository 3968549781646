
const subAreasData = {
	personnel: {
		title: {
			da: 'Bemanding',
			en: 'Personnel'
		}
	},
	materials: {
		title: {
			da: 'Råvarer',
			en: 'Raw materials'
		}
	}, 
	machines: {
		title: {
			da: 'Maskinel',
			en: 'Machines'
		}
	},
	office: {
		title: {
			da: 'Kontor',
			en: 'Office'
		}
	},
	storage: {
		title: {
			da: 'Lager',
			en: 'Inventory'
		}
	},
	initiatives: {
		title: {
			da: 'Fælles initiativer',
			en: 'Joint initiatives'
		}
	}
};

export {subAreasData};