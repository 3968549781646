import {languagesData} from 'data/languages-data';
import {areasData} from 'data/areas-data';
import {teamsData} from 'data/teams-data';
import {roundsData} from 'data/rounds-data';
import {actionsData} from 'data/actions-data';
import {getReportSnippets} from 'helpers/report-helper';
import appConfig from 'config/app.config';
import { workEnvironmentData } from 'data/work-environment-data';

/**
 * Format number
 * @param {string} languageId
 * @param {any} number 
 * @param {number} decimals 
 * @param {bool} isRange 
 * @param {bool} showFullNumbers 
 * @returns 
 */
const formatNumber = (languageId, number, decimals = 0, isRange = false, showFullNumbers = false) => {
	let formattedNumber = number;

	let localization = languagesData.find((l) => {return l.id === languageId;}).localization;

	if (isRange) {
		if (number[0] !== number[1]) {
			formattedNumber = 
				(showFullNumbers 
					? parseFloat(Math.round(number[0]).toLocaleString(localization)) 
					: Math.round(number[0] / 1000.)) + '-' + 
				parseFloat(Math.round(number[1])).toLocaleString(localization);
		} else {
			formattedNumber = Math.floor(parseFloat(number[0])).toLocaleString(localization);
		}
	} else {
		if (number >= 1000) {
			formattedNumber = Math.round(parseFloat(formattedNumber)).toLocaleString(localization);
		} else {
			formattedNumber = parseFloat(parseFloat(number).toFixed(decimals))
				.toLocaleString(localization, { minimumFractionDigits: decimals });
		}
	}
	return formattedNumber;
};

/**
 * Get stats of all areas for specific round (ignoring selected actions)
 * @param {string} scenarioId
 * @param {object} group
 * @param {number} roundId
 */
const calculateRoundStats = (scenarioId, group, roundId) => {

	// Get group data for current round
	const roundData = roundsData.find((round) => {return round.id === roundId;});
	const groupRoundData = group.rounds.find((round) => {return round.id === roundId;});
	const groupUpgrades = (group.upgrades ? group.upgrades : []);

	// Prepare area stats object
	let roundStats = {};
	areasData.forEach((area) => {roundStats[area.id] = {};});

	// Get active group updates 
	let activeGroupUpgrades = groupUpgrades.filter((upgrade) => {
		return (
			!upgrade.hasOwnProperty('rounds') || 
			(upgrade.rounds[0] <= roundId && roundId <= upgrade.rounds[1]));
	});

	// Calculate costs related numbers
	let minEffCapacity = null;

	// Independent stats
	roundStats['work-environment'] = groupRoundData.workEnvironment;
	areasData.forEach((area) => {
		let groupAreaData = groupRoundData[area.id];

		// Material costs
		if (area.id !== 'office-and-storage') {
			roundStats[area.id]['material-cost-per-unit'] = 0;
			if (groupRoundData[area.id]['material-cost-per-unit']) {
				roundStats[area.id]['material-cost-per-unit'] = groupRoundData[area.id]['material-cost-per-unit'];
			}
		}

		// Size and cost of operator & support teams
		if (area.id !== 'office-and-storage') {
			roundStats[area.id]['operator-teams'] = groupRoundData[area.id]['operator-teams'];
			roundStats[area.id]['operators'] = 0;
			if (area.hasOwnProperty('costPerOperator') && groupAreaData.hasOwnProperty('operator-teams')) {
				roundStats[area.id]['operators'] = (groupAreaData['operator-teams'] * area.costPerOperator);			
			}	

			roundStats[area.id]['support-teams'] = groupRoundData[area.id]['support-teams'];
			roundStats[area.id]['production-support'] = 0;
			if (area.hasOwnProperty('costPerSupporter') && groupAreaData.hasOwnProperty('support-teams')) {
				roundStats[area.id]['production-support'] = (groupAreaData['support-teams'] * area.costPerSupporter);
			}
		}

		// Cost of office and storage teams
		if (area.id === 'office-and-storage') {
			roundStats[area.id]['office-teams'] = groupAreaData['office-teams'];
			roundStats[area.id]['office-staff'] = 0;
			if (area.hasOwnProperty('costPerOfficeTeam') && groupAreaData.hasOwnProperty('office-teams'))	{
				roundStats[area.id]['office-staff'] = groupAreaData['office-teams'] * area.costPerOfficeTeam;
			}

			roundStats[area.id]['storage-teams'] = groupAreaData['storage-teams'];
			roundStats[area.id]['storage-staff'] = 0;
			if (area.hasOwnProperty('costPerStorageTeam') && groupAreaData.hasOwnProperty('storage-teams')) {
				roundStats[area.id]['storage-staff'] = (groupAreaData['storage-teams'] * area.costPerStorageTeam);
			}
		}

		// Machine level
		roundStats[area.id]['machine-level'] = null;
		if (groupAreaData['machine-level']) {
			roundStats[area.id]['machine-level'] = groupAreaData['machine-level'];
		}

		// Waste
		roundStats[area.id]['waste'] = groupRoundData[area.id]['waste'] ? groupRoundData[area.id]['waste'] : 0;

		// Other expenses and write offs
		roundStats[area.id]['expenses'] = groupAreaData.expenses;
		if (groupAreaData['expenses-write-offs']) {
			let activeExpensesWriteoffs = groupAreaData['expenses-write-offs'].filter((wo) => {
				return (
					(!wo.hasOwnProperty('delay') || wo.delay === 0) && 
					wo.rounds[0] <= roundId && roundId <= wo.rounds[1]);
			});
			roundStats[area.id]['expenses'] = roundStats[area.id]['expenses'] + 
				activeExpensesWriteoffs.reduce((prev, cur) => {
					return (prev + cur.cost);
				}, 0);
		}

		// Machine write offs
		roundStats[area.id]['machine-write-offs'] = groupAreaData['machine-write-offs'];
		
		let activeMachineWriteoffs = roundStats[area.id]['machine-write-offs'].filter((wo) => {
			return (
				(!wo.hasOwnProperty('delay') || wo.delay === 0) && 
				wo.rounds[0] <= roundId && roundId <= wo.rounds[1]);
		});
		roundStats[area.id]['machines'] = activeMachineWriteoffs.reduce((prev, cur) => {
			return (prev + cur.cost);
		}, 0);

		// Expected demand
		if (area.id === 'office-and-storage') {
			roundStats[area.id]['expected-demand'] = roundData['expected-demand'][scenarioId];
			if (activeGroupUpgrades.some((upgrade) => {
				return upgrade.type === 'reduce-demand-uncertainty';
			})) {
				roundStats[area.id]['expected-demand'] = [
					roundData['actual-demand'][scenarioId], 
					roundData['actual-demand'][scenarioId]
				];
			}
		}

		// Machine
		if (area.id !== 'office-and-storage' && roundStats[area.id]['machine-level']) {
			// Machine level data
			let machineLevelData = teamsData['support-teams'][area.id]['level-' + roundStats[area.id]['machine-level']];

			// Machine capacity
			roundStats[area.id]['equipment-capacity'] = machineLevelData.capacity;
			roundStats[area.id]['equipment-rampup'] = 0;
			activeGroupUpgrades.forEach((upgrade) => {
				if (upgrade.areaId === area.id) {
					if (upgrade.type === 'machine-capacity-modifier') {
						roundStats[area.id]['equipment-rampup'] = upgrade.value;
						roundStats[area.id]['equipment-capacity'] = 
							roundStats[area.id]['equipment-capacity'] * upgrade.value;
					}
				}
			});

			// Operator efficiency
			roundStats[area.id]['operator-efficiency'] = 
				teamsData['operator-teams'].efficiency[roundStats[area.id]['operator-teams']];
			roundStats[area.id]['operator-efficiency-minus'] = 0;
			roundStats[area.id]['operator-efficiency-bonus'] = 0;
			roundStats[area.id]['operator-efficiency-modifier-types'] = [];
			teamsData['operator-teams'].modifiers.forEach((modifier) => {
				if (modifier.type === 'work-environment') {
					if (
						modifier.minVal <= groupRoundData.workEnvironment && 
						groupRoundData.workEnvironment <= modifier.maxVal
					) {
						roundStats[area.id]['operator-efficiency'] = 
							roundStats[area.id]['operator-efficiency'] + modifier.effect;
						roundStats[area.id]['operator-efficiency-modifier-types'].push('work-environment');
						if (modifier.effect > 0) {
							roundStats[area.id]['operator-efficiency-bonus'] = modifier.effect;
						} else {
							roundStats[area.id]['operator-efficiency-minus'] = modifier.effect;;
						}

					}
				}
			}); 

			// Support efficiency
			roundStats[area.id]['support-efficiency'] = 
				machineLevelData.efficiency[roundStats[area.id]['support-teams']];
			roundStats[area.id]['support-efficiency-bonus'] = 0;
			roundStats[area.id]['support-efficiency-minus'] = 0;
			roundStats[area.id]['support-efficiency-modifier-types'] = [];
			activeGroupUpgrades.forEach((upgrade) => {
				if (upgrade.type === 'support-efficiency-modifier' && upgrade.areaId === area.id) {
					roundStats[area.id]['support-efficiency'] = 
						roundStats[area.id]['support-efficiency'] + upgrade.value;
					if (upgrade.modifierType) {
						roundStats[area.id]['support-efficiency-modifier-types'].push(upgrade.modifierType);
					} else {
						console.warn('Upgrade has no modifierType: ', upgrade);
					}
					if (upgrade.value > 0) {
						roundStats[area.id]['support-efficiency-bonus'] = upgrade.value;
					} else {
						roundStats[area.id]['support-efficiency-minus'] = upgrade.value;
					}
				}
			});

			// Effective capacity (equipment capacity x operator efficiency x support efficiency)
			roundStats[area.id]['effective-capacity'] = roundStats[area.id]['equipment-capacity']
				* roundStats[area.id]['operator-efficiency'] * roundStats[area.id]['support-efficiency'];

			// Track min value of effective capacity (ignoring "office & storage" area)
			if (!minEffCapacity || roundStats[area.id]['effective-capacity'] < minEffCapacity) {
				minEffCapacity = roundStats[area.id]['effective-capacity'];
			}		
		}
	});

	// Calculate throughput
	areasData.filter((area) => {return area.id !== 'office-and-storage';}).forEach((area, index) => {
		let currentAreaEffCap = roundStats[area.id]['effective-capacity'];
		let currentAreaWaste = roundStats[area.id]['waste'];

		if (index === 0) { 
			let nextAreaEffCap = roundStats[areasData[index + 1].id]['effective-capacity'];
			let maxThroughput = currentAreaEffCap * (1 - (currentAreaWaste / 100.));
			
			roundStats[area.id]['throughput'] = (maxThroughput > nextAreaEffCap
				? nextAreaEffCap
				: maxThroughput
			);
		} else {
			let prevAreaThroughput = roundStats[areasData[index - 1].id]['throughput'];
			let maxThroughput = Math.min(prevAreaThroughput, currentAreaEffCap) * (1 - (currentAreaWaste / 100.));
			roundStats[area.id]['prev-area-throughput'] = prevAreaThroughput;
			if (index === 1) {
				let nextAreaEffCap = roundStats[areasData[index + 1].id]['effective-capacity'];
				roundStats[area.id]['throughput'] = (maxThroughput > nextAreaEffCap
					? nextAreaEffCap
					: maxThroughput
				);
			} else {
				roundStats[area.id]['throughput'] = maxThroughput;
			}
		}
	});
	roundStats['office-and-storage']['throughput'] = roundStats['filling-and-inspection']['throughput'];
	roundStats['office-and-storage']['production-no-actions'] = roundStats['office-and-storage']['throughput'];
	let finalThroughput = roundStats['office-and-storage']['throughput'];

	// Calculate inventory (only Office & Storage)
	areasData.filter((area) => {return area.id === 'office-and-storage';}).forEach((area) => {
		let inventoryPrimo = groupRoundData['inventory-primo'] ? groupRoundData['inventory-primo'] : 0;
		roundStats['office-and-storage']['inventory-primo'] = inventoryPrimo;
		roundStats['office-and-storage']['expected-bought-units'] = null;
		let adjustedInventoryPrimo = inventoryPrimo * (1 - (roundStats[area.id]['waste'] / 100.));
		let minFinalInventory = (finalThroughput + adjustedInventoryPrimo) - roundStats[area.id]['expected-demand'][1];
		let maxFinalInventory = (finalThroughput + adjustedInventoryPrimo) - roundStats[area.id]['expected-demand'][0];
		roundStats['office-and-storage']['expected-inventory-ultimo'] = 
			[Math.max(0, minFinalInventory), Math.max(0, maxFinalInventory)];
		
		if (minFinalInventory < 0 || maxFinalInventory < 0) {
			roundStats['office-and-storage']['expected-bought-units'] = [
				(maxFinalInventory > 0 ? 0 : -maxFinalInventory),
				-minFinalInventory
			];
		}
	});

	
	// Calculate material costs (except Office & Storage)
	areasData.filter((area) => {return area.id !== 'office-and-storage';}).forEach((area) => {
		let producedBeforeWaste = roundStats[area.id]['throughput'] / (1 - (roundStats[area.id]['waste'] / 100.));
		roundStats[area.id]['materials'] = producedBeforeWaste * roundStats[area.id]['material-cost-per-unit'];
		// roundStats[area.id]['throughput'] * roundStats[area.id]['material-cost-per-unit'];
	});
	roundStats['office-and-storage']['materials'] = 0;

	// Total cost
	areasData.forEach((area) => {
		if (area.id === 'office-and-storage') {
			roundStats[area.id]['total'] = 
				roundStats[area.id]['office-staff'] + 
				roundStats[area.id]['storage-staff'] + 
				roundStats[area.id]['expenses'] + 
				roundStats[area.id]['machines'];
		} else {
			roundStats[area.id]['total'] = 
			roundStats[area.id]['materials'] + 
			roundStats[area.id]['operators'] + 
			roundStats[area.id]['production-support'] +
			roundStats[area.id]['expenses'] + 
			roundStats[area.id]['machines'];
		}
	});

	// Calculate unit cost per area
	areasData.forEach((area) => {
		roundStats[area.id]['unitcost'] = (roundStats[area.id]['total']) / finalThroughput;
	});
	return roundStats;
};

/**
 * Get total cost of all areas
 * @param {object} roundStats 
 */
const getTotalCost = (roundStats) => {
	let totalCost = 0;
	areasData.forEach((area) => {
		if (roundStats.hasOwnProperty(area.id)) {
			totalCost = totalCost + roundStats[area.id]['total'];
		}
	});
	return totalCost;
};

const getTotalUnitCost = (roundStats) => {
	let totalUnitCost = 0;
	areasData.forEach((area) => {
		if (roundStats.hasOwnProperty(area.id)) {
			totalUnitCost = totalUnitCost + parseFloat(roundStats[area.id]['unitcost'].toFixed(1));
		}
	});
	return totalUnitCost;
};


/**
 * Get available actions
 * @param {object} group 
 */
const getAvailableActions = (areaId, subAreaId, group) => {
	// Get all bought actions
	let groupActionIds = [];
	group.rounds.forEach((round) => {
		if (round.selectedActions) {
			round.selectedActions.forEach((action) => {
				groupActionIds.push(action.id);
			});
		}
	});	
	
	// Locked actions
	let groupLockedActionsIds = (group.lockedActionIds ? group.lockedActionIds : []);

	// Selected actions
	let groupSelectedActionIds = group.selectedActions.map((a) => {return a.id;});
	
	// Get available actions
	let availableActions = actionsData.filter((action) => {
		return (
			action.areaId === areaId && // in correct area
			action.subAreaId === subAreaId && // in correct subarea 
			action.rounds[0] <= group.roundId && group.roundId <= action.rounds[1] && // available in current round
			groupLockedActionsIds.indexOf(action.id) < 0 && // not locked
			// repeatable OR not repeatable, not previously taken
			(action.isRepeatable === true || groupActionIds.indexOf(action.id) < 0) &&
			checkIfRequirementsAreMet(groupActionIds, groupSelectedActionIds, action)
		);
	});

	return availableActions;
};


/**
 * Check if the requirements of an action are met
 * @param {array} groupActionIds 
 * * @param {array} groupSelectedActionIds 
 * @param {object} action 
 */
const checkIfRequirementsAreMet = (groupActionIds, groupSelectedActionIds, action) => {
	let requirementsAreMet = true;

	if (action.requirements && action.requirements.length > 0) {
		action.requirements.forEach((req) => {
			if (req.type === 'action') {
				if (groupActionIds.indexOf(req.actionId) < 0) requirementsAreMet = false;
			}

			if (req.type === 'action-not-selected') {
				if (groupSelectedActionIds.indexOf(req.actionId) >= 0) requirementsAreMet = false;
			}
		});
	}

	return requirementsAreMet;
};

/**
 * Get the area data of area containing oldest machine
 * @param {object} groupRound 
 * @returns 
 */
const getAreaDataOfOldestMachine = (groupRound) => {
	let areaIndex = null;
	let machineAge = null;
	areasData.forEach((area, index) => {
		if (
			area.id !== 'office-and-storage' && // no machines in 'office and storage'
			groupRound[area.id]['machine-level'] === 1 && // machine is level 1
			!groupRound[area.id]['machine-is-upgraded'] && // machine has not been upgraded
			(!machineAge || groupRound[area.id]['machine-age'] > machineAge) // machine is the oldest machine
		) {
			areaIndex = index;
			machineAge = groupRound[area.id]['machine-age'];
		}
	});

	if (areaIndex === null) return null;
	return areasData[areaIndex];
};

/**
 * Calculate round effects
 * @param {string} scenarioId
 * @param {object} group
 * * @param {number} roundId
 */
const calculateRoundEffects = (scenarioId, group, roundId) => {
	// Prepare group updates
	let groupUpdates = {selectedActions: []};

	// Check for bottleneck before applying action effects
	let roundStatsPreActions = calculateRoundStats(scenarioId, group, roundId);
	let areaEffectiveCapacities = [
		{
			id: 'wash-and-sterilization', 
			value: roundStatsPreActions['wash-and-sterilization']['effective-capacity']
		},
		{
			id: 'formulation-and-stabilization', 
			value: roundStatsPreActions['formulation-and-stabilization']['effective-capacity']
		},
		{
			id: 'filling-and-inspection', 
			value: roundStatsPreActions['filling-and-inspection']['effective-capacity']
		},
	];
	let preActionsBottleneck = getBottleneck(areaEffectiveCapacities);
	
	// Current round: apply effects of selected actions	
	let groupLockedActionIds = (group.lockedActionIds ? JSON.parse(JSON.stringify(group.lockedActionIds)) : []);
	let groupLockedReportSnippetIds = (group.lockedReportSnippetIds 
		? JSON.parse(JSON.stringify(group.lockedReportSnippetIds))
		: []
	);
	let groupUpgrades = (group.upgrades ? JSON.parse(JSON.stringify(group.upgrades)) : []);
	let groupDelayedEffects = (group.delayedEffects ? JSON.parse(JSON.stringify(group.delayedEffects)) : []);
	let groupRounds = JSON.parse(JSON.stringify(group.rounds));
	let currentGroupRoundIndex = groupRounds.findIndex((round) => {return round.id === roundId;});
	let currentRound = JSON.parse(JSON.stringify(groupRounds[currentGroupRoundIndex]));
	currentRound.selectedActions = group.selectedActions;
	
	// Machine ages increase
	areasData.forEach((area) => {
		if (currentRound[area.id].hasOwnProperty('machine-age')) {
			currentRound[area.id]['machine-age'] = currentRound[area.id]['machine-age'] + 1;
		}
	});

	// Selected actions
	let numberOfTeamsFired = 0;
	group.selectedActions.forEach((action) => {
		let actionData = actionsData.find((a) => {return a.id === action.id;});
		if (actionData) {
			// Change parameter
			if (actionData.type === 'change-parameter') {
				if (
					currentRound[actionData.areaId] && 
					currentRound[actionData.areaId][actionData.parameterId]
				) {
					currentRound[actionData.areaId][actionData.parameterId] += action.parameterAdjustment;
					if (
						action.parameterAdjustment < 0 &&
						(actionData.parameterId === 'operator-teams' || actionData.parameterId === 'support-teams')
					) {
						// Count number of teams fired
						numberOfTeamsFired += 1;
					}
				}
			}

			// Buy item
			if (actionData.type === 'buy-item') {
				actionData.effects.forEach((effect) => {
					// Effect is in a specific area
					if (effect.areaId && currentRound[effect.areaId]) {
						// Upgrade / downgrade machine
						if (effect.type === 'machine-is-upgraded') {
							currentRound[effect.areaId]['machine-is-upgraded'] = effect.value;
						}

						// Replace machine
						if (effect.type === 'machine-level') {
							currentRound[effect.areaId]['machine-level'] = effect.level;
							currentRound[effect.areaId]['machine-age'] = 1;
						}

						// Remove old machine effect if machine was upgraded / replaced
						if (effect.type === 'machine-is-upgraded' || effect.type === 'machine-level') {
							let indexOfOldMachineUpgrade = groupUpgrades.findIndex((upgrade) => {
								return upgrade.areaId === effect.areaId && upgrade.condition === 'old-machine';
							});
							if (indexOfOldMachineUpgrade >= 0) {
								groupUpgrades.splice(indexOfOldMachineUpgrade, 1);
							}
						}

						// Add machine write off
						if (effect.type === 'machine-write-off') {
							let currentWriteOffs = (
								currentRound[effect.areaId]['machine-write-offs'] 
									? currentRound[effect.areaId]['machine-write-offs'] : []
							);
							let newWriteOff = {
								cost: effect.cost, 
								rounds: [roundId, (roundId + effect.rounds - 1)],
								label: actionData.title
							};
							currentWriteOffs.push(newWriteOff);
							currentRound[effect.areaId]['machine-write-offs'] = currentWriteOffs;
						}

						// Add expenses write off
						if (effect.type === 'expenses-write-off') {
							let currentWriteOffs = (
								currentRound[effect.areaId]['expenses-write-offs'] 
									? currentRound[effect.areaId]['expenses-write-offs'] : []
							);
							let newWriteOff = {
								cost: effect.cost, 
								rounds: [roundId, (roundId + effect.rounds - 1)]
							};
							currentWriteOffs.push(newWriteOff);
							currentRound[effect.areaId]['expenses-write-offs'] = currentWriteOffs;
						}

						// Addition
						if (effect.type === 'addition' && currentRound[effect.areaId][effect.parameter]) {
							currentRound[effect.areaId][effect.parameter] = 
							currentRound[effect.areaId][effect.parameter] + effect.value;
						}

						// Multiplication
						if (effect.type === 'multiplication' && currentRound[effect.areaId][effect.parameter]) {
							currentRound[effect.areaId][effect.parameter] = 
							currentRound[effect.areaId][effect.parameter] * effect.value;
						}
					} else {
						// Work environment
						if (effect.type === 'work-environment') {
							currentRound.workEnvironment = currentRound.workEnvironment + effect.value;
						}

						// Upgrade
						if (effect.type === 'upgrade') {
							let groupUpgrade = JSON.parse(JSON.stringify(effect.upgrade));
							groupUpgrade.roundId = roundId;
							if (groupUpgrade.rounds) {
								let rounds = groupUpgrade.rounds;
								let delay = (groupUpgrade.hasOwnProperty('delay') ? groupUpgrade.delay : 0);
								groupUpgrade.rounds = [roundId + delay, (roundId + delay + rounds - 1)];
								delete groupUpgrade.delay;
							}
							groupUpgrades.push(groupUpgrade);
						}

						// Remove upgrade
						if (effect.type === 'remove-upgrade') {
							groupUpgrades = groupUpgrades.filter((upgrade) => {
								return (
									!(upgrade.type === effect.upgrade.type && upgrade.areaId === effect.upgrade.areaId)
								);
							});
						}

						// Lock / unlock actions
						if (effect.type === 'add-action-lock') {
							if (groupLockedActionIds.indexOf(effect.actionId) < 0) {
								groupLockedActionIds.push(effect.actionId);
							}
						}
						if (effect.type === 'remove-action-lock') {
							if (groupLockedActionIds.indexOf(effect.actionId) >= 0) {
								groupLockedActionIds.splice(groupLockedActionIds.indexOf(effect.actionId), 1);
							}
						}

						// Delayed effect
						if (effect.type === 'delayed-effect') {
							groupDelayedEffects.push(JSON.parse(JSON.stringify(effect.effect)));
						}
					}
				});
			}
		}
	});

	// Delayed effects
	groupDelayedEffects.forEach((effect) => {
		// Reduce delay 
		effect.delay = effect.delay - 1;
		
		// Apply effect if delay is < 0
		if (effect.delay < 0 && effect.type === 'remove-action-lock') {
			if (groupLockedActionIds.indexOf(effect.actionId) >= 0) {
				groupLockedActionIds.splice(groupLockedActionIds.indexOf(effect.actionId), 1);
			}
		}
	});
	groupDelayedEffects = groupDelayedEffects.filter((effect) => {
		return effect.delay >= 0;
	});

	// Check if work environment is affected by firing teams 
	let workEnvironmentReduced = false;
	currentRound.numberOfTeamsFired = numberOfTeamsFired;
	if (numberOfTeamsFired > 0) {
		let numberOfTeamsFiredPrevRound = currentGroupRoundIndex > 0
			? groupRounds[currentGroupRoundIndex - 1].numberOfTeamsFired 
				? groupRounds[currentGroupRoundIndex - 1].numberOfTeamsFired : 0
			: 0;

		if (numberOfTeamsFired >= 3 || (numberOfTeamsFired > 1 && numberOfTeamsFiredPrevRound > 1)) {
			currentRound.workEnvironment = currentRound.workEnvironment - 1;
			workEnvironmentReduced = true;
		}
	}

	// Make sure work environment is within bounds 
	currentRound.workEnvironment = Math.min(currentRound.workEnvironment, appConfig.maxWorkEnvironment);
	currentRound.workEnvironment = Math.max(currentRound.workEnvironment, appConfig.minWorkEnvironment);

	// Make sure waste is within bounds
	areasData.forEach((area) => {
		if (currentRound[area.id] && currentRound[area.id].waste) {
			currentRound[area.id].waste = Math.max(currentRound[area.id].waste, appConfig.minWaste);
		}
	});

	// Add upgrades and rounds to group updates
	groupUpdates.lockedActionIds = groupLockedActionIds;
	groupUpdates.upgrades = groupUpgrades;
	groupUpdates.delayedEffects = groupDelayedEffects;
	groupRounds[currentGroupRoundIndex] = currentRound;
	
	// Current round: inventory, production and demand
	const currentRoundData = roundsData.find((round) => {return round.id === roundId;});
	let currentRoundStats = calculateRoundStats(scenarioId, {rounds: groupRounds, upgrades: groupUpgrades}, roundId);
	let currentRoundTotalCost = getTotalCost(currentRoundStats);
	let currentRoundInventoryPrimo = currentRoundStats['office-and-storage']['inventory-primo'];
	let currentRoundInventory = 
		currentRoundInventoryPrimo * (1 - (currentRoundStats['office-and-storage']['waste'] / 100.));
	let currentRoundProduction = currentRoundStats['office-and-storage']['throughput'];
	let currentRoundDemand = currentRoundData['actual-demand'][scenarioId];

	// Current round: adjust total cost if bonus received
	let bonusUpgrade = groupUpgrades.find((upgrade) => {
		return (
			upgrade.type === 'total-cost-bonus' &&
			(upgrade.rounds[0] <= roundId && roundId <= upgrade.rounds[1])
		);
	});
	if (bonusUpgrade) {
		currentRoundTotalCost = currentRoundTotalCost + bonusUpgrade.value; // value is negative
	}

	// Current round: check that demand is met, if not: adjust production and cost 
	let extraProduction = 0;
	let extraCost = 0;
	if (currentRoundDemand > (currentRoundInventory + currentRoundProduction)) {
		let initialUnitCost = currentRoundTotalCost / currentRoundProduction;
		extraProduction = currentRoundDemand - (currentRoundInventory + currentRoundProduction);
		extraCost = initialUnitCost * appConfig.extraProductionCostFactor * extraProduction;
	}

	// Current round: inventory ultimo
	let currentRoundInventoryUltimo = Math.round(
		currentRoundInventory + currentRoundProduction + extraProduction - currentRoundDemand
	);

	// Current round: unit cost
	let currentRoundUnitCost = currentRoundTotalCost / currentRoundProduction; 
	// getTotalUnitCost(currentRoundStats); // sum of areas' unit cost
	// if (bonusUpgrade) currentRoundUnitCost = currentRoundTotalCost / currentRoundProduction;
	let originalUnitCost = currentRoundUnitCost;
	if (extraProduction > 0 && extraCost > 0) {
		// originalUnitCost = currentRoundTotalCost / currentRoundProduction;
		currentRoundUnitCost = (
			(currentRoundTotalCost + extraCost) / 
			(currentRoundProduction + extraProduction)
		); // .toFixed(1); // why to fixed now?
	}

	// Current round: Results
	let results = {
		actions: currentRound.selectedActions,
		production: currentRoundProduction,
		cost: currentRoundTotalCost + extraCost,
		inventoryPrimo: currentRoundInventoryPrimo,
		inventory: currentRoundInventory,
		inventoryUltimo: currentRoundInventoryUltimo,
		unitCost: currentRoundUnitCost,
		originalUnitCost: originalUnitCost,
		extraProduction: extraProduction,
		extraCost: extraCost,
		actualDemand: currentRoundDemand,
		workEnvironment: currentRound.workEnvironment,
		demandWasMet: (extraProduction === 0 ? true : false),
		workEnvironmentReduced: workEnvironmentReduced,
		preActionsBottleneck: preActionsBottleneck,
		areaEffectiveCapacities: [
			{
				id: 'wash-and-sterilization', 
				value: currentRoundStats['wash-and-sterilization']['effective-capacity']
			},
			{
				id: 'formulation-and-stabilization', 
				value: currentRoundStats['formulation-and-stabilization']['effective-capacity']
			},
			{
				id: 'filling-and-inspection', 
				value: currentRoundStats['filling-and-inspection']['effective-capacity']
			},
		]
	};
	currentRound.results = results;

	// Check if there is a bottleneck
	currentRound.results.bottleneck = getBottleneck(results.areaEffectiveCapacities);

	// Calculate game score 
	let totalCostAllRounds = 0;
	let totalDemandAllRounds = 0;
	groupRounds.forEach((round) => {
		if (round.results) {
			totalCostAllRounds = totalCostAllRounds + round.results.cost;
			totalDemandAllRounds = totalDemandAllRounds + round.results.actualDemand;
		}
	});

	let gameScore = totalCostAllRounds / 
		(totalDemandAllRounds - Math.max(results.inventoryUltimo, 0.5 * results.actualDemand));
	currentRound.results.gameScore = gameScore;

	// Update group rounds
	groupRounds[currentGroupRoundIndex].results = currentRound.results;


	// Initialize next round if roundId is current round
	if (group.roundId === roundId) {
		let currentRoundIndex = roundsData.findIndex((round) => {return round.id === roundId;});
		if (currentRoundIndex >= 0 && (currentRoundIndex + 1) < roundsData.length) {
			let nextRoundData = roundsData[currentRoundIndex + 1];
	
			// Update round number
			groupUpdates.roundId = nextRoundData.id;

			// Flag as game over if last round
			if (currentRoundIndex + 1 === roundsData.length - 1) groupUpdates.isGameOver = true;
	
			// Create round object 
			let nextGroupRound = JSON.parse(JSON.stringify(currentRound));
			nextGroupRound.id = nextRoundData.id;
			nextGroupRound.selectedActions = [];
			nextGroupRound.results = null;
	
			// Inventory
			nextGroupRound['inventory-primo'] = currentRound.results.inventoryUltimo;
	
			// Special round effects
			if (nextRoundData.effects && nextRoundData.effects.length > 0) {
				nextRoundData.effects.forEach((effect) => {
					// Multiplication
					if (effect.type === 'addition' && nextGroupRound[effect.areaId][effect.parameter]) {
						nextGroupRound[effect.areaId][effect.parameter] = 
							nextGroupRound[effect.areaId][effect.parameter] + effect.value;
					}
	
					// Work environment
					if (effect.type === 'work-environment') {
						nextGroupRound.workEnvironment = nextGroupRound.workEnvironment + effect.value;
					}

					// Old machine, reduced effective capacity (only if one or more machines are level 1)
					if (effect.type === 'old-machine-reduced-effect') {
						let oldMachineAreaData = getAreaDataOfOldestMachine(nextGroupRound);
						if (oldMachineAreaData) {
							groupUpgrades.push({
								type: 'support-efficiency-modifier', 
								areaId: oldMachineAreaData.id, 
								value: effect.value, 
								condition: 'old-machine',
								modifierType: 'old-machine',
								rounds: [nextGroupRound.id, 8]
							});
						}
					}

					// Work environment bonus
					if (effect.type === 'total-cost-reduction') {
						if (effect.condition && effect.condition.parameter === 'work-environment-level') {
							let workEnvironment = nextGroupRound.workEnvironment;
							let weData = workEnvironmentData.find((we) => {
								return workEnvironment >= we.minVal && workEnvironment <= we.maxVal;
							});
							if (weData && weData.level && weData.level === effect.condition.level) {
								groupUpgrades.push({
									type: 'total-cost-bonus', 
									value: effect.value, 
									rounds: [nextGroupRound.id, nextGroupRound.id]
								});
							}
						}
					}

					// Mandatory action
					if (effect.type === 'mandatory-action') {
						if (effect.condition && effect.condition.type === 'no-upgrade') {
							if (
								!groupUpgrades.some((upgrade) => {
									return upgrade.type === effect.condition.upgradeType;
								})
							) {
								groupUpdates.selectedActions.push({
									id: effect.actionId, 
									parameterAdjustment: null,
									parameterId: null,
									isMandatory: true
								});
							}
						}
					}
				});
			}
			// Make sure work environment is within bounds 
			nextGroupRound.workEnvironment = Math.min(nextGroupRound.workEnvironment, appConfig.maxWorkEnvironment);
			nextGroupRound.workEnvironment = Math.max(nextGroupRound.workEnvironment, appConfig.minWorkEnvironment);

			// Add to group object
			groupRounds.push(nextGroupRound);

			// Get report snippets
			
			let reportSnippets = getReportSnippets(currentRoundIndex + 1, groupRounds, groupLockedReportSnippetIds);
			reportSnippets.forEach((snippet) => {
				if (snippet.groupId === 'extra') groupLockedReportSnippetIds.push(snippet.id);
			});

			groupRounds[groupRounds.length - 1].reportSnippets = reportSnippets;
			groupUpdates.lockedReportSnippetIds = groupLockedReportSnippetIds;

		} else {
			// Final round played, flag game as over (should not happen)
			groupUpdates.isGameOver = true;
		}
	}
	
	// Add rounds & upgrades to group updates
	groupUpdates.rounds = groupRounds;
	groupUpdates.upgrades = groupUpgrades;

	return groupUpdates;
};

/**
 * Get bottlenck
 * @param {array} areaEffectiveCapacities 
 * @returns 
 */
const getBottleneck = (areaEffectiveCapacities) => {
	let bottleNeckAreaIds = null;

	let lowestCapacityAreaIndex = 0;
	let highCapacityAreaIndex = 0;
	areaEffectiveCapacities.forEach((area, index) => {
		if (area.value < areaEffectiveCapacities[lowestCapacityAreaIndex].value) {
			lowestCapacityAreaIndex = index;
		}
		if (area.value > areaEffectiveCapacities[highCapacityAreaIndex].value
		) {
			highCapacityAreaIndex = index;
		}
	});
	if (
		(areaEffectiveCapacities[highCapacityAreaIndex].value - 
				areaEffectiveCapacities[lowestCapacityAreaIndex].value) > 
			(areaEffectiveCapacities[lowestCapacityAreaIndex].value *
			appConfig.bottleneckDifferenceMax)
	) {
		bottleNeckAreaIds = {
			low: areaEffectiveCapacities[lowestCapacityAreaIndex],
			high: areaEffectiveCapacities[highCapacityAreaIndex]
		};
	}

	return bottleNeckAreaIds;
};


export {
	formatNumber,
	calculateRoundStats,
	getTotalCost,
	getTotalUnitCost,
	getAvailableActions,
	calculateRoundEffects
};