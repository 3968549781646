import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {areasData} from 'data/areas-data';
import {objectsData} from 'data/objects-data';
import {getImageSize} from 'helpers/image-helper';
import {getStatusOfObject} from 'helpers/area-objects-helper';
import Button from 'components/button/button';
import CostsAndProduction from 'components/costs-and-production/costs-and-production';
import AvailableActions from 'components/available-actions/available-actions';
import SelectedActions from 'components/selected-actions/selected-actions';
import RoundTrack from 'components/round-track/round-track';
import './area.scss';

const Area = (props) => {
	let {
		isGameOver,
		isCalculatingEffects,
		animateInfoIcon,
		popupIsOpen,
		languageId,
		pageData, 
		roundStats, 
		group, 
		openActionContainers,
		openActions,
		handleToggleActionContainer,
		handleOpenCloseAction,
		handleToggleAction, 
		handleToggleInfoPopup, 
		handleToggleReportPopup,
		handleConfirmSelectedActions
	} = props;


	// Get area data
	let areaData = areasData.find((area) => {return area.id === pageData.areaId;});

	// Track if it is first render for this area (i.e. not ready for css transitions)
	let [areaId, setAreaId] = useState(pageData.areaId);
	let [isFirstRender, setIsFirstRender] = useState(true);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (areaData.id !== areaId) {
			setIsFirstRender(true);	
			setAreaId(areaData.id);
		} else {
			setIsFirstRender(false);
		}
	}, [areaData.id, areaId]);	

	// Get window size -> size / position of background image
	let [bgImageWidth, setBgImageWidth] = useState(0);
	let [bgImageHeight, setBgImageHeight] = useState(0);
	let [bgImageTranslation, setBgImageTranslation] = useState(0);
	
	// Set size of overlay images to match background image 
	const setImageSize = () => {
		let container = document.getElementById('area');
		if (container) {
			let containerWidth = container.offsetWidth;
			let containerHeight = container.offsetHeight;		
			let imageRatio = 1536. / 864.;
			let [w, h] = getImageSize(containerWidth, containerHeight, imageRatio);
			setBgImageWidth(w);
			setBgImageHeight(h);
			if (w > containerWidth) {
				setBgImageTranslation((w - containerWidth) / 2.);
			} else {
				setBgImageTranslation(0);
			}
		}
	};

	// Set image size after render or when window is resized
	useEffect(() => {
		setImageSize();
		window.addEventListener('resize', setImageSize);
	});

	/* Get background style */
	const backgroundStyle = { 
		width: bgImageWidth + 'px',
		height: bgImageHeight + 'px',
		transform: `translate(${-bgImageTranslation}px, 0px)` 
	};


	// Action hover effect
	let [hoverActionId, setHoverActionId] = useState(null);
	const handleHoverAction = (actionId) => {setHoverActionId(actionId);};

	// Check if info popup btn should blink
	let animateInfoPopupBtn = (!popupIsOpen && animateInfoIcon && group.roundId < 2);


	if (!areaData) {
		// Unknown area, return empty area page
		console.error('unknown area: ' + pageData.areaId);
		return <div className="Area" />;
	}	

	return (
		<div id="area" className={'Area ' + pageData.areaId}>
			{/* Background and objects */}
			<div className="Area-objects">
				<svg style={backgroundStyle} viewBox='0 0 1536 864' preserveAspectRatio="xMidYMax" version='1.1' xmlns='http://www.w3.org/2000/svg'>
					{areaData.objects.map((object, index) => {
						let objectData = objectsData.find((o) => {return o.id === object.id;});
						let groupRound = group.rounds.find((r) => {return r.id === group.roundId;});
						if (objectData) {
							/* Status: visible / hidden */
							let status = getStatusOfObject(
								areaData.id, 
								roundStats[areaData.id], 
								groupRound,
								object, 
								group
							);
							/* Object translation */
							let offsetX = (objectData.width / 2.);
							let offsetY = (objectData.height / 2.);
							if (objectData.hasOwnProperty('offsetX')) offsetX = objectData.offsetX;
							if (objectData.hasOwnProperty('offsetY')) offsetY = objectData.offsetY;
							return (
								<g 
									key={index} 
									width={objectData.width}
									height={objectData.height}
									transform={'translate(' + (object.x - offsetX) + ', ' + (object.y - offsetY) + ')'}
								>
									<image 
										className={status + (isFirstRender || areaId !== areaData.id ? '' : ' ready')}
										alignmentBaseline="bottom"
										x={0}
										y={0}
										width={objectData.width}
										height={objectData.height}
										transform={'translate(' + 
											(status === 'visible' ? 0 : (objectData.width / 2.)) + ', ' + 
											(status === 'visible' ? 0 : objectData.height) + '), ' +
											'scale(' + (status === 'visible' ? 1 : 0) + ')'
										}
										href={objectData.href}
									/>
								</g>
							);
						}
						return null;
					})}
				</svg>
			</div>

			{/* Cost and production panels */}
			<CostsAndProduction 
				isGameOver={isGameOver}
				languageId={languageId}
				hoverActionId={hoverActionId}
				roundId={group.roundId}
				areaStats={roundStats[pageData.areaId]} 
				areaData={areaData} group={group} 
			/>

			{/* Available actions */}
			{!isGameOver && areaData.subAreaIds && areaData.subAreaIds.map((subAreaId, index) => {
				return (
					<AvailableActions 
						key={areaData.id + '-' + subAreaId}
						languageId={languageId}
						areaId={areaData.id}
						subAreaId={subAreaId}
						hoverActionId={hoverActionId}
						areaStats={roundStats[pageData.areaId]} 
						group={group} 
						openActionContainers={openActionContainers}
						openActions={openActions}
						handleToggleActionContainer={handleToggleActionContainer}
						handleOpenCloseAction={handleOpenCloseAction}
						handleToggleAction={handleToggleAction}
						handleHoverAction={handleHoverAction}
					/>
				);
			})}

			{/* Rounds overview */}
			<RoundTrack page='area' group={group} />

			{/* Selected actions */}
			{!isGameOver && <SelectedActions 
				isCalculatingEffects={isCalculatingEffects}
				languageId={languageId}
				roundStats={roundStats}
				group={group} 
				handleToggleAction={handleToggleAction}
				handleConfirmSelectedActions={handleConfirmSelectedActions}
			/>}

			{/* Popup buttons */}
			<Button classes={['icon', 'report']} text="" onClick={() => {handleToggleReportPopup();}} />
			<Button 
				classes={['icon', 'info', (animateInfoPopupBtn ? 'blink' : '')]} 
				text="" 
				onClick={() => {handleToggleInfoPopup();}}
			/>
		</div>
	);
};

Area.propTypes = {
	isGameOver: PropTypes.bool.isRequired,
	isCalculatingEffects: PropTypes.bool.isRequired,
	animateInfoIcon: PropTypes.bool.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	pageData: PropTypes.object.isRequired,
	roundStats: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	openActionContainers: PropTypes.array.isRequired,
	openActions: PropTypes.array.isRequired,
	handleToggleActionContainer: PropTypes.func.isRequired,
	handleOpenCloseAction: PropTypes.func.isRequired,
	handleToggleAction: PropTypes.func.isRequired,
	handleToggleInfoPopup: PropTypes.func.isRequired,
	handleToggleReportPopup: PropTypes.func.isRequired,
	handleConfirmSelectedActions: PropTypes.func.isRequired,
};

export default Area;
