import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import ChangeParameter from './change-parameter';
import BuyItem from './buy-item';
import './action.scss';

const Action = (props) => {
	let {
		isOpen, 
		languageId,
		type,
		areaId, 
		hoverActionId,
		actionData, 
		areaStats, 
		group, 
		toggleAction, 
		handleToggleAction,
		handleHoverAction
	} = props;

	// Check if action is confirmed
	let [isConfirmed, setIsConfirmed] = useState(false);
	useEffect(() => {
		if (
			group.hasOwnProperty('selectedActions') && group.selectedActions &&
			group.selectedActions.some((action) => {return action.id === actionData.id;})
		) {
			setIsConfirmed(true);
		} else {
			setIsConfirmed(false);
		}
	}, [actionData, areaStats, group]);

	let selectedActionData = group.selectedActions.find((action) => {return action.id === actionData.id;});

	// Confirm button status
	let confirmBtnIsDisabled = false;
	if (!isConfirmed && group.selectedActions && group.selectedActions.length >= appConfig.actionsPerRound) {
		// Max number of actions have been selected (and this is not one of them)
		confirmBtnIsDisabled = true;
	}

	return (
		<div 
			className={'Action ' + type + ' ' + areaId + (isOpen ? ' open ' : '') + (isConfirmed ? ' confirmed' : '')}
			onMouseOver={() => {if (hoverActionId !== actionData.id) handleHoverAction(actionData.id);}}
			onMouseOut={() => {if (hoverActionId) handleHoverAction(null);}}
		>
			<div className="Action-header" onClick={() => {toggleAction(actionData.id);}}>
				<div className="Action-title">{getText(actionData.title, languageId)}</div>
				<div className="Action-toggleBtn" />
			</div>
			<div className="Action-body">
				<div className="Action-description">{renderMarkdown(getText(actionData.description, languageId))}</div>
				<div className={'Action-action ' + actionData.type}>
					{actionData.type === 'change-parameter' && 
						<ChangeParameter 
							isOpen={isOpen}
							isDisabled={confirmBtnIsDisabled}
							isConfirmed={isConfirmed}
							languageId={languageId}
							actionData={actionData}
							areaStats={areaStats}
							group={group}
							handleToggleAction={handleToggleAction}
						/>
					} 
					{actionData.type === 'buy-item' && 
						<BuyItem 
							isConfirmed={isConfirmed}
							confirmBtnIsDisabled={confirmBtnIsDisabled}
							languageId={languageId}
							selectedActionData={selectedActionData}
							actionData={actionData}
							handleToggleAction={handleToggleAction}
						/>
					}
				</div>
			</div>
		</div>
	);
};

Action.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	areaId: PropTypes.string.isRequired,
	hoverActionId: PropTypes.number,
	actionData: PropTypes.object.isRequired,
	areaStats: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	toggleAction: PropTypes.func.isRequired,
	handleToggleAction: PropTypes.func.isRequired,
	handleHoverAction: PropTypes.func.isRequired,
};

export default Action;
