const errorTexts = {
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.'
	},

	/* Create user (facilitator) */
	emptyFields: {
		da: 'Du skal udfylde alle felter.',
		en: 'You have to fill out all the fields.'
	},
	invalidEmailDomain: {
		da: 'Du kan kun tilmelde dig med en Novo Nordisk-email',
		en: 'You can only sign up with a Novo Nordisk e-mail'
	},
	emailExists: {
		da: 'Der findes allerede en bruger med denne email.',
		en: 'This email is already in use.'
	},
	invalidPassword: {
		da: 'Dit password skal være på mindst 8 tegn.',
		en: 'Your password has to be at least 8 characters.'
	},
	noPasswordMatch: {
		da: 'Password skal være ens i begge felter.',
		en: 'Password has to be the same in both fields.'
	},

	/* Login / reset password (facilitator) */
	emailNotFound: {
		da: 'Der findes ingen bruger med denne email.',
		en: 'There is no user linked to this email.'
	},
	invalidEmail: {
		da: 'Din email er ikke gyldig.',
		en: 'This email is not valid.'
	},
	invalidEmailPasswordFormat: {
		da: 'Fejl. Check om din email og password er korrekte.',
		en: 'Error. Check if your email and password are correct.'
	},
	
	/* Login (group) */
	noGameCodeMatch: {
		da: 'Ugyldig spilkode.',
		en: 'Invalid game code.'
	},
	
	/* Create group */
	invalidCharacters: {
		da: 'Spilnavnet indeholder et eller flere ugyldige tegn.',
		en: 'Game name contains one or more invalid characters.'
	},
	groupNameTooShort: {
		da: 'Gruppenavn skal være på mindst 3 tegn.',
		en: 'Group name has to be at least 3 characters.'
	},
	groupNameTooLong: {
		da: 'Gruppenavn må ikke være længere end 12 tegn.',
		en: 'Group name can\'t be longer than 12 characters.'
	},
	noGroupName: {
		da: 'Husk at udfylde et gruppenavn.',
		en: 'Remember to put a group name.'
	},
	groupNameAlreadyExists: {
		da: 'Der eksisterer allerede en gruppe med det navn.',
		en: 'A group with this name already exists.'
	}
};

export default errorTexts;