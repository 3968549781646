import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './illustration-popup.scss';

const IllustrationPopup = ({isLoading, showIllustrationPopup, languageId, roundId}) => {
	return (
		<div className={'IllustrationPopup' + (isLoading ? ' loading' : '') + (showIllustrationPopup ? ' show' : '')}>
			<div className="IllustrationPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className={'IllustrationPopup-text round-' + (roundId - 1)}>
					{renderMarkdown(getText(generalUiTexts.newRoundText, languageId))}
					<div className="IllustrationPopup-animation" />
				</div>
			</div>
		</div>
	);
};

IllustrationPopup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	showIllustrationPopup: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	roundId: PropTypes.number.isRequired
};

export default IllustrationPopup;
